
<h2 mat-dialog-title>Choose Image</h2>
<div mat-dialog-content>
  Would you like to keep current image or override with the new one?

  <div id="buttons-container">
    <button mat-button (click)="keep('current')">Keep Current</button>
    <button mat-button (click)="keep('override')">Override</button>
  </div>
</div>
