<div id="right-content">
  <div class="container parent-div">

    <!--static image-->
    <img *ngIf="windowWidth > 992" class="static-image" src="assets/human-resources/learning-hub/Graphic.png" alt="background image">

    <!--go back button-->
    <div class="d-flex flex-row align-items-center go-back-button-div" [routerLink]="['/', 'human-resources']">
      <i class="fas fa-caret-left" style="color: #FFFFFF;"></i>
      <button class="go-back-button">
        Back
      </button>
    </div>

    <!--title and text description-->
    <div class="text-area-cont d-flex flex-column align-items-start">
      <h1 class="big-title">The Learning Hub</h1>
      <p class="text-description">The Learning Hub was designed to offer resources, in interesting and varied ways,
        that will help encourage all Jazmanians to develop independent learning skills and encourage a culture of
        curiosity.</p>
    </div>

    <!--content area-->
    <div class="content-area" [ngStyle]="{'grid-template-columns': windowWidth > 992 && !categoriesView ? '1fr 2fr' :
         windowWidth > 992 && categoriesView ? '1fr 1fr 1fr' : '1fr'}">

      <!--item #1-->
      <div class="items-cont d-flex flex-column">

        <div class="jazwiki-card d-flex flex-column align-items-center">
          <img class="jazwiki-logo" src="assets/human-resources/learning-hub/Jazwiki_Logo.png" alt="jazwiki logo">
<!--          <button class="learning-hub-custom-button">Click Here</button>-->
          <a class="learning-hub-custom-button d-flex flex-row justify-content-center align-items-center"
             href="https://jazwares.bizlibrary.com/" target="_blank">Click Here</a>
        </div>

        <div class="hr-help d-flex flex-column align-items-center">
          <span class="text-muted hr-help-text">For questions or suggestions please contact:</span>
          <a class="hr-help-link" href="https://jazwares.employee.hrsd.ultipro.com/home" target="_blank">HR HELP: Learning and Organizational Development</a>
          <img class="learning-jaz-image" src="assets/human-resources/learning-hub/LearningJazmanian.png"
               alt="learning jazmanian logo">
        </div>
      </div>

      <!--item #2-->
      <div *ngIf="windowWidth > 1299 && categoriesView" class="items-cont d-flex flex-column">
       <div *ngFor="let category of categories | slice:0:3, let i = index">
         <jz-category-card [cat]="category.cat" [description]="category.description"
                           [backGroundColor]="category.color" [icon]="category.logo" [catID]="category.id"
                           (showCatResources)="showCategoriesView($event)">

         </jz-category-card>
       </div>
      </div>

      <!--item #3-->
      <div *ngIf="windowWidth > 1299 && categoriesView" class="items-cont d-flex flex-column" style="padding-top: 150px">
        <div *ngFor="let category of categories | slice:3:categories.length, let i = index">
          <jz-category-card [cat]="category.cat" [description]="category.description" [margin]="marginTop"
                            [indexPosition]="category[i]" [backGroundColor]="category.color"
                            [icon]="category.logo" [catID]="category.id" (showCatResources)="showCategoriesView($event)">

          </jz-category-card>
        </div>
      </div>

      <div *ngIf="windowWidth <= 1299 && categoriesView" class="items-cont d-flex flex-row justify-content-start flex-wrap">
            <div *ngFor="let category of categories, let i = index">
              <jz-category-card [cat]="category.cat" [description]="category.description"
                                [backGroundColor]="category.color"
                                [icon]="category.logo" [catID]="category.id" (showCatResources)="showCategoriesView($event)">

              </jz-category-card>
        </div>
      </div>

      <!--showing resources and sub-cat if-->
      <div *ngIf="!categoriesView" class="resources-data-list-cont" style="margin-top: 151px">
        <div class="close-button" (click)="closeDetailView()">X</div>
        <div *ngFor="let cat of detailedArray" id="detail-view-resource-cards" class="d-flex flex-column">
          <h4 class="cat-header d-flex flex-row justify-content-start align-items-center">{{cat.cat}}</h4>
          <p class="resource-data-list-cat-description">{{cat.description}}</p>

          <div class="resources-list-scrollable">

            <!--catResources-->
            <div *ngFor="let resource of detailedArray[0].resources">
              <a class="resource-list-cont" [href]="(resource.resource_type === 'pdf' || resource.resource_type === 'xlsx' ||
                resource.resource_type === 'video') ? resource.resource_url : resource.external_link" target="_blank">
                <img style="width: 5%" [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'" alt="">
                <span class="resource-data-list-cat-description">{{resource.name}}</span>
              </a>
            </div>

            <!--subCategories-->
            <div *ngIf="subCat" id="mat-accordion-custom" class="d-flex flex-column">
              <mat-accordion *ngFor="let subCat of detailedArray[0].sub_cat; index as i">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{subCat.cat}}</mat-panel-title>
                  </mat-expansion-panel-header>
                  <div *ngFor="let resource of detailedArray[0].sub_cat[i].resources">
                    <a class="resource-list-cont d-flex flex-row" [href]="(resource.resource_type === 'pdf' || resource.resource_type === 'xlsx' ||
                    resource.resource_type === 'video') ? resource.resource_url : resource.external_link" target="_blank">
                      <img style="width: 5%" [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'" alt="">
                      <span class="resource-data-list-cat-description">{{resource.name}}</span>
                    </a>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>

          </div>

        </div>
      </div>

    </div>

  </div>

</div>

