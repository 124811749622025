import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IIdea} from '../models/idea';
import {IdeaService} from '../idea.service';

@Injectable()
  export class IdeasResolver implements Resolve<IIdea[]> {

    constructor(
      private ideaService: IdeaService
    ) {}

    resolve(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<IIdea[]> | Promise<IIdea[]> | IIdea[] {
      return this.ideaService.getIdeas();
    }
  }
