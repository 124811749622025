<div id="jtank-submit">

  <div id="header">
    <img id="jtank-logo" alt="jtank logo" src="assets/bluej/contest/jtank-white-logo.png"/>
    <p>Submit</p>
  </div>

  <div id="back-btn-route" *ngIf="!showPreview">
    <button (click)="navigateBack()">
      <i class="fas fa-caret-left"></i>
      Back
    </button>
  </div>

  <ngx-spinner
    bdColor="rgba(51,51,51,0.2)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  ></ngx-spinner>

  <div *ngIf="contest?.is_active && contest?.submission_active">
    <div
      id="body-container"
      @preview
      *ngIf="!showPreview"
      [ngStyle]="{'flex-direction': isNavOpen && innerWidth < 1300 && innerWidth > 1000 ? 'column' : 'row'}"
    >
      <div id="form-section"
        [ngStyle]="{'width': isNavOpen && innerWidth < 1300 ? '100%' : '80%'}"
      >
        <h2>Submit your Idea</h2>

        <form [formGroup]="ideaForm" (ngSubmit)="onSubmit()">
          <select id="category-select" formControlName="category">
            <option value="">----- Pick a Category -----</option>
            <option *ngFor="let category of categories" [value]="category.id">{{category.display_name}}</option>
          </select>

          <div id="email-name-container">
            <div class="label-input-container">
              <label for="email">Email*</label>
              <input id="email" name="email" placeholder="email@jazwares.com" formControlName="email"/>
            </div>

            <div class="label-input-container">
              <label for="name">Name*</label>
              <input id="name" name="name" placeholder="Type Name" formControlName="name"/>
            </div>
          </div>

          <div class="label-input-container">
            <label for="title">Title of the Idea*</label>
            <input
              id="title"
              name="title"
              placeholder="Please specify"
              formControlName="title"
              [ngStyle]="{border: titleRepeated ? '1px solid red' : 'none'}"
            />
          </div>

          <label for="description">Description*</label>
          <textarea
            id="description"
            name="description"
            placeholder="Please specify"
            formControlName="description"
          ></textarea>

          <div
            id="dropdown-section"
            ngx-dropzone
            (change)="onSelect($event)"
            [multiple]="false"
            [maxFileSize]="1048576"
          >
            <ngx-dropzone-label>Drop your image here</ngx-dropzone-label>

            <span *ngIf="isPdf">
            <ngx-dropzone-preview
              *ngFor="let f of selectedFiles"
              [file]="f"
              [removable]="true"
              (removed)="onRemove(f)"
            >
              <ngx-dropzone-label>{{f.name}} {{f.type}}</ngx-dropzone-label>
            </ngx-dropzone-preview>
          </span>

            <span *ngIf="!isPdf">
            <ngx-dropzone-image-preview
              ngProjectAs="ngx-dropzone-preview"
              *ngFor="let f of selectedFiles"
              [file]="f"
              [removable]="true"
              (removed)="onRemove(f)"
            ></ngx-dropzone-image-preview>
          </span>
          </div>

          <div id="buttons-container">
            <button
              class="s-button"
              type="button"
              [disabled]="!ideaForm.valid || isSubmitted || selectedFiles.length === 0 || ideaForm.controls.category.value === ''"
              [ngStyle]="{opacity: !ideaForm.valid || isSubmitted || selectedFiles.length === 0 || ideaForm.controls.category.value === '' ? 0.4 : 1}"
              (click)="onChangeToPreview()"
            >
              Preview
            </button>

            <button
              class="s-button"
              style="margin-left: 30px;"
              type="submit"
              [disabled]="!ideaForm.valid || isSubmitted || selectedFiles.length === 0 || ideaForm.controls.category.value === ''"
              [ngStyle]="{opacity: !ideaForm.valid || isSubmitted || selectedFiles.length === 0 || ideaForm.controls.category.value === '' ? 0.4 : 1}"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <div id="reminder-section"
        [ngStyle]="{
        'width': isNavOpen && innerWidth < 1300 && innerWidth > 1000 ? '100%' : '20%',
        'margin-left': isNavOpen && innerWidth < 1300 && innerWidth > 1000 ? 0 : '20px'
      }"
      >
        <h2>Reminder:</h2>
        <p>
          All ideas will become live on the same date. If you submit your idea ahead of time, we will store your ideas in
          our incubator tank until the challenge is live.
        </p>
        <p>
          Please remember to come back and vote for all of your favorite ideas. The more up votes an idea has, the higher
          the chance that it will be seen by JTank Panel.
        </p>
        <img alt="jtank logo" src="assets/bluej/contest/jtank-color-logo.png"/>
      </div>
    </div>

    <div id="detail-preview" @thePreview *ngIf="showPreview">
      <div id="back-button">
        <button id="back-btn" (click)="onChangeToPreview()">
          <i class="fas fa-caret-left"></i>
          Back to my Idea
        </button>
      </div>

      <div id="section-container">
        <div id="image-section" *ngIf="imageUrl">
          <img id="selected-img" alt="preview image" [src]="imageUrl"/>
        </div>

        <div id="info-section">
          <h2>{{ideaForm.get('title').value}}</h2>
          <p>{{ideaForm.get('category').value}}</p>

          <p>{{ideaForm.get('description').value}}</p>

          <div id="like-dislike-section"></div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="!contest || !contest.submission_active"
    class="d-flex flex-column align-items-center p-5"
  >
    <img alt="submission period not active" src="assets/bluej/contest/end-submission-period.png" />
    <p id="empty-state-graph">The Submission Period Is Not Active</p>
  </div>
</div>
