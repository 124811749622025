import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Post} from '../core/models/post';
import {CategoriesService} from '../core/categories.service';
import {PostsService} from '../core/posts.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {PaginatedPosts2ListResponse, Posts2} from '../core/models/paginatedPosts2ListResponse';
import {Posts2Service} from '../core/posts2.service';
import {IResource2} from '../core/models/resource2';
import {Resources2Service} from '../core/resources2.service';

@Component({
  selector: 'jz-jnews',
  templateUrl: './jnews.component.html',
  styleUrls: ['./jnews.component.scss']
})
export class JnewsComponent implements OnInit {
  posts: Post[];
  paginatedPosts2ListResponse: PaginatedPosts2ListResponse;
  errorMessage = '';
  cat = 'jnews';
  resources2: IResource2[];
  page = 1;
  listOfPosts2: Posts2[];
  totalPages: number;

  constructor(
    private categoriesService: CategoriesService,
    private postsService: PostsService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private posts2Service: Posts2Service,
    private resourceService: Resources2Service
  ) {
  }

  ngOnInit(): void {
    // this.spinner.show();
    // if (this.categoriesService.categories['jnews']) {
    //   this.postsService.getPosts(this.categoriesService.categories['jnews'])
    //     .subscribe((data: Post[]) => {
    //       this.posts = data;
    //       this.spinner.hide();
    //     });
    // } else {
    //   this.categoriesService.getCategories().subscribe((data) => {
    //     this.categoriesService.setCategories(data);
    //     this.postsService.getPosts(this.categoriesService.categories['jnews'])
    //       .subscribe((postData: Post[]) => {
    //         this.posts = postData;
    //         this.spinner.hide();
    //       });
    //   });
    // }
    this.fetchPosts2();
    this.fetchResources2();
  }

  // private fetchPosts2() {
  //   this.spinner.show();
  //   this.posts2Service.getPosts2Category(this.cat).subscribe({
  //     next: response => {
  //       this.posts2List = response[0].posts;
  //       this.spinner.hide();
  //     },
  //     error: err => this.errorMessage = err
  //   });
  // }

  private fetchResources2() {
    this.resourceService.getResources2ByCat(this.cat).subscribe({
        next: response => {
          if (response.length) {
            this.resources2 = response;
          }
        }
      }
    );
  }

  // private function below to call posts2 paginated
  private fetchPosts2() {
    this.spinner.show('spinner1');
    this.posts2Service.getPosts2PaginatedByCategory(this.cat, this.page).subscribe({
      next: response => {
        this.paginatedPosts2ListResponse = response;
        // this.posts2List = response[0].posts;
        // console.log(this.paginatedPosts2ListResponse);
        this.listOfPosts2 = this.paginatedPosts2ListResponse.posts;
        this.totalPages = this.paginatedPosts2ListResponse.total_pages;
        this.spinner.hide('spinner1');
      },
      error: err => this.errorMessage = err
    });
  }

}
