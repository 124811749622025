<div *ngIf="response" class="input-controller-cont d-flex flex-row">
  <p *ngIf="!editBoolean" class="question-response">{{response}}</p>
  <button *ngIf="!editBoolean" type="submit" class="submit-answer-btn" (click)="editing()">Edit</button>
  <input *ngIf="editBoolean" type="text" [(ngModel)]="textEdited" class="form-control" placeholder="Edit your answer" name="text">
  <button *ngIf="editBoolean" type="submit" class="submit-answer-btn" (click)="edit(responseArray[0].id, textEdited)">Save</button>
</div>

<div *ngIf="!response" class="form-group input-controller-cont d-flex flex-row justify-content-center align-items-center">
  <input type="text" [(ngModel)]="text" class="form-control" placeholder="Add your answer" name="text">
  <button type="submit" class="submit-answer-btn" (click)="save(componentPK, email, text)">Save</button>
</div>
