import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnChanges, SimpleChanges,
} from '@angular/core';

import { OrgData } from 'src/app/org-chart/orgData';
import { AngOrgChartService } from '../services/ang-org-chart.service';

@Component({
  selector: 'jz-org-chart-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss', '../org-chart-combined.scss']
})
export class EntityComponent implements AfterViewInit, OnChanges{
  constructor(private evtSvc: AngOrgChartService){}



  isVisible = true;
  offices = [];
  departments = [];
  divisions = [];
  private _data: OrgData;
  private _searchParams: []
  openUserDetailedView = false;
  userEmail = localStorage.getItem('email');
  
  @Output() toggleChild = new EventEmitter();
  @Output() modalEmail = new EventEmitter<string>();
  @Input() user: string;
  @Input() hasParent = false;
  @Input() hideChild;
  @ViewChild('Target') target: ElementRef;
  @ViewChild('Person') person: ElementRef;
  @Input() set data(value: OrgData) {
    this._data = value;
    }

    get data(): OrgData{
      return this._data;
    }

  @Input() set searchParams(value: []) {
    this._searchParams = value;
    this.isVisible = true;
 
    // offices
    this.offices = this._searchParams.map(a=>{
      if(a['type'] === 'office'){
        return String(a['value']).toLowerCase()
      }
    });

    this.offices = this.offices.filter(function ( element ) {
      return element !== undefined;
   });

    // departments
    this.departments = this._searchParams.map(a=>{
      if(a['type'] === 'department'){
        return String(a['value']).toLowerCase()
      }
    });

    this.departments = this.departments.filter(function ( element ) {
      return element !== undefined;
   });

    // divisions
    this.divisions = this._searchParams.map(a=>{
      if(a['type'] === 'division'){
        return String(a['value']).toLowerCase()
      }
    });

    this.divisions = this.divisions.filter(function ( element ) {
      return element !== undefined;
   });


  


    if (this.offices.includes(this.data.office.toLowerCase()) == false) {
      this.isVisible = false
    }

    if (this.offices.length == 0) {
        this.isVisible = true;
    }

     
      if(this.departments.length > 0){
        if (this.departments.includes(this.data.department.toLowerCase()) == false) {
          this.isVisible = false
        }
      }
      
  
      // if (this.departments.length == 0) {
      //     this.isVisible = true;
      // }

     
      if (this.divisions.length > 0){
        if (this.divisions.includes(this.data.division.toLowerCase()) == false) {
          this.isVisible = false
        }
      }
      
  
      // if (this.divisions.length == 0) {
      //     this.isVisible = true;
      // }

  

  //   this._searchParams.forEach(param => {

  //     if (param['type'] == 'department'){
  //       if(this.data.department.toLowerCase() !== String(param['value']).toLowerCase()) {
  //         this.isVisible = false
  //       };
  //     }

  //     if (param['type'] == 'division') {
  //       if (this.data.division.toLowerCase() !== String(param['value']).toLowerCase()){
  //         this.isVisible = false;
  //       };
  //     }
  //   });


  }

  get searchParams(): []{
    return this._searchParams
  }

  toggleShowChild(e){
    this.toggleChild.emit(e);
  }

  open(email: string,event): void {
    let open = true
    event.stopPropagation();
    let modals = document.getElementsByClassName('detailed-user-modal')
    
    let main_contents = document.getElementsByClassName('main-cont') as HTMLCollectionOf<HTMLElement>

    //if scale isn't one
    // Array.from(main_contents).forEach(content=>{
    //   var scaleX = content.getBoundingClientRect().width / content.offsetWidth;
    //   if(scaleX){
    //     if(Math.abs(scaleX - 1) > .03){
    //       Array.from(modals).forEach(element => {element.classList.add('display-none')});
    //       open = false
    //     }
    //   }      
      
    // })

    if(open){
      this.openUserDetailedView = !this.openUserDetailedView
   
    
      // Array.from(modals).forEach(element => {element.classList.add('display-none')});
      this.userEmail = email;
      if (!this.openUserDetailedView) {
        this.evtSvc.emitChildEvent(email,true)
        this.modalEmail.emit(email)
        this.openUserDetailedView = true;
        // setTimeout(()=>{event.target.parentElement.parentElement.querySelector('.detailed-user-modal').classList.remove('display-none')},25)
        
      } else {
        this.evtSvc.emitChildEvent(email,false)
      } 
    }

    }

  closeUserDetailView(): void {
    this.openUserDetailedView = false;
    this.evtSvc.emitChildEvent('',false)
  }


  ngAfterViewInit() {
    this.openUserDetailedView = true;

    if (this.target != null) {
      const target = this.target.nativeElement;
      // tslint:disable-next-line:variable-name
      const data_check = this.person.nativeElement;
      // tslint:disable-next-line:variable-name
      const loading_interval = setInterval(function(){data_loaded(data_check, target, loading_interval);}, 100)
    };
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
}

function data_loaded(element, target, interval) {
  if (element.innerText != ''){
    target.scrollIntoView({inline:'center'});
    element.parentElement.parentElement.parentElement.querySelector('.detailed-modal').click()
    element.parentElement.parentElement.parentElement.classList.add('entity-select')
    clearInterval(interval);
  }
}
