import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogoServiceService {
  url = 'https://api.jcosmos.net/api/v1/site_meta/items/'; // prod
  // url = 'https://stagingapi.jcosmos.net/api/v1/site_meta/items/';
  // url = 'https://devapi.jcosmos.net/api/v1/site_meta/items/'; // dev

  constructor(private http: HttpClient) {}

  getSiteMeta(): Observable<any> {
    return this.http.get<any>(this.url);
  }
}
