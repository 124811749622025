import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {IResource2} from './models/resource2';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Resources2Service {
  private url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  // method to get resources2 for specific cat
  getResources2ByCat(cat: string): Observable<IResource2[]> {
    return this.http.get<IResource2[]>(this.url + 'resources2/get?app=resources2&model=category&category=' + cat).pipe(
      catchError(this.handleError)
    );
  }

  // method to get all resources2
  getAllResources2(): Observable<IResource2[]> {
    return this.http.get<IResource2[]>(this.url + 'resources2/get?app=jtraining&model=category').pipe(
      catchError(this.handleError)
    );
  }

  // method to handle the error
  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
