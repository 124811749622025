<section id="right-content">
  <div class="container">
    <div class="row">
      <!--left side-->
      <div class="col-lg-12 col-xl-4 mb-4 mb-xl-0">
        <div class="d-flex flex-row flex-column flex-md-row flex-xl-column h-100 ">
          <!--weather card-->
          <div id="weather-card-cont" class="d-flex justify-content-center align-items-start flex-fill d-sm-none d-md-none d-lg-none d-xl-block w-100">
            <jz-weather-card class="w-100 h-100"></jz-weather-card>
          </div>
          <!--upcoming events-->
          <div class="d-flex justify-content-center align-items-lg-start align-items-xl-center flex-fill order-md-3 order-lg-3 order-xl-2 w-100 mb-4 mb-md-0 mb-xl-4 mt-4 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-4">
            <div id="white-bkg" class="d-flex justify-content-center align-items-center align-items-lg-start flex-fill order-md-3 order-lg-3 order-xl-2 ml-md-4 ml-lg-4 ml-xl-0 w-100 h-100">
              <jz-upcoming-events class="w-100 h-100"></jz-upcoming-events>
            </div>
          </div>
          <!--recognition card-->
          <div id="weather-recognition-cont" class="d-flex flex-md-column flex-lg-column flex-xl-row justify-content-center flex-fill align-items-end order-md-1 order-lg-1 order-xl-2 w-100">
            <jz-weather-card class="d-lg-block d-none d-sm-block d-md-block d-lg-block d-xl-none mb-md-4 mb-lg-4 mr-sm-4 mr-md-0 w-100"></jz-weather-card>
            <jz-recognition-card class="w-100 h-100"></jz-recognition-card>
          </div>
        </div>
      </div>
      <!--right side-->
      <div class="col-lg-12 col-xl-8">
        <div class="d-flex flex-column h-100">
          <!--carousel-->
          <div class="d-flex justify-content-center align-items-center flex-fill mb-4 w-100">
            <jz-carousel style="width: 100%"></jz-carousel>
          </div>
          <!--quick links & b-day card-->
          <div class="d-flex flex-fill align-items-end flex-wrap">
            <div class="quick-cont mb-4 mb-sm-4">
              <jz-quick-links></jz-quick-links>
            </div>
            <div class="bday-cont mb-4 mb-sm-4">
              <jz-bday-card></jz-bday-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
