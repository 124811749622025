import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {PostCategory} from './models/postCategory';
import {HomeMessages} from './models/home-messages';
import {IPhotoCategory} from './models/halloween-contest';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  url = environment.apiUrl;

  categories = {}; // Storing categories index
  categoriesCache = [];
  categoriesSet = new Subject<boolean>();

  constructor(
    private http: HttpClient
  ) { }

  getCategories(): Observable<PostCategory[]> {
    return this.http.get<PostCategory[]>(this.url + 'posts/post_categories');
  }
  // name categories value
  setCategories(categories: PostCategory[]) {
    categories.forEach((category: PostCategory) => {
      this.categories[category.name] = category.id;
    });
  }

  setCategoriesCacheData(data: PostCategory[]) {
    this.categoriesCache = data;
  }
  //  get messages
  getMessages(): Observable<HomeMessages[]>{
    return this.http.get<HomeMessages[]>(this.url + 'notifications/messages');
  }

  // get categories for Halloween Contest
  getSubmissionCategories(): Observable<IPhotoCategory[]> {
    return this.http.get<IPhotoCategory[]>(this.url + 'photo_contest/photo_categories');
  }

}
