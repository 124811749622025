import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Diversity} from '../core/models/diversity';
import {IResource2} from '../core/models/resource2';
import {PaginatedPosts2ListResponse, Posts2} from '../core/models/paginatedPosts2ListResponse';
import {NgxSpinnerService} from 'ngx-spinner';
import {Posts2Service} from '../core/posts2.service';
import {Resources2Service} from '../core/resources2.service';

@Component({
  selector: 'jz-diversity',
  templateUrl: './diversity.component.html',
  styleUrls: ['./diversity.component.scss']
})
export class DiversityComponent implements OnInit {

  diversityResources: Diversity[];
  cat = 'Diversity and Inclusion';
  resources2: IResource2[];
  errorMessage = '';
  paginatedPosts2ListResponse: PaginatedPosts2ListResponse;
  page = 1;
  listOfPosts2: Posts2[];
  totalPages: number;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private posts2Service: Posts2Service,
    private resourceService: Resources2Service
  ) { }

  ngOnInit(): void {
    this.fetchResources2();
    this.fetchPosts2();
  }

  // private fetchPosts2() {
  //   this.spinner.show();
  //   this.posts2Service.getPosts2Category(this.catForResources).subscribe({
  //     next: response => {
  //       if (response.length) {
  //         this.posts2List = response[0].posts;
  //       }
  //       this.spinner.hide();
  //     },
  //     error: err => this.errorMessage = err
  //   });
  // }

  private fetchResources2() {
    this.resourceService.getResources2ByCat(this.cat).subscribe({
        next: response => {
          this.resources2 = response;
        }
      }
    );
  }

  // private function below to call posts2 paginated
  private fetchPosts2() {
    this.spinner.show();
    this.posts2Service.getPosts2PaginatedByCategory(this.cat, this.page).subscribe({
      next: response => {
        this.paginatedPosts2ListResponse = response;
        // this.posts2List = response[0].posts;
        // console.log(this.paginatedPosts2ListResponse);
        this.listOfPosts2 = this.paginatedPosts2ListResponse.posts;
        this.totalPages = this.paginatedPosts2ListResponse.total_pages;
        this.spinner.hide();
      },
      error: err => this.errorMessage = err
    });
  }

}
