<section class="bg-purple">

  <div class="stars">
    <div class="custom-navbar">
      <div class="brand-logo">
        <img src="assets/nav/New_JCosmos_Logo.png" width="100px" alt="jcosmos logo">
      </div>
    </div>
    <div class="central-body">
      <img class="image-404" src="http://salehriaz.com/404Page/img/404.svg" width="300px" alt="404 pic">
      <a [routerLink]="['/', 'home']" class="btn-go-home">GO BACK HOME</a>
    </div>
    <div class="objects">
      <img class="object_rocket" src="http://salehriaz.com/404Page/img/rocket.svg" width="40px" alt="rocket pic">
    <div class="earth-moon">
      <img class="object_earth" src="http://salehriaz.com/404Page/img/earth.svg" width="100px" alt="earth pic">
      <img class="object_moon" src="http://salehriaz.com/404Page/img/moon.svg" width="80px" alt="moon pic">
    </div>
    <div class="box_astronaut">
      <img class="object_astronaut" src="http://salehriaz.com/404Page/img/astronaut.svg" width="140px" alt="astronaut pic">
    </div>
  </div>
  <div class="glowing_stars">
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
  </div>

  </div>

</section>
