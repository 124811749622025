import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {EventService} from '../../core/event.service';
import {CalendarEvent} from '../../core/models/calendar-event';
import {GlobalEvents} from '../../core/models/global-events';

@Component({
  selector: 'jz-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss']
})
export class UpcomingEventsComponent implements OnInit {

  today = new Date();
  months = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
  };
  day: number;
  month: number;
  year: number;
  calendarEvents: CalendarEvent[];
  test = '';
  upcomingEvents: GlobalEvents[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private eventService: EventService,
  ) { }

  ngOnInit(): void {
    // dates calculation
    this.day = this.today.getDate();
    this.month = this.today.getMonth() + 1;
    this.year = this.today.getFullYear();

    this.fetchUpcomingEvents();
  }

  // convertDateFromIntToString(date) {
  //  const dateComp = date.split('T');
  //  const dateParts = dateComp[0].split('-');
  //  const day = dateParts[2];
  // }

  // method to get the date
  convertStringToDateObj(date) {
    return new Date(date).getDate();
  }

  // method to get the full year
  convertStringToFullYear(date) {
    return new Date(date).getFullYear();
  }

  // method to get the month
  convertStringToMonth(date) {
    const newDate = new Date(date);
    const testDate = newDate.getMonth() + 1;
    return this.months[testDate];
  }

  // private fetchLatestGlobalEvents(startDate, endDate) {
  //   this.eventService.getGlobalEventsByDates(startDate, endDate).subscribe({
  //     next: response => {
  //       this.globalEvents = response;
  //       // console.log('globalevents', this.globalEvents);
  //       // console.log('fjfjfjfj', this.globalEvents[0].datetime);
  //       this.test = this.globalEvents[0].datetime;
  //     }
  //   });
  // }

  private fetchUpcomingEvents() {
    this.eventService.getUpcomingEvents().subscribe({
      next: response => {
        this.upcomingEvents = response;
        // console.log('testing', this.upcomingEvents);
      }
    });
  }
}
