<div id="right-content">
  <div class="container-fluid">

<!--    <jz-header-->
<!--      [title]="'Tools to Thrive at Home'"-->
<!--      [color]="'#53c9e8'"-->
<!--      [icon]="'https://jcosmos.s3.amazonaws.com/media/icons/Helpful-Tips.png'"-->
<!--    ></jz-header>-->

    <!-- Spinner start -->
    <ngx-spinner
      bdColor="rgba(51,51,51,0.3)"
      size="medium"
      color="white"
      type="ball-scale-multiple"
      [fullScreen]="true"
    ></ngx-spinner>
    <!-- Spinner end -->

    <!--  posts  -->
    <jz-common-post-list [posts]="posts"></jz-common-post-list>

  </div>
</div>
