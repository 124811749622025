<div id="learning-hub-card" class="resource-list-container d-flex flex-column justify-content-end h-100">
  <span style="background: #FFFFFF;
          border-radius: 20px;
          color: #FFFFFF;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 18px">-</span>
<!--  <h5 style="font-weight: bold">The Learning Hub</h5>-->
<!--  <div class="resource-container">-->
<!--    <p>-->
<!--      {{(text.length > 66) ? (text | slice: 0: 66) + '...' : (text)}}-->
<!--    </p>-->
<!--    &lt;!&ndash;    <button type="button" class="btn btn-outline-primary">Click here</button>&ndash;&gt;-->
<!--  </div>-->
  <div class="action-button text-center">
    <a href="https://app.smartsheet.com/b/home?lgt=wf&lrt=s&EQBCT=9b8468678cbc4eb8b715f29185973231
        " class="btn btn-outline-primary" tabindex="-1" role="button" aria-disabled="true" [routerLink]="['/human-resources/learning-hub']">
      Click Here
    </a>
  </div>
</div>
