import {Component, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {PostCategory} from './core/models/postCategory';

@Component({
  selector: 'jz-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  onMobile: boolean;

  constructor(
    private router: Router
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
      if (event instanceof NavigationEnd) {
        window.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth'
        });
      }
    });
  }


  ngOnInit(): void {
  }

  // Triggered on each resize. Called in OnInit too.
  @HostListener('window:resize', ['$years'])
  _getScreenSize(event?) {
    this.onMobile = window.innerWidth <= 768;
  }

  navigationInterceptor(event: RouterEvent) {
    if (event instanceof NavigationEnd) {
      this.fireGoogleAnalitics(event);
    }
  }

  /*** For SPA we need to fire events manually (header only loads once)
   * like this pageview * */
  fireGoogleAnalitics(event: NavigationEnd) {
    (window as any).ga('set', 'page', event.urlAfterRedirects);
    (window as any).ga('send', 'pageview');
  }

}
