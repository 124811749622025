import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {LearningHub} from '../models/learning-hub';
import {LearningHubService} from '../learning-hub.service';

@Injectable()
export class TheLearningHub implements Resolve<LearningHub[]> {

  constructor(
    private learningHub: LearningHubService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<LearningHub[]> | Promise<LearningHub[]> | LearningHub[] {
    return this.learningHub.getLearningHubResources();
  }
}
