<div id="bg-login" class="px-2 px-md-4">
  <div class="d-flex flex-column" style="height: 100%">
    <!--    logo-->
    <div style="max-width: 500px;margin: auto" class="mb-0">
      <img id="big-logo" class="img-fluid" src="/assets/login/New_Big_JCosmos_Logo.png" alt="JCosmos logo">
    </div>
    <!--    login btn-->
    <div id="login-google" class="my-0" style="margin: auto">
      <div>
        <a (click)="signInWithGoogle()">
          <img src="/assets/login/btn_google_signin_light_pressed_web.png"
               class="mx-auto d-block"
               alt="google login logo">
        </a>
      </div>
    </div>
    <!--    social-->
    <div class="mt-0" style="margin: auto">
      <img src="/assets/login/Facebook01.png" class="img-fluid" alt="facebook logo">
      <img src="/assets/login/Instagram01.png" class="img-fluid" alt="instagram logo">
      <img src="/assets/login/Twitter01.png" class="img-fluid" alt="twitter logo">
      <img src="/assets/login/YouTube01.png" class="img-fluid" alt="youtube logo">
    </div>
    <!-- Jazwares Logo     -->
    <div>
      <img src="/assets/login/JAZWARES_1C_White_Wordmark-Icon_Logo.png" class="img-fluid float-right"
           width="10%" id="Icon_logo" alt="jazwares logo">
    </div>
  </div>
</div>

