import {Component, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IPhotoCategory, ISubmission} from '../../core/models/halloween-contest';
import {SubmissionService} from '../../core/submission.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'jz-vote-content',
  templateUrl: './vote-content.component.html',
  styleUrls: ['./vote-content.component.scss']
})
export class VoteContentComponent implements OnInit {

  submissions: ISubmission[];
  categories: IPhotoCategory[];
  voted: boolean;
  modalRef?: BsModalRef;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private submissionService: SubmissionService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit(): void {
    this.getRouteData();
  }

  submitVote() {
    const listRelationList = [];

    let atLeastOneVote = false;

    for (const cat of this.categories) {
      if (cat.associated_picture) {
        atLeastOneVote = true;
        listRelationList.push(([parseInt(String(cat.associated_picture), 10), cat.id]));
      }
    }

    if (atLeastOneVote) {
      this.submissionService.vote(listRelationList).subscribe((response) => {
        this.voted = true;
        this.toastr.success('Thanks for voting', 'Success');
      });
    } else {
      this.toastr.warning('Please select at least one Picture for a Category.', 'Invalid Vote');
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-lg');
  }

  private getRouteData() {
    this.route.data.subscribe((data) => {
      this.submissions = data.submissions;
      this.categories = data.photoCategories;
      this.voted = data.currentUserVoted.voted;
      // console.log(this.voted);
    });
  }

}
