<div id="right-content" class="bj-card">
  <!--  Blue j-->
  <div id="blue-j">
    <div class="container">
      <div class="row">
        <div class="col-12" style="margin-top: 20px;">
          <h4><img src="assets/bluej/blue-j-logo.png" width="140px" alt="bluej logo">Innovate</h4>
        </div>
      </div>
      <!-- Spinner start -->
      <ngx-spinner
        bdColor="rgba(51,51,51,0.3)"
        size="medium"
        color="white"
        type="ball-scale-multiple"
        [fullScreen]="true"
      ></ngx-spinner>
      <!-- Spinner end -->

      <div class="row">
        <div class="col-12">
          <div id="ideas-j">
            <h4 class="filsonProMed text-center">Welcome to Blue J, where innovation, insights and ideas collide.</h4>
            <p class="text-center">Check out our weekly vlogs and monthly JTrenz magazine. </p>
            <p class="text-center">Contact us to collaborate on ideas at </p>
            <p class="text-center">bluej@jazwares.com</p>
            <!--            <p class="filsonProMed"><img src="assets/bluej/xls.png">Click here to share your ideas!</p>-->
          </div>
        </div>
      </div>
      <!--      <div class="row mb-4">-->
      <!--        <div class="col-12">-->
      <!--          <a routerLink="/bluej/Bluej-Posts-Detail/ideas">-->
      <!--            <img-->
      <!--              alt="people banner Bluej-Posts-Detail"-->
      <!--              src="assets/bluej/contest/contest-banner.png"-->
      <!--              class="img-fluid"-->
      <!--              width="100%">-->
      <!--          </a>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
  <!--  posts -->
  <jz-common-post-list [posts]="posts"></jz-common-post-list>

</div>
