<div id="right-content">

  <div class="container-fluid" id="content-container">

    <div class="row">
      <div class="col-2">

      </div>
      <div class="col-8">
        <form [formGroup]="messageForm" (ngSubmit)="onSubmitForm()" class="form">
          <div class="row">
            <p class="ask-header pb-0">Ask JCosmos</p>
          </div>
          <div class="row">
            <div class="col-md-12">
              <input placeholder="Subject" formControlName="subject">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <textarea placeholder="Do you have questions or feedback about non-HR related topics? Feel free to submit your questions or comments here. For HR questions, please visit the Human Resources page and use the “Ask HR” form." formControlName="question"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-right">
              <button
                style="cursor: pointer"
                id="submit-button"
                type="submit"
                [disabled]="!messageForm.valid || isSubmitted"
              >Submit</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-2">

      </div>
    </div>


  </div>
</div>

