<section id="pic-vote">
  <div id="header">
    <span class="filsonProMed">Halloween Costume Contest</span>
    <p class="filsonProMed">Vote</p>
  </div>

  <!--  <p class="text-muted mt-2" style="font-size: 12px">Please note - you cannot vote for yourself therefore you will not see your own submission! Don't worry though we have it!</p>-->
  <div>
    <p class="mb-0 mt-3 filsonProMed" style="font-style: italic">It’s Time to Cast Your Ghostly Votes!</p>
    <p class="mb-0">For each category, enter the number of the photo assigned to the Halloween costume you think best
      captures the essence of the category.</p>
    <p class="mb-0">Make sure to scroll through ALL the photos to make the BEST decision!</p>
    <p class="mb-0"><span class="filsonProMed">NOTE:</span> Once you click <span class="filsonProMed">Submit</span>, you
      will not be able to change your vote. All votes are final, so choose carefully and wisely!</p>
  </div>

  <div class="container mt-5">
    <div class="row">
      <!--   Pictures   -->
      <div class="col-9">
        <div id="pics-scroll-cont" class="row row-cols-md-3">
          <div *ngFor="let submission of submissions" class="col">
            <div class="card mb-3">
              <div
                style="
                cursor: pointer;
                width: 100%;
                height: 200px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;"
                [style.background-image]="'url(' + submission.contents[0]?.display_image +')'"
                (click)="openModal(template)"
              >
              </div>
              <div class="card-body" (click)="openModal(template)" style="cursor: pointer">
                <p class="card-text filsonProMed">{{submission.title}}</p>
              </div>
              <div class="card-footer text-center">
                {{submission.pk}}
              </div>
            </div>
            <!--template for Modal with bigger img-->
            <ng-template #template>

              <div class="modal-header">
                <h4 class="modal-title pull-left">{{submission.title}}</h4>
                <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
                  <span aria-hidden="true" class="visually-hidden">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <img [src]="submission.contents[0]?.display_image" alt="submission.title" class="w-100">
              </div>
            </ng-template>

          </div>
        </div>
      </div>
      <!--   categories / voting   -->
      <div id="cat-column" class="col-3">
        <div *ngIf="!voted">
          <h2 class="filsonProMed mb-3">Categories</h2>

          <div *ngFor="let cat of categories" class="d-flex flex-row mt-2">
            <p class="m-0 mt-1 filsonProMed flex-grow-1">
              {{cat.photo_category_name}}
            </p>
            <div class="question-bubble-cat ml-2" tooltip="{{cat.description}}">
              ?
            </div>
            <input type="text" class="text-center" style="width: 40px;height: 30px"
                   [(ngModel)]="cat.associated_picture">
          </div>

          <div class="w-100 text-center mt-4">
            <button type="button" (click)="submitVote()" class="s-button">Submit</button>
            <p class="text-muted mt-1" style="font-size: 12px">Votes are final.</p>
          </div>
        </div>
        <div *ngIf="voted">
          <!--          <h5 class="text-muted text-center">Are you ready?</h5>-->

          <h5 class="text-muted">You have already Voted.</h5>
          <h5 class="text-muted">Thank you!</h5>
        </div>

      </div>
    </div>
    mi
  </div>


</section>
