import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService, SocialUser } from 'angularx-social-login';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  url = environment.authApiUrl;
  privateUserLoggedIn: boolean;

  isLoggedIn = new Subject<boolean>();
  user: SocialUser;

  constructor(private http: HttpClient, private authService: AuthService) {}

  // googleAccessTokenVerification(accessToken: string) {
  //   return this.http.post(this.url, { access_token: accessToken });
  // }
  getCustomUserProfile() {
    return this.http.get(this.url);
  }

  isUserLoggedIn(): boolean {
    this.privateUserLoggedIn = !!localStorage.getItem('token');
    this.isLoggedIn.next(!!localStorage.getItem('token'));
    return this.privateUserLoggedIn;
  }

  setLoggedInUser(user: SocialUser) {
    localStorage.setItem('token', user.idToken);
    this.user = user;
  }

  getToken() {
    return localStorage.getItem('token');
  }

  // Log out employee
  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.authService.signOut(true);
    this.isUserLoggedIn();
  }
}
