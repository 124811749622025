
<div class="container-fluid" style="padding-bottom: 50px;
padding-top: 20px; border-radius: 20px; background: #FFFFFF; position: relative; width: 98%; margin-top: 22px; height: 860px" #container>
   <span style="background: #54C8E8;
          border-radius: 20px;
          color: #54C8E8;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 28px">-</span>
    <p style="color: #707070; font-size: 30px; text-align: left; margin-left: 10px"><b>Employee Recognition</b></p>
    <div
      class="container button-container"
      [routerLink]="['/', 'recog-employees']"
    >
      <i class="fas fa-caret-left" style="color: #C4C4C4;"></i>
      <button>
        Back
      </button>
    </div>
  <div id="blue-container"
    [ngStyle]="{'background-color': recognition.quality.color}"
  >
    <div id="info-container">
      <div id="header-title">
        <div id="employee">
          <!-- Employee profile image -->
          <img
            id="employee-profile"
            [src]="recognition.employee.employee_profile_pic ? recognition.employee.employee_profile_pic : 'assets/new_placeholder.png'"
            alt="Employee profile image"
          />

          <div id="employee-info">
            <h1 id="name">{{recognition.employee.employee_name}}</h1>
            <p id="position">{{recognition.employee.employee_title}}</p>
          </div>
        </div>

        <!-- Badge -->
        <div
          id="badge-container"
          [ngStyle]="{'background-color': darkenColor(recognition.quality.color)}"
        >
          <img id="badge-img" src="assets/recognition/badge.png" alt="badge" />
        </div>
      </div>

      <div
        id="comments"
        [ngClass]="{'comments-class': applyClass && innerWidth > 576}"
      >
        <div
          id="left-imgs"
          [ngClass]="{'left-imgs-class': applyClass && innerWidth > 576}"
        >
          <p id="value">{{recognition.quality.quality_name}}</p>

          <img
            id="value-image"
            [src]="recognition.quality.icon_image"
            alt="value image"
          />

          <img
            alt="greek column"
            src="assets/recognition/column.png"
            id="greek-column"
          />
          <img
            alt="jazwares logo"
            src="assets/recognition/jazwares_letters_in_white.png"
            id="jazwares-letter-white"
          />
        </div>

        <div id="person-comment">
          <p
            id="comment"
            [ngClass]="{'comment-class': applyClass && innerWidth > 576}"
          >{{recognition.description}}</p>

          <p id="mention" [ngClass]="{'comment-class': applyClass && innerWidth > 576}">Mentioned by:</p>

          <h4 id="person" [ngClass]="{'comment-class': applyClass && innerWidth > 576}">{{recognition.referred_by}}</h4>
          <p id="person-position" [ngClass]="{'comment-class': applyClass && innerWidth > 576}">{{recognition.reference_title}}</p>
        </div>
      </div>

<!--      <img id="jazwares-logo" />-->
    </div>
  </div>
</div>
