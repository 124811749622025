import {Component, Input, OnInit, AfterViewInit} from '@angular/core';

@Component({
  selector: 'jz-hierarchy-container',
  templateUrl: './hierarchy-container.component.html',
  styleUrls: ['./hierarchy-container.component.scss']
})
export class HierarchyContainerComponent implements OnInit, AfterViewInit {
  @Input() data: [];
  expanded = false;
  mockData: [];
  isMobile = window.innerWidth;
  isCollapsed = false;

  // data to pass to child and listening to changes from child
  activeNodeStateChanges = false;
  activeNodeNumberId: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  checkDataFromChildComp(newData: any) {
    this.expanded = !this.expanded;
    this.mockData = newData;
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  checkActiveStatusChangeFromChildComp(activeData: string) {
    this.activeNodeStateChanges = !this.activeNodeStateChanges;
    if (this.activeNodeNumberId !== activeData.toString()) {
      this.activeNodeNumberId = activeData.toString();
    } else {
      this.activeNodeNumberId = '';
    }
  }

}
