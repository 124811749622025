import { Component, OnInit, Input } from '@angular/core';
import {HrCategory} from '../../core/models/hr-category';

@Component({
  selector: 'jz-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit {

  @Input() benefitsArray: HrCategory[];
  subCatArray: HrCategory[];
  isEmpty: boolean;
  panelOpenState = false;

  // mock data to present UI
  benefits = [
    { category: 'Benefits',
      name: 'Benefits Guide',
      resource_type: 'pdf',
      resource_url: 'placeholder',
      external_link: 'placeholder',
      resource_link: 'placeholder',
    },
    { category: 'Benefits',
      name: 'Benefits-at-a-Glance',
      resource_type: 'pdf',
      resource_url: 'placeholder',
      external_link: 'placeholder',
      resource_link: 'placeholder',
    },
    { category: 'Benefits',
      name: 'Metlife Dental Benefit Summary',
      resource_type: 'pdf',
      resource_url: 'placeholder',
      external_link: 'placeholder',
      resource_link: 'placeholder',
    },
  ];

  tuition = [
    {
      category: 'Tuition Reimbursement',
      name: 'Policy',
      resource_type: 'pdf',
      resource_url: 'placeholder',
      external_link: 'placeholder',
      resource_link: 'placeholder',
    },
    {
      category: 'Tuition Reimbursement',
      name: 'Form',
      resource_type: 'pdf',
      resource_url: 'placeholder',
      external_link: 'placeholder',
      resource_link: 'placeholder',
    }
  ];

  retirement = [
    {
      category: '401K',
      name: 'Enrollment Flyer',
      resource_type: 'pdf',
      resource_url: 'placeholder',
      external_link: 'placeholder',
      resource_link: 'placeholder',
    },
    {
      category: '401K',
      name: 'John Hancock Website',
      resource_type: 'link',
      resource_url: 'placeholder',
      external_link: 'placeholder',
      resource_link: 'placeholder',
    },
  ];


  constructor() { }

  ngOnInit(): void {
    this.subCatArray = this.benefitsArray[0].sub_cat;
    this.ifEmpty();
  }

  ifEmpty() {
    if (this.benefitsArray.length) {
      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }
  }

}
