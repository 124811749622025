import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FetchingDataService} from '../../services/fetching-data.service';
import {Employee} from '../../models/employee';
import {Hierarchy} from '../../models/hierarchy';

@Component({
  selector: 'jz-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit {
  // variables related to form
  newFilterGroup: FormGroup;
  name: FormControl;
  department: FormControl;
  division: FormControl;
  location: FormControl;
  clearOptionVar = false;

  // variables related to fetch services
  departmentArray: any;
  divisionArray: any;
  locationArray: any;
  searchResultsHierarchyArray: Hierarchy;

  // variables related to right side cards
  searchResultsArray: Employee[];
  searchWasDid = false;

  // variables related to changes on devices
  deviceInnerWidth = window.innerWidth;
  leftSideMenuVisibleForMobile = true;

  constructor(public dialogRef: MatDialogRef<ProfileModalComponent>, private fetchDataServices: FetchingDataService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.name = new FormControl('', Validators.required);
    this.department = new FormControl('', Validators.required);
    this.division = new FormControl('', Validators.required);
    this.location = new FormControl('', Validators.required);

    this.newFilterGroup = new FormGroup({
      name: this.name,
      department: this.department,
      division: this.division,
      location: this.location,
    });

    this.fetchDep();
    this.fetchDiv();
    this.fetchLoc();
  }

  // submitting search
  saveSearchParameters(FormValues: any) {
    const search: any = {
      name : FormValues.name,
      department : FormValues.department,
      division: FormValues.division,
      location: this.location,
    };

    this.fetchDataServices.fetchSearchByEmployees(search.name, search.department).subscribe({
      next: response => {
        this.searchResultsArray = response;
        // console.log('let see some magic', response);
        this.searchWasDid = true;
      }
    });
    if (this.deviceInnerWidth < 768) {
      this.leftSideMenuVisibleForMobile = false;
    }
  }

  // method to fetch departments list
  fetchDep() {
    this.fetchDataServices.fetchAllDepartments().subscribe({
      next: response => {
        this.departmentArray = response;
      }
    });
  }

  // method to fetch divisions list
  fetchDiv(): void {
    this.fetchDataServices.fetchAllDivisions().subscribe({
      next: response => {
        this.divisionArray = response;
      }
    });
  }

  // method to fetch locations list
  fetchLoc(): void {
    this.fetchDataServices.fetchAllLocations().subscribe({
      next: response => {
        this.locationArray = response;
      }
    });
  }

  // method to close dialog
  closeDialog(): void {
    this.dialog.closeAll();
  }

  backToFilters() {
    this.leftSideMenuVisibleForMobile = true;
    this.searchWasDid = false;
    this.searchResultsArray = [];
  }

  // method to fetch employee's hierarchy
  fetchHierarchy(numberEmployee: any): void {
    this.fetchDataServices.fetchSearchHierarchyByEmployee(numberEmployee).subscribe({
      next: response => {
        this.searchResultsHierarchyArray = response;
        this.dialogRef.close(this.searchResultsHierarchyArray);
      }
    });
  }

  clearOption() {
    this.clearOptionVar = true;
  }

  arh() {
    this.clearOptionVar = false;
  }
}
