<div class="resource-list-container">
  <span style="background: #FF6200;
          border-radius: 20px;
          color: #FF6200;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 18px">-</span>
  <h5 style="font-weight: bold">{{subCat.name}}</h5>
  <div *ngFor="let resource of subCat.res_by_sub_cat">
    <a
      class="resource-container"
      [href]="(
       resource.resource_type === 'pdf' ||
       resource.resource_type === 'xlsx' ||
       resource.resource_type === 'video') ? resource.resource_url : resource.external_link ? resource.external_link : resource.resource_link"
      [target]="'_blank'"
      style="text-decoration: none; color: black;"
    >
      <img
        alt="file type"
        [src]="resource.resource_type === 'pdf' ? 'assets/human-resources/pdf.svg' : 'assets/human-resources/arrow-right.svg'"
      />
      <p>{{resource.name}}</p>
    </a>
  </div>
</div>

