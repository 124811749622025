import {Component, OnInit} from '@angular/core';
import {UsersService} from '../../core/users.service';
import {Employee} from '../../new-org-chart/models/employee';

@Component({
  selector: 'jz-bday-card',
  templateUrl: './bday-card.component.html',
  styleUrls: ['./bday-card.component.scss']
})
export class BdayCardComponent implements OnInit {

  users: Employee;
  indexPosition = 0;
  upcomingBDays = true;
  today = new Date();
  day: number;
  month: number;
  year: number;
  months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  };
  errorMessage = '';
  separator = '-';

  constructor(
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
    // dates calculation
    this.day = this.today.getDate();
    this.month = this.today.getMonth() + 1;
    this.year = this.today.getFullYear();

    this.fetchUsers(this.month);
  }

  private fetchUsers(month: number) {
    this.usersService.getUsersByDates(month).subscribe({
      next: response => {
        this.users = response;
        // console.log('getting new list of users', this.users);
        // if (this.users.length <= 1){
        //   this.upcoming_bdays = false;
        // }
      },
      error: err => this.errorMessage = err
    });
  }

  nextUser() {
    const newIndex = this.indexPosition + 1;
    this.indexPosition = newIndex;
  }

  previousUser() {
    if (this.indexPosition !== 0) {
      const newIndex = this.indexPosition - 1;
      this.indexPosition = newIndex;
    }
  }

  stringToSplit(birthdate: string, separator: any) {
    const arrayOfStrings = birthdate.split(separator);
    const month = arrayOfStrings[1];
    const intMonth = parseInt(month, 10);
    return this.months[intMonth];
  }

  stringToSplitToDay(birthdate, separator) {
    const arrayOfStrings = birthdate.split(separator);
    return arrayOfStrings[2];
  }

}
