<div id="category-card" class="d-flex flex-column" [ngStyle]="{'margin-top' : margin && indexPosition === 3 ? margin : '',
        'background-color' : backGroundColor === 'GR' ? '#64cc44' : backGroundColor === 'PI' ? '#e6258b' :
        backGroundColor === 'YE' ? '#ffc401' : backGroundColor === 'PU' ? '#9b46bb' : backGroundColor === 'TE' ? '#54c8e8' : ''}">

  <!--icon and text-->
  <div id="icon-and-cat" class="d-flex flex-row">
    <div class="category-icon d-flex flex-row justify-content-center align-items-center">
      <img class="cat-icon-img" [src]="icon" alt="icon image">
    </div>
    <p class="cat-text">{{cat}}</p>
  </div>

  <!--description text-->
  <div class="cat-description">
    <p class="cat-description-text">{{description}}</p>
  </div>

  <!--button-->
  <button class="learning-hub-custom-button" (click)="buttonClicked(catID)">Click here</button>

</div>
