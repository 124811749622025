<div id="right-content">
  <div class="container">

    <!-- Spinner start -->
    <ngx-spinner bdColor="rgba(51,51,51,0.3)" size="medium" color="white" type="ball-climbing-dot"
      [fullScreen]="true"></ngx-spinner>
    <!-- Spinner end -->

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
        <div class="card-height left-card">
          <div class="img-container text-center">
            <!--            <img src="assets/compliance/Corportate-Compliance.jpg" class="img-fluid" alt="picture">-->
          </div>

          <div class="card-body justify-content-center">
            <p class="card-title">Let’s Make a Difference Together</p>
            <p class="card-text">
              At Jazwares we are committed in doing our part to create an equal world for us all.
              Regardless of age, gender, ethnicity, nationality, skin color, religion, sexual orientation
              and other markers by which people identify themselves with, inclusivity and diversity are part
              of our core values.
            </p>
            <p style="font-weight: bold">Have an idea or news you want to share?</p>
            <div class="jdiversity-button d-flex flex-row justify-content-center align-items-center">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSeLsqJibHy6T2FKP1O9rVgIIWb7B3MWQCdqiMpez_ncDSYVVQ/viewform"
                target="_blank"><span>CLICK HERE!</span></a>
            </div>

            <div class="cont-content" *ngIf="resources2">
              <div *ngFor="let resource of resources2[0].resources">
                <a class="resource-container" [href]="(
                           resource.resource_type === 'pdf' ||
                           resource.resource_type === 'xlsx' ||
                           resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                  [target]="'_blank'" style="text-decoration: none; color: black;">
                  <img alt="file type"
                    [src]="resource.resource_type === 'pdf' ? 'assets/resources-colored-icon.png' : 'assets/links-logo.png'" />
                  <p>{{resource.name}}</p>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-8 mt-0 mt-4 mt-md-0">
        <!-- posts and resources go here -->
        <jz-common-post-list [listOfPosts2]="listOfPosts2" [resources2]="resources2" [totalPages]="totalPages"
          [page]="page" [cat]="cat"></jz-common-post-list>
      </div>

    </div>
  </div>
</div>