import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {FetchingDataService} from '../../services/fetching-data.service';
import {Employee} from '../../models/employee';
import {ProfileQuestionsServiceService} from '../../../user-profile/services/profile-questions-service.service';
import {IQA} from '../../../user-profile/models/q&a';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'jz-right-menu-modal',
  templateUrl: './right-menu-modal.component.html',
  styleUrls: ['./right-menu-modal.component.scss']
})
export class RightMenuModalComponent implements OnInit {

  test: string;
  employee: Employee;
  peersBackgroundColor = 'rgb(84, 200, 232)';
  reportsBackgroundColor = '#f5f5f6';
  employeeManagerNumber: string;
  newManagesDataArray: [];
  manager: Employee;
  funFacts: IQA[];
  @ViewChild('anchor') anchor: ElementRef;

  // tslint:disable-next-line:max-line-length
  constructor(private viewPortScroller: ViewportScroller, private userResponsesService: ProfileQuestionsServiceService, private dialog: MatDialog,
              private fetchDataServices: FetchingDataService, @Inject(MAT_DIALOG_DATA) data) {
    this.test = data.number;
  }

  ngOnInit(): void {
    this.fetchByEmployeeNumber(this.test);
  }

  // scrolling to anchor
  onClick() {
    const targetElement = this.anchor.nativeElement;
    targetElement.scrollIntoView({behavior: 'smooth'});
  }

  // fetching user's fun facts
  // fetchFunFacts() {
  //   this.userResponsesService.fetchResponseByUsersEmail(this.employee.email).subscribe({
  //     next: response => {
  //       this.funFacts = response;
  //       console.log('user fun facts', this.funFacts);
  //     },
  //     error: err => {
  //       console.log('There was an error', err);
  //     }
  //   });
  // }

  // receiving new test variable
  receivedNewTest(newTestValueReceived: string) {
    this.fetchByEmployeeNumber(newTestValueReceived);
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  searchForNewSetOfData(employeeNumber: string) {
    this.fetchByEmployeeNumber(employeeNumber);
  }

  fetchEmployeeManager(employeeManagerNumber: string) {
    this.fetchDataServices.fetchEmployeePerNumber(employeeManagerNumber).subscribe({
      next: response => {
        this.manager = response;
      }
    });
  }

  fetchByEmployeeNumber(employeeNumber: string) {
    this.fetchDataServices.fetchEmployeePerNumber(employeeNumber).subscribe({
      next: response => {
          this.employee = response;
          this.employeeManagerNumber = response.manager;
          this.fetchEmployeeManager(this.employeeManagerNumber);
          this.newManagesDataArray = response.manages;
          // this.fetchFunFacts();
      }
    });
  }
}
