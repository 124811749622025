
<div id="right-content">

  <!--calling post lists for this component-->
  <div class="container" id="comp_container">

    <!-- Spinner start -->
    <ngx-spinner
      bdColor="rgba(51,51,51,0.3)"
      size="large"
      color="white"
      type="ball-climbing-dot"
      [fullScreen]="true"
    ></ngx-spinner>
    <!-- Spinner ends -->

    <div class="row">

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngIf="paginatedPosts2ListResponse">
        <div class="left-card card-height">
          <div class="img-container">
            <img src="assets/Did-you-know/Did-you-know-logo.png" class="img-fluid" alt="picture">
          </div>
          <div class="card-body">
            <p class="card-title">Did you know?</p>
            <p class="card-text">
              Check out some interesting facts and benefits you may not have known were available for Jazmanians.
              Click and learn or relearn what you may have missed.
            </p>
            <div class="cont-content" *ngIf="resources2">
              <div *ngFor="let resource of resources2[0].resources">
                <a
                  class="resource-container"
                  [href]="(
                           resource.resource_type === 'pdf' ||
                           resource.resource_type === 'xlsx' ||
                           resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                  [target]="'_blank'"
                  style="text-decoration: none; color: black;"
                >
                  <img
                    alt="file type"
                    [src]="resource.resource_type === 'pdf' ? 'assets/resources-colored-icon.png' : 'assets/links-logo.png'"
                  />
                  <p>{{resource.name}}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-8 mt-0 mt-4 mt-md-0">
        <jz-common-post-list [listOfPosts2]="listOfPosts2" [resources2]="resources2" [totalPages]="totalPages" [page]="page"></jz-common-post-list>
      </div>

    </div>
  </div>
</div>


