import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'jz-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss']
})
export class CategoryCardComponent implements OnInit {
  @Input() indexPosition: number;
  @Input() cat: string;
  @Input() description: string;
  @Input() margin: string;
  @Input() backGroundColor: string;
  @Input() icon: string;
  @Input() catID: number;
  @Output() showCatResources = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }

  buttonClicked(categoriesID: number) {
    this.showCatResources.emit(categoriesID);
  }

}
