<section id="right-content">

  <div class="container" #hrContainer>
    <!-- Spinner start -->
    <ngx-spinner
      bdColor="rgba(51,51,51,0.3)"
      size="large"
      color="white"
      type="ball-climbing-dot"
      [fullScreen]="true"
    ></ngx-spinner>
    <!-- Spinner end -->

    <div class="container" *ngIf="dataLoaded">
      <div class="row">

        <!--left side-->
        <div class="col-sm-12 col-md-6">
          <div class="d-flex flex-column h-100">
            <!--latest updates-->
            <div class="d-flex justify-content-center flex-fill mb-4">
              <jz-hr-latest-updates class="w-100" [posts2List]="posts2List"></jz-hr-latest-updates>
            </div>
            <!--meet your hr team-->
            <div class="d-flex justify-content-center flex-fill mb-4">
              <jz-hr-team class="w-100" [staff]="staffNew"></jz-hr-team>
            </div>
            <!--hr tracker and the hub cards-->
            <div class="d-flex flex-row">
              <div class="container p-0">
                <div class="row">
                  <div class="col-sm-12 col-lg-6 mb-md-4 mb-lg-0 mb-xl-0">
                    <jz-hr-tracker class="w-100"></jz-hr-tracker>
                  </div>
                  <div class="col-sm-12 col-lg-6 mt-sm-4 mt-md-0">
                    <jz-learning-hub class="w-100"></jz-learning-hub>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <!--right side-->
        <div class="col-sm-12 col-md-6 mt-sm-4 mt-md-0 ">
          <div style="background-color: #FFFFFF; height: 100%; border-radius: 20px; padding: 10px">
            <div class="box-for-accordion" style="position: relative; height: 840px; padding: 10px">
              <div style="overflow-y: auto; overflow-x: hidden; height: 100%">
                <mat-accordion id="{{'hr-acc-' + category.id}}" class="jResources-custom-mat-accordion" *ngFor="let category of hrCategories; index as i">
                <!--[expanded]="i === 0"-->
                  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{category.cat}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let resource of category.resources">
                      <a class="resource-container" [href]="(
                      resource.resource_type === 'pdf' ||
                      resource.resource_type === 'xlsx' ||
                      resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                         [target]="'_blank'"
                      >
                        <img style="width: 10%" alt="file type" [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'"/>
                        <p>{{resource.name}}</p>
                      </a>
                    </div>

                    <!-- region second accordion for subcategories-->
                    <div style="margin-top: 20px">
                      <mat-accordion *ngFor="let subCat of category.sub_cat; index as i">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{subCat.cat}}
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div *ngFor="let resource of subCat.resources">
                            <a class="resource-container" [href]="(
                            resource.resource_type === 'pdf' ||
                            resource.resource_type === 'xlsx' ||
                            resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                               [target]="'_blank'"
                            >
                              <img style="width: 10%" alt="file type" [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'"/>
                              <p>{{resource.name}}</p>
                            </a>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                    <!--region end second accordion ends-->

                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</section>


<!--
<div class="col-sm-12 col-md-6 col-lg-7 col-xl-8">
  <div class="d-flex flex-column h-100">
    <div class="d-flex flex-row">
      <div class="container p-0">
        <div class="row">
          <div class="col-xl-7 mb-md-4 mb-xl-0">
            <jz-hr-latest-updates [posts2List]="posts2List"></jz-hr-latest-updates>
          </div>
          <div class="col-xl-5">
            <jz-hr-team [staff]="staff"></jz-hr-team>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row flex-fill mt-4">
      <div class="container p-0">
        <div class="row h-100">
          <div class="col-md-12 col-lg-6 col-xl-5 mb-md-4 mb-lg-0">
            <jz-benefits [benefitsArray]="benefitsArray"></jz-benefits>
          </div>
          <div class="col-md-12 col-lg-6 col-xl-7 d-flex flex-column">
            <jz-talent-acquisition class="mb-4 mt-sm-4 mt-md-0"
                                   [talentAcquisitionArray]="talentAcquisitionArray"></jz-talent-acquisition>
            <jz-performance-management class="h-100"
                                       [performanceArray]="performanceArray"></jz-performance-management>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-sm-12 col-md-6 col-lg-5 col-xl-4 mt-sm-4 mt-md-0 ">
  <div class="d-flex flex-column h-100">
    <div class="d-flex justify-content-center flex-fill mb-4">
      <jz-hr-tracker class="w-100"></jz-hr-tracker>
    </div>

    <div class="d-flex justify-content-center flex-fill mb-4">
      <jz-learning-hub class="w-100"></jz-learning-hub>
    </div>

    <div class="d-flex justify-content-center flex-fill mb-4">
      <jz-payroll class="w-100" [payrollArray]="payrollArray"></jz-payroll>
    </div>

    <div class="d-flex justify-content-center flex-fill">
      <jz-leave-of-absences class="w-100" [policiesAndForms]="policiesAndForms"></jz-leave-of-absences>
    </div>
  </div>
</div>
-->
