import { Component, OnInit } from '@angular/core';
import {SocialMediaAccountService} from '../core/social-media-account.service';

@Component({
  selector: 'jz-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

  accounts = [];
  errorMessage = '';

  constructor(
    private socialMediaAccounts: SocialMediaAccountService
  ) { }

  ngOnInit(): void {
    this.socialMediaAccounts.getSocialMediaAccounts().subscribe({
      next: response => {
        this.accounts = response;
        this.accounts.sort((a, b) => a.sort_order - b.sort_order);
        console.log('the accounts', this.accounts);
      },
      error: err => this.errorMessage = err
    });
  }

}
