<div id="right-content">
  <div class="container" style="background: #FFFFFF; border-radius: 20px; position:relative;">
    <img src="assets/jpark/jpark_logo.png" id="jpark-logo" alt="jpark logo">
    <div class="row">
      <div class="col-3">
      </div>
      <div class="col-9" id="jpark-header-text">
        <p>Park with ease using the Jpark App! This app allows you to easily notify your coworkers and let them know you’ve parked behind them!</p>
      </div>
    </div>
    <div class="row" style="margin-top: 50px">
      <div class="col-md-12 col-lg-9 mb-3">
        <input id="search-bar" class="mb-2 sb" type="text" placeholder="Search by sticker number / Car" name="decalNumber" #box (keyup)="onKey(box.value)">
        <a href="assets/home-cards/parking-map.pdf" TARGET="_blank"><button id="parking-map"><img src="assets/jpark/map-icon.png" width="15" alt="map icon"> Parking Map</button></a>
        <div class="row mt-4">
          <div class="col-2 text-center" style="color: #727272">#</div>
          <div class="col-2 col-sm-4" style="color: #727272"><span class="d-none d-sm-block" style="color: #727272">Employee</span> Name</div>
          <div class="col-sm-4 col-3" style="color: #727272;">Car <span class="d-none d-sm-block" style="color: #727272">Make / Color</span></div>
          <div class="col-2"></div>
          <div id="sep-line" class="mt-2"></div>
        </div>
        <div class="row" *ngFor="let car of carsCopy">
          <div class="col-2 bg-figure text-center">{{car.decal}}</div>
          <div class="col-2 col-sm-4 name-car-style">{{car.owner.first_name}} <span class="d-none d-sm-block">{{car.owner.last_name}}</span></div>
          <div class="col-3 name-car-style">{{car.make}} <span class="d-none d-sm-block">/ {{car.color}}</span></div>
          <div class="col-2 d-flex align-items-center"><a href="mailto:{{car.owner.email}}"><button class="modify-btn"><span class="d-none d-sm-block" style="padding-right: 5px">Notify </span> <img src="assets/jpark/mail-icon.png" width="18" alt="mail icon"></button></a></div>
        </div>
      </div>
      <div class="col-md-12 col-lg-3 mb-2">
        <div id="mobile-app">
          <img class="img-fluid" src="assets/jpark/new-mobile-app.png" alt="mobile logo">
          <p>Take the hassle away from parking at work. Use this app to easily communicate with your coworkers and let them know where you parked.<p>
          <p>For iPhone users talk to the Development Team.</p>
          <div class="row">
            <div class="col-6 text-right">
              <img src="assets/jpark/google-play.png" class="img-fluid center-block" alt="google play logo">
            </div>
            <div class="col-6">
              <img src="assets/jpark/app-store.png" class="img-fluid center-block" alt="app store logo">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
