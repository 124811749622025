import {Component, Input, OnInit} from '@angular/core';
import {PostsService} from '../core/posts.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CategoriesService} from '../core/categories.service';
import {PostCategory} from '../core/models/postCategory';
import {Post} from '../core/models/post';

@Component({
  selector: 'jz-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent implements OnInit {

  @Input() posts: Post[];
  title = 'is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make atype specimen book. It has survived not only five';

  constructor(
    private categoriesService: CategoriesService,
    private postsService: PostsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.categoriesService.categories[params.category]) {

        const categories = this.categoriesService.categories;

        this.postsService.getPosts(categories[params.category]).subscribe((posts: Post[]) => {
          this.posts = posts;
        });
      } else {
        this.categoriesService.getCategories().subscribe((categories: PostCategory[]) => {
          this.categoriesService.setCategories(categories);
          const theCategories = this.categoriesService.categories;

          this.postsService.getPosts(theCategories[params.category]).subscribe((posts: Post[]) => {
            this.posts = posts;
            // console.log(this.posts);
            // console.log(theCategories);
          });
        });
      }
    });
  }
}
