<div *ngIf="employeeData && !toggle" class="card-style-elem d-flex flex-column justify-content-center align-items-center"
     [ngStyle]="{'opacity' : !activeState ? '1' : activeState && numberId === employeeData.number ? '1' : '0.5',
                 'pointer-events' : !activeState ? '' : activeState && numberId === employeeData.number ? '' : 'none'}">

  <img class="info-icon-img-style" src="assets/new-org-chart/info-icon.png" alt="info icon"
       (click)="openRightSideDialog(employeeData.number)">

  <img [src]="employeeData.profile_picture_url ? employeeData.profile_picture_url : 'assets/org-chart/pic-placeholder.png'"
       class="profile-img-style" alt="profile pic"
       (click)="openRightSideDialog(employeeData.number)">

  <div class="d-flex flex-column align-items-center" style="gap: 1px">
    <p class="full-name-text">{{employeeData.name}}</p>
    <span class="position-text">{{employeeData.job_title}}</span>
    <span class="position-text">{{employeeData.department}}</span>
  </div>

  <div *ngIf="employeeData.manages.length > 0 && !search" #newItems class="children-counter text-center"
       (click)="addNewData(employeeData.manages); sendNewActiveDataToParent(employeeData.number)">
    {{employeeData.manages.length ? employeeData.manages.length : 0}}
    <img src="assets/new-org-chart/Vector-3.svg" [ngStyle]="{'transform': vectorToggle && id != '100007' ?
      'rotate(180deg)' : !vectorToggle && id === '100007' ? 'rotate(180deg)' : vectorToggle && id === '100007' ?
      'rotate(180deg)' : ''}" alt="vector logo">
  </div>
</div>

<!--to show when click on Collapse button-->
<div *ngIf="employeeData && toggle" class="card-style-elem-toggled d-flex flex-column justify-content-center align-items-center">
  <img [src]="employeeData.profile_picture_url ? employeeData.profile_picture_url :
  'assets/org-chart/pic-placeholder.png'" class="profile-img-style-collapsed" alt="">
</div>

