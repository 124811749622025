<main class="right-content">
  <div class="main-div-cont-styles d-flex flex-column flex-md-row justify-content-start">

    <!--first section: my profile/about me-->
    <section class="first-section d-flex flex-row flex-md-column justify-content-start">
      <div [ngClass]="myProfileIsActive ? 'profile-menu-pill' : 'deactivate'" class="d-flex flex-row justify-content-center
      align-items-center" (click)="onClickMyProfile(); handleClickMyProfile()">My Profile</div>
      <div [ngClass]="aboutMeIsActive ? 'profile-menu-pill' : 'deactivate'" class="d-flex flex-row justify-content-center
      align-items-center" (click)="onClickAboutMe(); handleClickAboutMe()">About Me</div>
    </section>

    <!--divider-->
    <div *ngIf="innerWidth > 768" class="line-div"></div>

    <!--second section: my profile-->
    <section *ngIf="userData && myProfileClicked" class="second-section d-flex flex-column justify-content-start">
      <p *ngIf="innerWidth > 768" class="my-profile">My Profile</p>

      <div class="my-profile-img-cont d-flex flex-row justify-content-start align-items-center">
        <img class="my-profile-img" [src]="userData[0].profile_picture_url ? userData[0].profile_picture_url : 'assets/org-chart/pic-placeholder.png'" alt="profile pic">
        <div class="d-flex flex-column">
          <p class="my-profile-name-text">{{userData[0].name}}</p>
          <span class="my-profile-name-text-gray-muted">{{userData[0].job_title}}</span>
        </div>
      </div>


      <div class="d-flex flex-column" style="gap: 16px">
        <div class="gray-block d-flex flex-column justify-content-start align-items-start">
          <p class="gray-block-text">Location</p>
          <span class="gray-block-text-muted">{{userData[0].location}}</span>
        </div>
        <div class="gray-block d-flex flex-column">
          <p class="gray-block-text">Department</p>
          <span class="gray-block-text-muted">{{userData[0].department}}</span>
        </div>
        <div class="gray-block d-flex flex-column">
          <p class="gray-block-text">Division</p>
          <span class="gray-block-text-muted">{{userData[0].division}}</span>
        </div>
        <div class="gray-block d-flex flex-column">
          <p class="gray-block-text">Email</p>
          <span class="gray-block-text-muted">{{userData[0].email}}</span>
        </div>
      </div>
    </section>

    <!--third section: about me-->
    <section *ngIf="userData && aboutMeClicked" class="third-section d-flex flex-column justify-content-start">

      <div class="scrollable-div d-flex flex-column justify-content-start">
        <p *ngIf="innerWidth > 768" class="my-profile">About Me</p>

        <div *ngFor="let question of questions, let i = index" class="d-flex flex-column">
          <!--tagline-->
          <span *ngIf="question.tagline" class="question-tagline-cont d-flex flex-row justify-content-center align-items-center">{{question.tagline}}</span>
          <!--question-->
          <p class="question-content">{{question.content}}</p>
          <!--response-->
          <jz-questions [componentPK]="question.id" [email]="user.email"></jz-questions>

        </div>
      </div>

    </section>

  </div>
</main>
