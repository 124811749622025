<div id="right-content">
  <div class="container">

<!--    <jz-header-->
<!--      [title]="'All About Kids'"-->
<!--      [color]="'#fd1598'"-->
<!--      [icon]="'https://jcosmos.s3.amazonaws.com/media/icons/kids-2.png'"-->
<!--    ></jz-header>-->

    <!-- Spinner start -->
    <ngx-spinner
      bdColor="rgba(51,51,51,0.3)"
      size="medium"
      color="white"
      type="ball-scale-multiple"
      [fullScreen]="true"
    ></ngx-spinner>
    <!-- Spinner end -->

    <!--  posts  -->
    <jz-common-post-list [posts]="posts"></jz-common-post-list>

<!--    <div class="row">-->
<!--      <div-->
<!--        class="col-md-4 global-center-card-mobile"-->
<!--        style="cursor:pointer; margin-bottom: 25px;"-->
<!--        *ngFor="let post of posts"-->
<!--        [routerLink]="['/posts', post.category, post.pk]"-->
<!--      >-->
<!--        <mat-card style="height: 380px; width: 330px; border-radius: 19px; background-color: #f4f4f4">-->
<!--          <img style="height: 55%; object-fit: cover;" mat-card-image [src]="post.card_image">-->
<!--          <mat-card-content style="overflow-y: hidden;">-->
<!--            <h4 style="color: #3551da; overflow: hidden; text-overflow: ellipsis;">{{post.headline}}</h4>-->
<!--            <div>-->
<!--              <p>{{post.short_description}}</p>-->
<!--            </div>-->
<!--          </mat-card-content>-->
<!--        </mat-card>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
