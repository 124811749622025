import { animation, style, animate, trigger, transition, useAnimation } from '@angular/animations';

export const triggerPreviewAnimation = trigger('preview', [
    transition(':enter', [
      style({opacity: 0}),
      animate('200ms', style({opacity: 1}))
    ]),
    transition(':leave', [
      animate('200ms', style({opacity: 0}))
    ])
  ]);


export const triggerThePreviewAnimation =  trigger('thePreview', [
  transition(':enter', [
    style({opacity: 0}),
    animate('200ms 250ms', style({opacity: 1}))
  ]),
  transition(':leave', [
    animate('200ms', style({opacity: 0}))
  ])
]);
