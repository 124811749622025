<div class="org-chart-entity-connector org-chart-entity-border" *ngIf="hasParent"></div>
<div class="org-chart-entity-box" [style.opacity]="isVisible ? '1' : '0.25' " (click)="toggleShowChild($event)">
  <div *ngIf="data?.id == user" #Target></div>
  <div class="d-flex flex-row justify-content-start">
    <img class="md-avatar org-prof-pic" [src]="data?.image ? data.image : 'assets/org-chart/pic-placeholder.png'" alt="profile-pic" />
    <div class="info-block">
      <div #Person class="org-chart-entity-name" attr.data-email="{{data?.email}}">{{data?.person}}</div>
      <div class="org-chart-entity-type">{{data?.position}}</div>
      <div class="org-chart-entity-type">{{data?.email}}</div>
    </div>
  </div>
  <!--span element for show how many children the node has-->
  <span [style.display]="data?.total > 0 ? 'block' : 'none' " class="text-center count-label">{{data?.total}}</span>
  <!--span element for show how a detailed view-->
  <span class="text-center detailed-modal" (click)="open(data?.email,$event)">+</span>
</div>
<div class="org-chart-entity-connector org-chart-entity-border" *ngIf="data?.children.length && !hideChild"></div>

<!--employee detail card-->
<!-- <div class="sticky d-flex flex-column detailed-user-modal" *ngIf="data?.email === userEmail && openUserDetailedView">
  <div class="d-flex flex-row justify-content-start align-items-center blue-back">
    <img class="md-avatar org-prof-pic" [src]="data?.image ? data.image : 'assets/org-chart/pic-placeholder.png'" alt="profile-pic" />
    <div class="info-block">
      <div class="org-chart-entity-name" style="color: #f4f4f4; font-size: 12px">{{data?.person}}</div>
      <div class="org-chart-entity-type" style="color: #f4f4f4; font-size: 10px">{{data?.position}}</div>
    </div>
    <span class="text-center close-div-btn" (click)="closeUserDetailView()">X</span>
  </div>
  <div class="d-flex flex-column extra-info-block">
    <h5>Details</h5>
    <p>Company: {{data?.company | uppercase}}</p>
    <p>Department: {{data?.department | uppercase}}</p>
    <p *ngIf="data?.division">Division: {{data?.division | uppercase}}</p>
    <p>Email: {{data?.email}}</p>
    <p>{{data?.office | uppercase}}</p>
  </div>
</div> -->
