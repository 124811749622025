import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import {IdeaDetailComponent} from './idea-detail/idea-detail.component';
import {JTankRulesComponent} from './j-tank-rules/j-tank-rules.component';
import {SubmitIdeaComponent} from './submit-idea/submit-idea.component';
import {VoteComponent} from './vote/vote.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {AppRoutingModule} from '../app-routing.module';
import {TruncatePipe} from '../truncate.pipe';
import {RouterModule} from '@angular/router';
import { SizeDialogComponent } from './submit-idea/size-dialog/size-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    IdeaDetailComponent,
    JTankRulesComponent,
    SubmitIdeaComponent,
    VoteComponent,
    TruncatePipe,
    SizeDialogComponent
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    NgxDropzoneModule,
    AppRoutingModule,
    RouterModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    IdeaDetailComponent,
    JTankRulesComponent,
    SubmitIdeaComponent,
    VoteComponent
  ],
  entryComponents: [
    IdeaDetailComponent,
    JTankRulesComponent,
    SubmitIdeaComponent,
    VoteComponent
  ]
})
export class JtankModule { }
