import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService, SocialUser} from 'angularx-social-login';
import {JeducationService} from '../core/jeducation.service';
import {AuthorizationService} from '../core/authorization.service';
import {JeducationMessage} from '../core/models/jeducationMessage';
import {Resources2Service} from '../core/resources2.service';
import {IResource2} from '../core/models/resource2';

@Component({
  selector: 'jz-jeducation',
  templateUrl: './jeducation.component.html',
  styleUrls: ['./jeducation.component.scss']
})
export class JeducationComponent implements OnInit {

  educationForm = new FormGroup({
    email: new FormControl({value: '', disabled: true}),
    name: new FormControl({value: '', disabled: true}),
    department: new FormControl({value: '', disabled: true}),
    additional_information: new FormControl(''),
    type_of_training: new FormControl('', Validators.required)
  });
  isSubmitted = false;

  video = true;
  sop = true;
  forms = true;
  toolGuide = true;
  googleTraining = true;
  smartSheets = true;
  wrike = true;
  resource = true;

  isOpen = true;

  // adding static data for the training plataforms
  plataforms = [
    {name: 'Know b 4', resource_type: 'external link', external_link: ''},
    {name: 'Jazwiki', resource_type: 'external link', external_link: `"https://jazwares.bizlibrary.com"`}
  ];

  resources2Training: IResource2[];
  errorMessage = '';
  categoryName: string;
  resourcesArray: IResource2[];
  placeholder = '+';
  hover: boolean;
  keepItOpened = true;
  panelOpenState = false;

  constructor(
    private jeducationService: JeducationService,
    private authorizationService: AuthorizationService,
    private authService: AuthService,
    private resources2Service: Resources2Service
  ) { }

  ngOnInit(): void {
    this.authService.authState.subscribe((user: SocialUser) => {
      this.educationForm.controls.email.setValue(user.email);
      this.educationForm.controls.name.setValue(user.name);
    });
    this.fetchResources2();
  }

  onSubmitForm() {
    this.authService.authState.subscribe((user: SocialUser) => {
      const message: JeducationMessage = {
        email: user.email,
        name: user.name,
        additional_information: this.educationForm.get('additional_information').value,
        type_of_training: this.educationForm.get('type_of_training').value,
      };

      if (this.educationForm.valid) {
        this.isSubmitted = true;
        this.jeducationService.sendTraining(message).subscribe((data) => {
          this.isSubmitted = false;

          if (data.status === 204) {
            this.educationForm.reset();
          }
        });
      }
    });
  }

  onClick(cat: string): void {
    this.categoryName = cat;
    this.isOpen = !this.isOpen;
    this.resourcesArray = this.resources2Training.filter(jTrainingResources => jTrainingResources.cat === cat);
  }

  onAccordionClick() {
    this.isOpen = !this.isOpen;
  }

  onVideoClick(): void {
    this.video = !this.video;
  }
  onSopClick(): void {
    this.sop = !this.sop;
  }
  onFormsClick(): void {
    this.forms = !this.forms;
  }
  onToolGuideClick(): void {
    this.toolGuide = !this.toolGuide;
  }
  onGoogleTrainingClick(): void {
    this.googleTraining = !this.googleTraining;
  }
  onSmartsheetsClick(): void {
    this.smartSheets = !this.smartSheets;
  }
  onWrikeClick(): void {
    this.wrike = !this.wrike;
  }
  onResourceClick(): void {
    this.resource = !this.resource;
  }

  // private functions
  private fetchResources2() {
    this.resources2Service.getAllResources2().subscribe({
      next: response => {
        this.resources2Training = response;
      },
      error: err => this.errorMessage = err
    });
  }
}
