<div class="row header-container">
  <div class="col-12">
    <div
      class="top-intro-bar"
      [ngStyle]="{'background-color': color}"
    >
      <img *ngIf="icon" [src]="icon" width="30" alt="section icon">
      <h4 class="title">{{title}}</h4>
    </div>
  </div>
</div>
