<div id="right-content">
  <div id="blue-j">
    <div class="container">
<!--      <div class="row">-->
<!--        <div class="col-12">-->
<!--          <h4><img src="https://jcosmos.s3.amazonaws.com/media/icons/covid-icon.png" width="30"> See Latest Covid-19 Related News  </h4>-->
<!--        </div>-->
<!--      </div>-->
      <jz-header
        [title]="'See Latest Covid-19 Related News'"
        [color]="'#f4112a'"
        [icon]="'https://jcosmos.s3.amazonaws.com/media/icons/covid-icon.png'"
      ></jz-header>
      <!-- Spinner start -->
      <ngx-spinner
        bdColor="rgba(51,51,51,0.3)"
        size="medium"
        color="white"
        type="ball-scale-multiple"
        [fullScreen]="true"
      ></ngx-spinner>
      <!-- Spinner end -->

    </div>
  </div>
  <div class="container">
    <div class="row">
      <div
        class="col-lg-4 col-md-6 global-center-card-mobile"
        style="cursor:pointer; margin-bottom: 25px;"
        *ngFor="let post of posts"
        [routerLink]="['/posts', post.category, post.pk]"
      >
        <mat-card style="height: 380px; width: 330px; border-radius: 19px; background-color: #f4f4f4">
          <img style="height: 55%; object-fit: cover;" mat-card-image [src]="post.card_image">
          <mat-card-content style="overflow-y: hidden;">
            <h4 style="color: #3551da; overflow: hidden; text-overflow: ellipsis;">{{post.headline}}</h4>
            <div>
              <p>{{post.short_description}}</p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
