import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IPartner} from '../models/partner';
import {PartnerService} from '../partner.service';

@Injectable()
export class PartnerResolver implements Resolve<IPartner[]> {

  constructor(private partnerService: PartnerService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IPartner[]> | Promise<IPartner[]> | IPartner[] {
     return this.partnerService.getAllPartners();
  }
}
