import {Directive, ElementRef, Renderer2, OnInit, HostListener} from '@angular/core';

@Directive({
  selector: '[jzPartnersHover]'
})
export class PartnersHoverDirective implements OnInit{

  textCardElem: ElementRef;
  linkButtonElem: ElementRef;

  constructor(
    private elm: ElementRef,
    private renderer2: Renderer2
  ) { }

  ngOnInit(): void {
    this.textCardElem = this.elm.nativeElement.querySelector('.text-descrip');
    this.linkButtonElem = this.elm.nativeElement.querySelector('jdiscounts-link-button');
  }

}
