<section class="site-container">

  <mat-toolbar color="primary" style="padding: 40px 20px;" class="justify-content-between justify-content-sm-start">

    <button id="nav-menu-icon" mat-icon-button (click)="sidenav.toggle()"
            style="width: 50px;height: 50px">
      <mat-icon style="font-size: 40px;width: 40px">menu</mat-icon>
    </button>

    <!--Company Logo-->
    <div id="logo-nav" class="mr-sm-auto">
      <a [routerLink]="'/'">
<!--        <img src="assets/nav/Pride-Month-Logo-Skins.png" alt="logo" style="width: 176px"/>-->
        <img  [src]="logos[0].img" alt="logo" style="width: 176px"/>
      </a>
      <p class="confidential">Company Intranet Strictly Confidential</p>
    </div>

    <!-- Announcement bar here -->
    <div class="container-fluid annauncm-sec" >
      <div class="d-flex flex-row green-bar">
        <div class="px-2">
          <img src="assets/nav/new_announcement_icon.png" alt="Icon" style="width: 25px;"/>
          <span class="ann-txt" style="font-weight: bold; padding-left: 10px;font-size: 16px;">Announcement:</span>
        </div>
        <div class="px-2">
          <carousel>
            <slide *ngFor="let message of homeMessages" style="font-size: 16px;">{{message.message}}</slide>
          </carousel>
        </div>
      </div>
    </div>

    <!-- global search button  -->
    <div id="top-bar-search-btn" [routerLink]="'/global-search'" class="global-search-button">
      <button mat-stroked-button >
        <mat-icon>search</mat-icon>
        Search
      </button>
    </div>

    <!-- log out   -->
<!--    <div *ngIf="loggedUser" id="log-out" (click)="openLoggedUserDialog(loggedUser)" class="float-sm-right">-->
<!--&lt;!&ndash;      <img alt="log out button" src="assets/nav/logout-icon.png">&ndash;&gt;-->
<!--      <img alt="log out button" [src]="loggedUser[0].profile_picture_url">-->
<!--    </div>-->

    <div id="log-out" class="float-sm-right" (click)="logOut()">
      <img src="assets/nav/logout-icon.png" alt="log out button">
    </div>

  </mat-toolbar>

  <mat-drawer-container style="background-color: transparent">
    <mat-drawer
      [ngStyle]="{'width': '280px'}"
      #sidenav
      [mode]="innerWidth <= 1024 ? 'over' : 'side'"
      [opened]="!onMobile"
      (openedChange)="openChange($event)"
    >
      <jz-nav>
<!--        [categories]="categories"-->
        [onMobile]="innerWidth <= 576"
        (onTouch)="hideSideNav($event)"
      ></jz-nav>
    </mat-drawer>
    <router-outlet></router-outlet>
  </mat-drawer-container>

</section>


