<section class="ml-md-4" style="height: 100%">
  <div class="d-flex flex-column justify-content-between" id="bday-section">

    <span style="background: #AD21E2;
               border-radius: 20px;
               color: #AD21E2;
               position: absolute;
               left: -9px;
               width: 26px;
               height: 14px;
               top: 28px">-</span>
    <img class="confeti-img" src="assets/confeti.png" alt="confeti-img">
    <p class="label-title">Happy Birthday!</p>

    <div>
      <!-- avatar placeholder -->
      <div class="col-sm-12 col-md-12 col-lg-12" id="placeholder-img" [routerLink]="['/', 'birthday']" *ngIf="users">
        <img class="img-fluid" style="border-radius: 50%" [src]="users[indexPosition].profile_picture_url ? users[indexPosition].profile_picture_url : 'assets/jazwares-placeholder-bday.jpg'" alt="user-logo">
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12" id="name-bday-section">
        <p class="text-center">{{users ? users[indexPosition].name : ''}}</p>
        <p class="text-center" id="users-month-date">
          {{users ? stringToSplit(users[indexPosition].birthdate, separator) : ''}},
          {{users ? stringToSplitToDay(users[indexPosition].birthdate, separator) : ''}}</p>
      </div>
    </div>

    <div>
      <!-- bday user's slide -->
      <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="upcomingBDays">
          <mat-divider></mat-divider>
          <div class="d-flex flex-row" style="justify-content: space-around">
            <p class="text-center" id="link-text"
            >
              Upcoming<br>Birthdays
            </p>
            <mat-icon class="custom-mat-icon" (click)="previousUser()">arrow_left</mat-icon>
            <div class="d-flex flex-column text-center">
              <p class="users-name">
                {{users ? users[indexPosition + 1].name : 'oops'}}
              </p>
              <p class="users-date">
                {{users ? stringToSplit(users[indexPosition + 1].birthdate, separator) : 'oops'}},
                {{users ? stringToSplitToDay(users[indexPosition + 1].birthdate, separator) : 'oops'}}
              </p>
            </div>
            <mat-icon class="custom-mat-icon" (click)="nextUser()">arrow_right</mat-icon>
          </div>
        </div>
    </div>

  </div>
</section>








<!--<div class="container" id="bday-section" [routerLink]="['/', 'birthday']">-->

<!--    <span style="background: #AD21E2;-->
<!--               border-radius: 20px;-->
<!--               color: #AD21E2;-->
<!--               position: absolute;-->
<!--               left: -9px;-->
<!--               width: 26px;-->
<!--               height: 14px;-->
<!--               top: 28px">-</span>-->
<!--  <img class="confeti-img" src="assets/confeti.png" alt="confeti-img">-->
<!--  <p class="label-title">Happy Birthday!</p>-->

<!--  <div class="row">-->

<!--    <div class="col-sm-12 col-md-12 col-lg-12" id="placeholder-img">-->
<!--      <img class="img-fluid" src="assets/new_placeholder.png" alt="user-logo">-->
<!--    </div>-->
<!--    <div class="col-sm-12 col-md-12 col-lg-12" id="name-bday-section">-->
<!--      <p class="text-center">{{users ? users[indexPosition].first_name : 'oops'}} {{users ? users[indexPosition].last_name : 'oops'}}</p>-->
<!--      <p class="text-center" id="users-month-date">-->
<!--        {{users ? convertDateFromIntToString(users[indexPosition].birthdate_month) : 'oops'}}, {{users ? users[indexPosition].birthdate_day : 'oops'}}</p>-->
<!--    </div>-->
<!--    <mat-divider></mat-divider>-->

<!--    <div class="col-sm-12 col-md-12 col-lg-12">-->
<!--      <div class="d-flex flex-row" style="justify-content: space-around">-->
<!--        <p class="text-center" id="link-text"-->
<!--        >-->
<!--          upcoming<br>birthdays-->
<!--        </p>-->
<!--        <mat-icon (click)="previousUser()">arrow_left</mat-icon>-->
<!--        <div class="d-flex flex-column text-center">-->
<!--          <p class="users-name">-->
<!--            {{users ? users[indexPosition + 1].first_name : 'oops'}} {{users ? users[indexPosition + 1].last_name : 'oops'}}-->
<!--          </p>-->
<!--          <p class="users-date">-->
<!--            {{users ? convertDateFromIntToString(users[indexPosition + 1].birthdate_month) : 'oops'}}, {{users ? users[indexPosition + 1].birthdate_day : 'oops'}}-->
<!--          </p>-->
<!--        </div>-->
<!--        <mat-icon (click)="nextUser()">arrow_right</mat-icon>-->
<!--      </div>-->
<!--    </div>-->

<!--  </div>-->

<!--</div>-->

