import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AskService} from '../core/ask.service';
import {Message} from '../core/models/message';
import {AuthorizationService} from '../core/authorization.service';
import {AuthService, SocialUser} from 'angularx-social-login';

@Component({
  selector: 'jz-ask-jcosmos',
  templateUrl: './ask-jcosmos.component.html',
  styleUrls: ['./ask-jcosmos.component.scss']
})
export class AskJcosmosComponent implements OnInit {
  messageForm = new FormGroup({
    subject: new FormControl(''),
    question: new FormControl('', Validators.required)
  });

  isSubmitted = false;

  constructor(
    private askService: AskService,
    private authorizationService: AuthorizationService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
  }

  onSubmitForm() {
   this.authService.authState.subscribe((user: SocialUser) => {
     const message: Message = {
       email: user.email,
       subject: this.messageForm.get('subject').value,
       message: this.messageForm.get('question').value
     };

     if (this.messageForm.valid) {
       this.isSubmitted = true;
       this.askService.sendQuestion(message).subscribe((data) => {
         this.isSubmitted = false;

         if (data.status === 204) {
           this.messageForm.reset();
         }
       });
     }
    });
  }
}
