import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BluejCategoriesService} from '../../core/bluej-categories.service';
import {BluejCategories} from '../../core/models/bluej-categories';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'jz-bluej-refactor',
  templateUrl: './bluej-refactor.component.html',
  styleUrls: ['./bluej-refactor.component.scss']
})
export class BluejRefactorComponent implements OnInit {

  categories: BluejCategories[] = [];
  errorMessage = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bluejCategories: BluejCategoriesService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.fetchBluejCategories();
  }

  // private functions below
  private fetchBluejCategories() {
    this.spinner.show();
    this.bluejCategories.getAllBluejCategories().subscribe({
      next: response => {
        this.categories = response;
        // console.log(this.categories);
        this.spinner.hide();
      },
      error: err => this.errorMessage = err
    });
  }
}
