import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HomeMessages} from '../models/home-messages';
import {CategoriesService} from '../categories.service';


@Injectable()
export class HomemessagesResolver implements Resolve<HomeMessages[]> {
  constructor(
    private categoriesService: CategoriesService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<HomeMessages[]> | Promise<HomeMessages[]> | HomeMessages[] {
    return this.categoriesService.getMessages();
  }
}
