import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jz-under-construction-page',
  templateUrl: './under-construction-page.component.html',
  styleUrls: ['./under-construction-page.component.scss']
})
export class UnderConstructionPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
