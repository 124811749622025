<main id="main-logged-user-profile" class="d-flex flex-column">
  <!--profile pic and position-->
  <section>
    <div class="first-section-logged-user-profile d-flex flex-row justify-content-start align-items-center">
      <img class="logged-user-profile-img"
           [src]="userData[0].profile_picture_url ? userData[0].profile_picture_url : 'assets/org-chart/pic-placeholder.png'"
           alt="profile pic">
      <div class="d-flex flex-column">
        <p class="first-section-logged-user-profile-text">{{userData[0].name}}</p>
        <span class="first-section-logged-user-profile-gray-text">{{userData[0].job_title}}</span>
      </div>
    </div>
  </section>

  <!--profile link and about me link-->
  <section>
    <div class="d-flex flex-column align-items-start">
<!--      <span class="logged-user-profile-gray-text-selector" (click)="goTo()">Profile</span>-->
<!--      <span class="logged-user-profile-gray-text-selector" (click)="goTo()">About Me</span>-->
    </div>
  </section>

  <!--divider-->
  <div class="logged-user-divider"></div>

  <!--sign out-->
  <section>
<!--    <span class="logged-user-profile-gray-text-selector" (click)="logOut()">Sign Out</span>-->
  </section>
</main>
