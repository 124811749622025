<div class="resource-list-container d-flex flex-column justify-content-between h-100">

 
  
  <div class="action-button text-center">
    <a href="https://jazwares.employee.hrsd.ultipro.com/"
       class="btn btn-outline-primary" tabindex="-1" role="button" aria-disabled="true" target="_blank">
      <img class="askHrGraphic" src="assets/human-resources/ask-hr-01.png" alt="user-logo">
      </a>
  </div>
</div>
