import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CategoriesService} from '../categories.service';
import {IPhotoCategory} from '../models/halloween-contest';

@Injectable()
export class SubmissionCategoriesResolver implements Resolve<IPhotoCategory[]> {
  constructor(
    private categoriesService: CategoriesService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<IPhotoCategory[]> | Promise<IPhotoCategory[]> | IPhotoCategory[] {
    return this.categoriesService.getSubmissionCategories();
  }
}
