import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {BluejCategoriesService} from '../bluej-categories.service';
import {BluejCategory} from '../models/bluej-category';


@Injectable({
  providedIn: 'root'
})
export class BluejCategoryResolver implements Resolve<BluejCategory[]> {
  constructor(
    private bluejCategory: BluejCategoriesService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<BluejCategory[]> | Promise<BluejCategory[]> | BluejCategory[] {
    return this.bluejCategory.getBluejCategoryPosts(route.paramMap.get('slug'));
  }
}
