import { Component, OnInit } from '@angular/core';
import {Employee} from '../new-org-chart/models/employee';
import {AuthService, SocialUser} from 'angularx-social-login';
import {FetchingDataService} from '../new-org-chart/services/fetching-data.service';
import {ProfileQuestionsServiceService} from './services/profile-questions-service.service';
import {IQuestions} from './models/questions';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ModalAlertComponent} from './components/modal-alert/modal-alert.component';

@Component({
  selector: 'jz-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  user: SocialUser;
  userData: Employee[];
  myProfileClicked = true;
  aboutMeClicked = false;
  questions: IQuestions[];
  postId;
  innerWidth = window.innerWidth;
  myProfileIsActive = true;
  aboutMeIsActive = false;

  constructor(private dialog: MatDialog, private authService: AuthService, private fetchUserData: FetchingDataService,
              private fetchQuestionsData: ProfileQuestionsServiceService, private http: HttpClient ) { }

  ngOnInit(): void {
    this.fetchUser();
    this.fetchQuestions();
  }

  openModalAlert() {
    const dialogRef: MatDialogRef<ModalAlertComponent> = this.dialog.open(ModalAlertComponent, {
      panelClass: 'custom-modalAlert',
    });
  }

  handleClickAboutMe() {
    this.aboutMeIsActive = true;
    this.myProfileIsActive = false;
    this.openModalAlert();
  }

  handleClickMyProfile() {
    this.myProfileIsActive = true;
    this.aboutMeIsActive = false;
  }

  fetchQuestions() {
    this.fetchQuestionsData.fetchQuestionsData().subscribe({
      next: response => {
        this.questions = response;
        // console.log(this.questions);
      }
    });
  }

  onClickAboutMe() {
    if (this.aboutMeClicked) {
      this.aboutMeClicked = true;
    } else {
      this.aboutMeClicked = true;
      if (this.myProfileClicked) {
        this.myProfileClicked = false;
      }
    }
  }

  onClickMyProfile() {
    if (this.myProfileClicked) {
      this.myProfileClicked = true;
    } else {
      this.myProfileClicked = true;
      if (this.aboutMeClicked) {
        this.aboutMeClicked = false;
      }
    }
  }

  fetchUser() {
    this.authService.authState.subscribe((user: SocialUser) => {
      this.user = user;
      this.fetchUserDataFromUKG(this.user.email);
    });
  }

  fetchUserDataFromUKG(userEmail: string) {
    this.fetchUserData.fetchSearchByEmail(userEmail).subscribe({
      next: response => {
        this.userData = response;
      }
    });
  }

}
