import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LearningHub} from './models/learning-hub';

@Injectable({
  providedIn: 'root'
})
export class LearningHubService {

  private url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  // method to get all resources from Learning Hub different cat
  getLearningHubResources(): Observable<LearningHub[]> {
    return this.http.get<LearningHub[]>(
      this.url + 'resources2/get?app=learning_hub&model=category'
    );
  }
}
