<div>
  <div class="d-flex flex-row justify-content-start">

    <!--left side form-->
    <div id="filters-options" *ngIf="leftSideMenuVisibleForMobile">
      <p class="left-card-title">Search</p>
      <img class="clear-button" src="assets/new-org-chart/Clear.png" (click)="closeDialog()" alt="clear icon">

      <!--form section-->
      <section class="options-container d-flex flex-column">
        <form [formGroup]="newFilterGroup" (ngSubmit)="saveSearchParameters(newFilterGroup.value)" autocomplete="off">

          <!--input name-->
          <div class="form-group generic-option-div d-flex flex-row" [ngClass]="{ error: name.invalid && name.dirty }">
<!--            <em *ngIf="name.invalid && name.dirty">Required</em>-->
            <img class="form-icon-jpg" src="assets/new-org-chart/contact-name-icon.png" alt="contact name icon">
            <input
              id="contactName"
              type="text"
              class="form-control"
              placeholder="Contact Name"
              formControlName="name"
            />
          </div>

          <!--select department-->
          <div class="form-group generic-option-div d-flex flex-column">
            <div class="d-flex flex-row">
<!--              <em *ngIf="department.touched && department.invalid">Required</em>-->
              <img class="form-icon-jpg" src="assets/new-org-chart/department-icon.png" alt="department icon">
              <select formControlName="department" id="select-dropdown-department" class="form-control">
                <option  value="" selected>Department</option>
                <option *ngFor="let option of departmentArray, let i = index" value="{{option.department}}"
                        (click)="arh()">{{option.department}}</option>
              </select>
            </div>
            <div *ngIf="department.valid && !clearOptionVar" class="filter-choice-selected-style">
              <span>{{department.value}}</span>
              <span (click)="clearOption()" style="cursor: pointer">x</span>
            </div>
          </div>

          <!--select divisions-->
          <div class="form-group generic-option-div d-flex flex-column">
            <div class="d-flex flex-row">
<!--              <em *ngIf="department.touched && department.invalid">Required</em>-->
              <img class="form-icon-jpg" src="assets/new-org-chart/division-icon.png" alt="division icon">
              <select formControlName="division" id="select-dropdown-division" class="form-control">
                <option  value="" selected>Divisions</option>
                <option *ngFor="let division of divisionArray" value="{{division.division}}">{{division.division}}</option>
              </select>
            </div>
            <div *ngIf="division.valid" class="filter-choice-selected-style">{{division.value}}</div>
          </div>

          <!--select location-->
          <div class="form-group generic-option-div d-flex flex-column">
            <div class="d-flex flex-row">
              <!--              <em *ngIf="department.touched && department.invalid">Required</em>-->
              <img class="form-icon-jpg" src="assets/new-org-chart/location-icon.png" alt="location icon">
              <select formControlName="location" id="select-dropdown-location" class="form-control">
                <option  value="" selected>Locations</option>
                <option *ngFor="let location of locationArray" value="{{location.location}}">{{location.location}}</option>
              </select>
            </div>
            <div *ngIf="location.valid" class="filter-choice-selected-style">{{location.value}}</div>
          </div>

          <!--submit button-->
          <button type="submit" [disabled]="name.invalid" class="apply-btn-cont d-flex flex-row
                  justify-content-center align-items-center" [ngStyle]="{'background-color': name.invalid ? '#d7d7d7' :
          '#1d52e1'}">
            Apply
          </button>
        </form>
      </section>

    </div>

    <!--right side result cards-->
    <div id="list-results" class="d-flex flex-column justify-content-start align-items-start" style="position: relative">
      <span *ngIf="searchWasDid && searchResultsArray.length > 0" class="showing-results-text">
        Showing {{searchResultsArray.length}} results
      </span>
      <img *ngIf="searchWasDid && searchResultsArray.length > 0" src="assets/new-org-chart/search-icon.png"
           class="search-icon-cont-mobile" alt="search icon" (click)="backToFilters()">

      <div *ngIf="searchResultsArray" class="scrollable-cont-y">

        <!--search results card-->
        <div *ngFor="let foundEmployee of searchResultsArray" class="employee-card-search-result d-flex flex-row
             justify-content-start align-items-start align-items-lg-center">

          <!--for desktop, IPad-->
          <div *ngIf="deviceInnerWidth > 768" class="d-flex flex-row justify-content-start align-items-center"
               style="width: 522px">

            <section class="d-flex flex-row" style="width: 100%">
              <img class="found-emp-image" [src]="foundEmployee.profile_picture_url ? foundEmployee.profile_picture_url :
            'assets/org-chart/pic-placeholder.png'"
                   alt="profile pic">
              <div class="d-flex flex-column" style="width: 195px; margin-right: 20px">
                <span class="full-name-text-found-employee">{{foundEmployee.name}}</span>
                <span class="generic-grey-text">{{foundEmployee.job_title}}</span>
              </div>
              <div class="d-flex flex-column" style="width: 244px">
                <div class="d-flex flex-row justify-content-start align-items-center">
                  <img src="assets/new-org-chart/email.png" alt="email icon">
                  <span class="email-text">
                    <a href="mailto:{{foundEmployee.email}}">{{foundEmployee.email}}</a>
                  </span>
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center">
                  <img src="assets/new-org-chart/phone.png" alt="phone icon">
                  <span class="generic-grey-text">{{foundEmployee.division}}</span>
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center">
                  <img src="assets/new-org-chart/marker.png" alt="marker icon">
                  <span class="generic-grey-text">{{foundEmployee.location}}</span>
                </div>
              </div>
            </section>

          </div>

          <button *ngIf="deviceInnerWidth > 768" class="view-org-chart-button d-flex flex-row justify-content-center
                  align-items-center" (click)="fetchHierarchy(foundEmployee.number)">View Org Chart
          </button>

          <!--for mobile view-->
          <div *ngIf="deviceInnerWidth < 768" class="d-flex flex-row justify-content-between align-items-end"
               style="width: 100%">

            <section class="d-flex flex-row justify-content-start align-items-start">
              <img class="found-emp-image" [src]="foundEmployee.profile_picture_url ? foundEmployee.profile_picture_url :
                    'assets/org-chart/pic-placeholder.png'" alt="profile pic" style="border-radius: 100px">
              <div class="d-flex flex-column justify-content-start align-items-start">
                <span class="full-name-text-found-employee">{{foundEmployee.name}}</span>
                <span class="generic-grey-text">{{foundEmployee.job_title}}</span>
                <div class="d-flex flex-row justify-content-start align-items-center" style="margin-left: 15px">
                  <img src="assets/new-org-chart/email.png" alt="email icon">
                  <span class="email-text">{{foundEmployee.email}}</span>
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center" style="margin-left: 15px">
                  <img src="assets/new-org-chart/phone.png" alt="phone icon">
                  <span class="generic-grey-text">{{foundEmployee.division}}</span>
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center" style="margin-left: 15px">
                  <img src="assets/new-org-chart/marker.png" alt="marker icon">
                  <span class="generic-grey-text">{{foundEmployee.location}}</span>
                </div>
              </div>
            </section>

            <div class="d-flex flex-row justify-content-end" style="width: 100px">
              <button class="view-org-chart-button d-flex flex-row justify-content-center align-items-center"
                      (click)="fetchHierarchy(foundEmployee.number)">View</button>
            </div>

          </div>
        </div>

        <!--0 results found-->
        <div *ngIf="searchWasDid && searchResultsArray.length < 1" class="search-results-zero d-flex flex-column
             justify-content-center align-items-center">

          <div class="d-flex flex-column justify-content-start align-items-center">
            <p class="header-text-style">Nothing Found</p>
            <p class="secondary-info-text-style">Please update your search criteria and try again</p>
          </div>

          <img class="error-message-img" src="assets/new-org-chart/search-error.jpg" alt="search error image">
        </div>
      </div>

    </div>

  </div>
</div>


