import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IPartner} from '../core/models/partner';

@Component({
  selector: 'jz-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss'],
})
export class DiscountsComponent implements OnInit {

  constructor(private route: ActivatedRoute) {
  }

  partnersDiscounts: IPartner[];
  allLocations: string[];
  filteredDiscounts: IPartner[];
  selectedLocation: string;
  nameString = '';
  noData: boolean; // const created to check if we have data or not

  // states 1 & 2
  beforeClick = true;
  afterClick = false;
  hoverIndex = -1;
  imageResize = false;

  ngOnInit(): void {
    this.resolveDataAndInitialize();
  }

  filterByLocation(location: string) {
    this.selectedLocation = location;
    if (this.nameString) {
      this.filteredDiscounts = this.partnersDiscounts.filter(discount => {
        return (discount.location.location_detail === location) && (discount.name.toLowerCase().includes(this.nameString));
      });
    } else {
      this.filteredDiscounts = this.partnersDiscounts.filter(discount => {
        return discount.location.location_detail === location;
      });
    }
  }

  onKey(value: any) {
    this.nameString = value.toLowerCase();
    this.noData = false;
    if (this.nameString) {
      this.filteredDiscounts = this.partnersDiscounts.filter((partner: IPartner) => {
        if (this.selectedLocation) {
          return (partner.name.toLowerCase().includes(this.nameString)) && (partner.location.location_detail === this.selectedLocation);
        } else {
          return partner.name.toLowerCase().includes(this.nameString);
        }
      });
    } else {
      if (this.selectedLocation) {
        // filter by location again
        this.filterByLocation(this.selectedLocation);
        return this.filteredDiscounts;
      } else {
        // init again
        this.initDiscountList();
        return this.filteredDiscounts;
      }
    }
    if (this.filteredDiscounts.length === 0) {
      this.noData = true; // if this true will show in the UI thr state for no data
    }
  }

  // Private functions
  private resolveDataAndInitialize() {
    this.route.data.subscribe((data) => {
      this.partnersDiscounts = data.partners;
      // console.log('testing', this.partnersDiscounts);
      // Inits
      this.extractLocations(this.partnersDiscounts);
      this.initDiscountList();
    });
  }

  // Extracts location obj nested in Partners obj.
  // Used to populate Location filter options.
  private extractLocations(partners: IPartner[]) {
    if (partners) {
      this.allLocations = partners.map(p => {
        return p.location.location_detail;
      }).filter((elem, idx, array) => {
        return idx === array.indexOf(elem);
      });
    }
  }

  private initDiscountList() {
    this.filteredDiscounts = this.partnersDiscounts;
  }

  changeState() {
    this.beforeClick = !this.beforeClick;
  }

  onHover(i: number) {
    this.hoverIndex = i;
    this.imageResize = !this.imageResize;
  }

}
