<section id="pic-contest">

  <div id="header">
    <span class="filsonProMed">Halloween Costume Contest</span>
    <p class="filsonProMed">Submit</p>
  </div>

  <ngx-spinner
    bdColor="rgba(51,51,51,0.2)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  ></ngx-spinner>

  <div *ngIf="submissionPeriodOpened">
    <div id="body-container"
      class="d-flex"
      @preview
      [ngStyle]="{'flex-direction': isNavOpen && innerWidth < 1300 && innerWidth > 1000 ? 'column' : 'row'}"
    >
      <div id="form-section"
           class="mt-5"
           [ngStyle]="{'width': isNavOpen && innerWidth < 1300 ? '100%' : '70%'}"
      >
        <h2>Submit your Photo</h2>

        <form [formGroup]="contentForm" (ngSubmit)="onSubmit()">
          <div class="label-input-container">
            <input
              id="title"
              name="title"
              placeholder="Costume Name"
              formControlName="title"
              style="width: 100%"
            />
          </div>

          <div id="email-name-container">
            <div class="label-input-container">
              <label for="email">Email*</label>
              <input id="email" name="email" placeholder="email@jazwares.com" formControlName="email"/>
            </div>

            <div class="label-input-container">
              <label for="name">Name*</label>
              <input id="name" name="name" placeholder="Type Name" formControlName="name"/>
            </div>
          </div>


          <!--region dropzone-->
          <div
            id="dropdown-section"
            ngx-dropzone
            (change)="onSelect($event)"
            [multiple]="false"
            [maxFileSize]="2097152"
          >
            <ngx-dropzone-label>One image. Formats: .jpeg or .png. Under 2 mgb.</ngx-dropzone-label>

            <span *ngIf="isPdf">
                      <ngx-dropzone-preview
                        *ngFor="let f of selectedFiles"
                        [file]="f"
                        [removable]="true"
                        (removed)="onRemove(f)"
                      >
                        <ngx-dropzone-label>{{f.name}} {{f.type}}</ngx-dropzone-label>
                      </ngx-dropzone-preview>
                    </span>

            <span *ngIf="!isPdf">
                      <ngx-dropzone-image-preview
                        ngProjectAs="ngx-dropzone-preview"
                        *ngFor="let f of selectedFiles"
                        [file]="f"
                        [removable]="true"
                        (removed)="onRemove(f)"
                      ></ngx-dropzone-image-preview>
                    </span>
          </div>
          <!--          endregion-->

          <div id="buttons-container">
            <button
              class="s-button"
              style="margin-left: 30px;"
              type="submit"
              [disabled]="!contentForm.valid || isSubmitted || selectedFiles.length === 0"
              [ngStyle]="{opacity: !contentForm.valid || isSubmitted || selectedFiles.length === 0}"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <div id="reminder-section"
           class="mt-5"
           [ngStyle]="{
        'width': isNavOpen && innerWidth < 1300 && innerWidth > 1000 ? '100%' : '30%',
        'margin-left': isNavOpen && innerWidth < 1300 && innerWidth > 1000 ? 0 : '20px'
      }"
      >
        <h3>Hello Jazmanians,</h3>
        <ol>
          <li>Upload photo and make sure your photo is under 2 MB.</li>
          <li>Make sure you add a title for your costume name.</li>
          <li>Please upload 1 photo per submission.</li>
          <li>Your photo must be either in Jpeg or Png formats.</li>
          <li>Photos must be uploaded by 3 pm (your time) no later than October 29th.</li>
          <li>Voting will take place after Monday, November 1st.</li>
          <li>Good luck and get ready for some prizes.</li>
          <li>Honor Code: Submissions must be from costumes worn between 10/27/21-10/29/21.</li>
        </ol>
      </div>
    </div>

  </div>

  <div *ngIf="!submissionPeriodOpened" class="d-flex flex-column align-items-center p-5">
    <img alt="submission period not active" src="assets/bluej/contest/end-submission-period.png"/>
    <p id="empty-state-graph" class="text-muted filsonProMed mt-4">The Submission Period Is Not Active</p>
  </div>

</section>
