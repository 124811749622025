import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SocialMediaAcc} from './models/social-media-acc';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaAccountService {
  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getSocialMediaAccounts(): Observable<SocialMediaAcc[]> {
    return this.http.get<SocialMediaAcc[]>(this.url + 'resources2/social_links');
  }
}
