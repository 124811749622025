import {Component, HostListener, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {triggerPreviewAnimation, triggerThePreviewAnimation} from '../../shared/animations';
import {UtilsService} from '../../core/utils/utils.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthorizationService} from '../../core/authorization.service';
import {AuthService} from 'angularx-social-login';
import {SizeDialogComponent} from '../../idea-contest/submit-idea/size-dialog/size-dialog.component';
import {ImageSelectionComponent} from '../../idea-contest/submit-idea/image-selection/image-selection.component';
import {MatDialog} from '@angular/material/dialog';
import {NgxSpinnerService} from 'ngx-spinner';
import {ISubmission} from '../../core/models/halloween-contest';
import {IdeaService} from '../../core/idea.service';
import {SubmissionService} from '../../core/submission.service';
import {IIdea} from '../../core/models/idea';
import {AfterSubmissionComponent} from '../../idea-contest/submit-idea/after-submission/after-submission.component';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'jz-submit-content',
  templateUrl: './submit-content.component.html',
  styleUrls: ['./submit-content.component.scss'],
  animations: [
    triggerPreviewAnimation,
  ]
})
export class SubmitContentComponent implements OnInit {

  isNavOpen: boolean;
  innerWidth = window.innerWidth;
  isSubmitted = false;
  submissionPeriodOpened = true;

  contentForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
    ]),
    name: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
  });

  selectedFiles: File[] = [];
  dialogRef;
  isPdf = false;
  imageUrl: string | ArrayBuffer;


  constructor(
    private utilsService: UtilsService,
    private auth: AuthorizationService,
    private authService: AuthService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private submissionService: SubmissionService,
    private toastr: ToastrService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    // Subscribing to changes of nav (opened or closed) to style accordingly
    this.utilsService.navOpened.subscribe((isOpen) => {
      this.isNavOpen = isOpen;
    });
    this.getUserEmail();
  }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  getUserEmail() {
    if (this.auth.user) {
      this.contentForm.controls.email.reset({value: this.auth.user.email, disabled: true});
      this.contentForm.controls.name.reset({value: this.auth.user.name, disabled: true});
    } else {
      this.authService.authState.subscribe((user) => {
        this.auth.setLoggedInUser(user);
        this.contentForm.controls.email.reset({value: user.email, disabled: true});
        this.contentForm.controls.name.reset({value: user.name, disabled: true});
      });
    }
  }

  // Method for selecting image
  onSelect(event) {
    // If File size is greater than 1MB list of rejectedFiles will be
    // greater than zero so we present dialog letting the user know
    if (event.rejectedFiles.length > 0) {
      this.dialogRef = this.dialog.open(SizeDialogComponent, {
        width: '450px',
        disableClose: true
      });
    }

    // If there is more than one file present this dialog so the user can
    // choose between both files selected
    if (this.selectedFiles.length === 1) {
      this.dialogRef = this.dialog.open(ImageSelectionComponent, {
        width: '450px',
        disableClose: true
      });
    }

    if (this.selectedFiles.length === 0) {
      this.selectedFiles.push(...event.addedFiles);
    }

    // Adding files to list of selected files and setting if file is pdf or not
    if (this.dialogRef && event.addedFiles.length > 0) {
      this.dialogRef.afterClosed().subscribe(result => {
        if (result === 'override') {
          this.selectedFiles.push(...event.addedFiles);
          this.selectedFiles.shift();
          this.isPdf = this.selectedFiles[0].type === 'application/pdf';
        } else {
          this.selectedFiles.push(...event.addedFiles);
          this.selectedFiles.pop();
          this.isPdf = this.selectedFiles[0].type === 'application/pdf';
        }
      });
    }

    // For previewing the image selected
    if (this.selectedFiles.length > 0) {
      if (this.selectedFiles[0].type !== 'application/pdf') {
        const reader = new FileReader();
        reader.readAsDataURL(this.selectedFiles[0]);
        reader.onload = (readerEvent) => {
          this.imageUrl = reader.result;
        };
      } else {
        this.isPdf = true;
      }
    }
  }

  // Removing img
  onRemove(event) {
    this.selectedFiles.splice(this.selectedFiles.indexOf(event), 1);
    this.imageUrl = undefined;
  }

  // Submit form
  onSubmit() {
    const submission: ISubmission = {
      title: this.contentForm.get('title').value,
      // @ts-ignore
      contents: this.selectedFiles[0]
    };

    if (this.contentForm.valid) {
      this.spinner.show(); // activate spinner

      this.isSubmitted = true;

      this.submissionService.createSubmission(submission).subscribe((data: ISubmission) => {
        this.spinner.hide();
        this.isSubmitted = false;
        this.openAfterSubmissionDialog(data);

        // Resetting form and setting email and name
        this.contentForm.reset({
          email: {value: this.auth.user.email, disabled: true},
          name: {value: this.auth.user.name, disabled: true}
        });
        this.selectedFiles = []; // Selected images to be empty
      }, (error) => {
        this.spinner.hide();
        this.isSubmitted = false;

        this.toastr.error('Please contact IT', 'Something went wrong!');

      });
    }
  }

  private openAfterSubmissionDialog(data: ISubmission) {
    const dialogRef = this.dialog.open(AfterSubmissionComponent, {
      width: '450px',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'another') {
        // Navigate to detail component
        this.router.navigate([]);
      } else {
        // Navigate to explore section
        this.router.navigate(['/']);
      }
    });
  }
}

