import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LearningHub} from '../../../core/models/learning-hub';


@Component({
  selector: 'jz-learning-hub-dashboard',
  templateUrl: './learning-hub-dashboard.component.html',
  styleUrls: ['./learning-hub-dashboard.component.scss']
})
export class LearningHubDashboardComponent implements OnInit {

  categories = [
    {
      cat_name : `Manager's Corner`,
      content : 'Explore these weekly newsletters to help inspire your teams in a hybrid environment.'
    },
    {
      cat_name : `Insights`,
      content : 'Check out these websites, pod casts, webinars, etc. for great insight into what’s new,' +
        ' interesting, challenging and inspiring in the world of work.'
    },
    {
      cat_name : `Recommended Reading List`,
      content : 'For those that are interested in learning about business best practices as told by ' +
        'some of the world’s most respected and trusted masters of their craft.'
    },
    {
      cat_name : `Development Articles`,
      content : 'Divided into categories like “First Time Managers” & “Business Etiquette”, these articles offer great insight.'
    },
    {
      cat_name : `Developmental Resources`,
      content : 'Templates, Quick Tips, Reference Guides, Recorded Webinars, and more…'
    },

  ];
  learninghubCat: LearningHub[];
  categoriesView = true;
  filterBy: number;
  detailArray: LearningHub[];
  subCat = false;

  panelOpenState = false;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((data: {learningHubCategories: LearningHub[]}) => {
      this.learninghubCat = data.learningHubCategories;
    });
  }

  onClick(id) {
    this.filterBy = id;
    // console.log(this.filterBy);
    this.showResources();
    window.scrollTo(0, 0);
  }

  closeDetailView() {
    this.categoriesView = !this.categoriesView;
  }

  private showResources() {
    this.detailArray = this.learninghubCat.filter(category => category.id === this.filterBy);
    this.categoriesView = !this.categoriesView;
    if (this.detailArray[0].sub_cat.length) {
      this.subCat = !this.subCat;
    }
  }

}
