import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubmitContentComponent} from './submit-content/submit-content.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {NgxSpinnerModule} from 'ngx-spinner';
import { VoteContentComponent } from './vote-content/vote-content.component';
import {ContentResolver} from '../core/resolvers/content.resolver';
import {RouterModule} from '@angular/router';
import {ModalModule, TooltipModule} from 'ngx-bootstrap';


@NgModule({
  declarations: [
    SubmitContentComponent,
    VoteContentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    NgxSpinnerModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
  ],
  providers: [
    ContentResolver,
  ],
  exports: [
    SubmitContentComponent,
    VoteContentComponent
  ]
})
export class ContestModule {
}
