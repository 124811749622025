import {Component, Input, OnInit} from '@angular/core';
import {Post} from '../../core/models/post';
import {IResource2} from '../../core/models/resource2';
import {Posts2} from '../../core/models/paginatedPosts2ListResponse';
import {Posts2Service} from '../../core/posts2.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'jz-common-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent implements OnInit {

  @Input() posts: Post[];
  @Input() listOfPosts2: Posts2[];
  @Input() resources2: IResource2[];
  @Input() cat: string;
  @Input() totalPages: number;
  @Input() page: number;
  posts2List = [];
  errorMessage = '';

  // local variables for infinite scrolling
  isAll = false;
  notScrolled = true;
  nextPage: number;
  stopLoading: boolean;

  constructor(
    private posts2Service: Posts2Service,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }

  onScroll() {
    // console.log('scrolled!!');
    if (this.notScrolled) {
      this.notScrolled = false;
      this.nextPage = this.page;
      if (this.nextPage !== this.totalPages) {
        this.stopLoading = false;
      } else {
        this.stopLoading = true;
      }
      // start spinner here to add a visual effect for the UI
      this.spinner.show('spinner2');
      // load next page of posts2
      this.loadNextPageOfPosts2();
    }
  }

  // function to load next posts2
  loadNextPageOfPosts2() {
    if (this.stopLoading === false) {
      this.nextPage = this.page + 1;
      this.posts2Service.getPosts2PaginatedByCategory(this.cat, this.nextPage).subscribe({
        next: response => {
          this.listOfPosts2 = this.listOfPosts2.concat(response.posts);
          this.page = this.page + 1;
          this.notScrolled = true;
        //  close spinner
          this.spinner.hide('spinner2');
        },
        error: err => this.errorMessage = err
      });
    } else {
      this.isAll = true;
      //  close spinner
      this.spinner.hide('spinner2');
    }
  }
}
