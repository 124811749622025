import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'jz-check-vote',
  templateUrl: './check-vote.component.html',
  styleUrls: ['./check-vote.component.scss']
})
export class CheckVoteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CheckVoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  ngOnInit(): void {
  }

  onAction(action: string) {
    this.dialogRef.close(action);
  }

}
