import { Component, OnInit } from '@angular/core';
import {Post} from '../core/models/post';
import {CategoriesService} from '../core/categories.service';
import {PostsService} from '../core/posts.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Posts2Service} from '../core/posts2.service';
import {PaginatedPosts2ListResponse, Posts2} from '../core/models/paginatedPosts2ListResponse';
import {IResource2} from '../core/models/resource2';
import {Resources2Service} from '../core/resources2.service';

@Component({
  selector: 'jz-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent implements OnInit {

  posts: Post[];
  cat = 'corporate-compliance-corner';
  codeOfConductCat = 'Code of Conduct/ Alert Line';
  paginatedPosts2ListResponse: PaginatedPosts2ListResponse;
  errorMessage = '';
  resources2: IResource2[];
  page = 1;
  listOfPosts2: Posts2[];
  totalPages: number;

  constructor(
    private categoriesService: CategoriesService,
    private postsService: PostsService,
    private spinner: NgxSpinnerService,
    private posts2Service: Posts2Service,
    private resourceService: Resources2Service
  ) { }

  ngOnInit(): void {
    // if (this.categoriesService.categories['corporate-compliance-corner']) {
    //   this.postsService.getPosts(this.categoriesService.categories['corporate-compliance-corner'])
    //     .subscribe((data: Post[]) => {
    //       this.posts = data;
    //       this.spinner.hide();
    //     });
    // } else {
    //   this.categoriesService.getCategories().subscribe((data) => {
    //     this.categoriesService.setCategories(data);
    //     this.postsService.getPosts(this.categoriesService.categories['corporate-compliance-corner'])
    //       .subscribe((postData: Post[]) => {
    //         this.posts = postData;
    //         this.spinner.hide();
    //       });
    //   });
    // }
    this.fetchPosts2();
    this.fetchResources2();
  }

  private fetchResources2() {
    this.resourceService.getResources2ByCat(this.codeOfConductCat).subscribe({
        next: response => {
          if (response.length) {
            this.resources2 = response;
          }
        }
      }
    );
  }

  // private function below to call posts2 paginated
  private fetchPosts2() {
    this.spinner.show();
    this.posts2Service.getPosts2PaginatedByCategory(this.cat, this.page).subscribe({
      next: response => {
        this.paginatedPosts2ListResponse = response;
        // console.log(this.paginatedPosts2ListResponse);
        this.listOfPosts2 = this.paginatedPosts2ListResponse.posts;
        this.totalPages = this.paginatedPosts2ListResponse.total_pages;
        this.spinner.hide();
      },
      error: err => this.errorMessage = err
    });
  }

}
