import { ConstantPool } from '@angular/compiler';
import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { env } from 'process';
import { OrgData } from '../orgData';
import { AngOrgChartService } from '../services/ang-org-chart.service';

@Component({
  selector: 'jz-angular-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss', '../org-chart-combined.scss']
})
export class OrgChartComponent implements OnInit {
	constructor(private evtSvc: AngOrgChartService){}
	private _data: OrgData;
	private _searchParams: [];
	private _head:string;
	results = [];

	@Input() set head(value: string){
		this._head = value;
		this._searchParams.forEach(param => {
			this.hideChild = false;
			if (param['type'] == 'division') {
				if (this.data.division.toLowerCase().trim() == String(param['value']).toLowerCase().trim()){
					
					if(this.data.email.toLowerCase().trim() == this.head.toLowerCase().trim()){
						let names = document.querySelectorAll('.org-chart-entity-name')

						names.forEach(name=>{
							let element = (name as HTMLElement)
							let email = element.dataset.email

							if(email && this.head){

							
							if(email.toLowerCase() == this.head.toLowerCase()){
						
								//element.parentElement.parentElement.parentElement.classList.add('scroll-margin-top-50')
								//element.style.scrollMarginTop = '50px'
								element.setAttribute('style','scroll-margin-top:50px')
								//this.evtSvc.emitChildLoadingEvent(true)
								setTimeout(() => {
									element.scrollIntoView({inline:'center',behavior:'auto',block:'start'});
								},1000);

								//setTimeout(()=>{this.evtSvc.emitChildLoadingEvent(false)},2000)
								
							
							}
							}

						})
					}
				};
			  }

			  if (param['type'] == 'department') {
				if (this.data.department.toLowerCase().trim() == String(param['value']).toLowerCase().trim()){
					
					if(this.data.email.toLowerCase().trim() == this.head.toLowerCase().trim()){
						let names = document.querySelectorAll('.org-chart-entity-name')

						names.forEach(name=>{
							let element = (name as HTMLElement)
							let email = element.dataset.email

							if(email && this.head){

							
							if(email.toLowerCase() == this.head.toLowerCase()){
								
								element.setAttribute('style','scroll-margin-top:50px')
								//this.evtSvc.emitChildLoadingEvent(true)
								setTimeout(() => {
									element.scrollIntoView({inline:'center',behavior:'auto',block:'start'});
								},1000);

								setTimeout(()=>{this.evtSvc.emitChildLoadingEvent(false)},2000)
								
							
							}
							}

						})
					}
				};
			  } 
			})
	}
	@Input() set searchParams(value: []){
		
		this._searchParams = value;
		// this._searchParams.forEach(param => {
		// if (param['type'] == 'division') {
		// 	if (this.data.division == param['value']){
		// 		this.hideChild = false;
		// 	};
		//   }

		//   if(this.head == this.data.email){
		
		//  }
		// })
		
	}
	@Input() set data(value: OrgData) {
		this._data = value;
		if (this.data.status) {
			if (this.data.status === 'close') {
			  this.hideChild = true;
			} else {
				this.hideChild = false;
			}
    }
	}
	@Input() hasParent = false;
	@Input() user: string;
	hideChild = false;
	openUserDetailedView = true;
	userEmail = localStorage.getItem('email');
  @Input() noZoomEffect = true;
  @Input() zoomIn = false;
  @Input() zoomOut = false;
  @Output() receiveModalEmail = new EventEmitter();
                      


	toggleShowChild(e) {
    this.hideChild = !this.hideChild;
		setTimeout(() => {
			let email = findEmail(e.target)
			this.evtSvc.emitChildEvent(email.dataset.email,false)
			e.target.scrollIntoView({inline:'center',behavior:'smooth',block:'center'});

			let entity_boxes = document.querySelectorAll('.org-chart-entity-box')

			entity_boxes.forEach((box,index)=>{
				box.classList.remove('entity-select')

				if(index == entity_boxes.length - 1){
					
						
						//e.target.parentElement.parentElement.classList.add('entity-select')
						//let element = findParentElement(e.target,'org-chart-entity-box')
					    let p_element = findParentElement(e.target,'org-chart-entity-box')
						p_element.classList.add('entity-select')

					
				}
			})

			

		}, 100);
	}

	emitModalEmail(email): void{
		this.receiveModalEmail.emit(email)
	}

	ngOnInit(): void {
		if (this.data.status) {
		  if (this.data.status === 'close') {
			  this.hideChild = true;
		  }
		}
  }

  toggle(email: string): void {
	  this.userEmail = email;
	  if (!this.openUserDetailedView) {
		  this.openUserDetailedView = true;
	  }
  }



  get searchParams(): []{
	return this._searchParams
  }
  get data(): OrgData {
	  return this._data;
  }
  get head(): string{
	return this._head;
}


}

function findParentElement(element,class_name){
	if(element.classList.contains(class_name))
	{
		return element
	} 
	else {
		return findParentElement(element.parentElement,class_name)
	}
}

function findEmail(element){
	let child = element.parentElement.querySelectorAll('.org-chart-entity-name')

	if(child.length == 1){
		return child[0]
	} else {
		return element.parentElement.parentElement.querySelectorAll('.org-chart-entity-name')[0]
	}
}