import {Component, HostListener, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
// import {trigger, animate, style, transition} from '@angular/animations';
import {Location} from '@angular/common';
import {UtilsService} from '../../core/utils/utils.service';
import {IdeaService} from '../../core/idea.service';
import {Idea, IdeaCategory, IIdea} from '../../core/models/idea';
import {AuthorizationService} from '../../core/authorization.service';
import {AuthService} from 'angularx-social-login';
import {MatDialog} from '@angular/material/dialog';
import {ImageSelectionComponent} from './image-selection/image-selection.component';
import {AfterSubmissionComponent} from './after-submission/after-submission.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {SizeDialogComponent} from './size-dialog/size-dialog.component';
import {Contest} from '../../core/models/contest';
import {triggerPreviewAnimation, triggerThePreviewAnimation} from '../../shared/animations';

@Component({
  selector: 'jz-submit-idea',
  templateUrl: './submit-idea.component.html',
  styleUrls: ['./submit-idea.component.scss'],
  animations: [
    triggerPreviewAnimation,
    triggerThePreviewAnimation
  ]
})
export class SubmitIdeaComponent implements OnInit {

  contest: Contest;

  ideaForm = new FormGroup({
    category: new FormControl(''),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
    ]),
    name: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required)
  });

  isSubmitted = false;
  showPreview = false;
  isNavOpen: boolean;
  innerWidth = window.innerWidth;
  titleRepeated = false;

  categories: IdeaCategory[];
  selectedFiles: File[] = [];
  imageUrl: string | ArrayBuffer;
  dialogRef;
  isPdf = false;

  constructor(
    private location: Location,
    private utilsService: UtilsService,
    private ideaService: IdeaService,
    private auth: AuthorizationService,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // Subscribing to changes of nav (opened or closed) to style accordingly
    this.utilsService.navOpened.subscribe((isOpen) => {
      this.isNavOpen = isOpen;
    });

    this.route.data.subscribe(data => {
      this.contest = data.contest[0];
    });

    this.getUserEmail(); // Getting user email for submission
    this.getCategories(); // Get categories
  }

  getCategories() {
    this.ideaService.getCategories().subscribe((categories: IdeaCategory[]) => {
      this.categories = categories;
    });
  }

  getUserEmail() {
    if (this.auth.user) {
      this.ideaForm.controls.email.reset({value: this.auth.user.email, disabled: true});
      this.ideaForm.controls.name.reset({value: this.auth.user.name, disabled: true});
    } else {
      this.authService.authState.subscribe((user) => {
        this.auth.setLoggedInUser(user);
        this.ideaForm.controls.email.reset({value: user.email, disabled: true});
        this.ideaForm.controls.name.reset({value: user.name, disabled: true});
      });
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  onChangeToPreview() {
    this.showPreview = !this.showPreview;
  }

  navigateBack() {
    this.location.back();
  }

  // Method for selecting image
  onSelect(event) {
    // If File size is greater than 1MB list of rejectedFiles will be
    // greater than zero so we present dialog letting the user know
    if (event.rejectedFiles.length > 0) {
      this.dialogRef = this.dialog.open(SizeDialogComponent, {
        width: '450px',
        disableClose: true
      });
    }

    // If there is more than one file present this dialog so the user can
    // choose between both files selected
    if (this.selectedFiles.length === 1) {
      this.dialogRef = this.dialog.open(ImageSelectionComponent, {
        width: '450px',
        disableClose: true
      });
    }

    if (this.selectedFiles.length === 0) {
      this.selectedFiles.push(...event.addedFiles);
    }

    // Adding files to list of selected files and setting if file is pdf or not
    if (this.dialogRef && event.addedFiles.length > 0) {
      this.dialogRef.afterClosed().subscribe(result => {
        if (result === 'override') {
          this.selectedFiles.push(...event.addedFiles);
          this.selectedFiles.shift();
          this.isPdf = this.selectedFiles[0].type === 'application/pdf';
        } else {
          this.selectedFiles.push(...event.addedFiles);
          this.selectedFiles.pop();
          this.isPdf = this.selectedFiles[0].type === 'application/pdf';
        }
      });
    }

    // For previewing the image selected
    if (this.selectedFiles.length > 0) {
      if (this.selectedFiles[0].type !== 'application/pdf') {
        const reader = new FileReader();
        reader.readAsDataURL(this.selectedFiles[0]);
        reader.onload = (readerEvent) => {
          this.imageUrl = reader.result;
        };
      } else {
        this.isPdf = true;
      }
    }
  }

  // Removing img
  onRemove(event) {
    this.selectedFiles.splice(this.selectedFiles.indexOf(event), 1);
    this.imageUrl = undefined;
  }

  // Submit form
  onSubmit() {
    const idea: Idea = {
      title: this.ideaForm.get('title').value,
      description: this.ideaForm.get('description').value,
      category: parseInt(this.ideaForm.get('category').value, 10),
      file_content: this.selectedFiles[0]
    };

    if (this.ideaForm.valid) {
      this.spinner.show(); // Activating spinner

      this.titleRepeated = false;
      this.isSubmitted = true;

      this.ideaService.createIdea(idea).subscribe((data: IIdea) => {
        this.spinner.hide();
        this.isSubmitted = false;
        this.openAfterSubmissionDialog(data);

        // Resetting form and setting email and name
        this.ideaForm.reset({
          email: {value: this.auth.user.email, disabled: true},
          name: {value: this.auth.user.name, disabled: true}
        });

        this.ideaForm.controls.category.setValue('');

        this.selectedFiles = []; // Selected images to be empty
      }, (error) => {
        this.spinner.hide();
        this.isSubmitted = false;

        if (error.error.title[0] === 'Idea with this Title already exists.') {
          this.toastr.error('Your idea\'s title is already taken', 'Title Taken');
          this.titleRepeated = true;
        } else {
          this.toastr.error('We are working on this issue, try again in a few minutes', 'Something went wrong!');
        }
      });
    }
  }

  openAfterSubmissionDialog(data: IIdea) {
    const dialogRef = this.dialog.open(AfterSubmissionComponent, {
      width: '450px',
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'another') {
        // Navigate to detail component
        this.router.navigate([]);
      } else {
        // Navigate to explore section
        this.router.navigate(['/bluej/Bluej-Posts-Detail']);
      }
    });
  }

}
