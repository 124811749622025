import { Component, OnInit } from '@angular/core';
import {PaginatedPosts2ListResponse, Posts2} from '../core/models/paginatedPosts2ListResponse';
import {IResource2} from '../core/models/resource2';
import {Resources2Service} from '../core/resources2.service';
import {Posts2Service} from '../core/posts2.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'jz-jplanet',
  templateUrl: './jplanet.component.html',
  styleUrls: ['./jplanet.component.scss']
})
export class JplanetComponent implements OnInit {

  catForResources = 'jplanet';
  cat = 'jplanet';
  listOfPosts2: Posts2[];
  resources2: IResource2[];
  page = 1;
  totalPages: number;
  paginatedPosts2ListResponse: PaginatedPosts2ListResponse;
  errorMessage = '';

  constructor(private resourceService: Resources2Service,
              private posts2Service: Posts2Service,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.fetchPosts2();
    this.fetchResources2();
  }

  // private functions
  private fetchResources2() {
    this.resourceService.getResources2ByCat(this.catForResources).subscribe({
        next: response => {
          if (response.length) {
            this.resources2 = response;
          }
        }
      }
    );
  }

  private fetchPosts2() {
    this.spinner.show();
    this.posts2Service.getPosts2PaginatedByCategory(this.cat, this.page).subscribe({
      next: response => {
        this.paginatedPosts2ListResponse = response;
        this.listOfPosts2 = this.paginatedPosts2ListResponse.posts;
        this.totalPages = this.paginatedPosts2ListResponse.total_pages;
        this.spinner.hide();
      },
      error: err => this.errorMessage = err
    });
  }

}
