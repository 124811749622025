<div id="right-content">
  <div class="container" id="global-search-container">

    <!-- global search input below -->
    <div class="row g-3" style="margin-top: 20px">
      <div class="col-sm-10" id="input-icons">
        <input type="search" class="form-control"
               placeholder="Search here"
               aria-label="input"
               [(ngModel)]="listFilter">
      </div>
      <div class="col-sm-2" id="global_search-button">
        <button type="button" class="btn btn-primary input-group-text"
                id="basic-addon1"
                [disabled]="userInput === ''"
                (click)="performSearch()">
          Search
        </button>
      </div>
    </div>

    <!-- new section start here -->
    <div class="row">

      <!-- filters here -->
      <div class="col-lg-3 col-md-12">
        <div class="grey-section p-4">
          <p><strong>Filters</strong></p>
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let item of checkList">
              <input type="checkbox" [(ngModel)]="item.isSelected" name="list_name"
                     value="{{item.id}}"/>
              {{item.value}}
            </li>
          </ul>
          <br>
          <button type="button" class="btn btn-primary input-group-text"
                  id="basic-addon2"
                  (click)="performSearch()">
            Apply
          </button>
        </div>
      </div>

      <!-- search results here -->
      <div class="col-lg-9 col-md-12">
        <div *ngIf="userInput && searchPerformed" class="search-section">
          <p class="card-title"><strong>Results by:</strong> {{listFilter}}</p>
          <a role="button" target="_top"
             (click)="resetSearch()"
             popover="Click here to restart the search"
             triggers="mouseenter:mouseleave" [delay]="500">clear search</a>
        </div>

        <!-- card for HR resources -->
        <div class="card card-search-results" *ngIf="resourceResponseObj && checkList[1].isSelected ">
          <span style="background: #AD21E2;
          border-radius: 20px;
          color: #AD21E2;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 23px">-</span>
          <p><strong>HR Resources</strong></p>
          <div class="card-body d-flex flex-column justify-content-between">
            <p class="text-muted" *ngIf="resourceResponseObj.results?.length === 0 && !searchPerformed">
              Your search results will be seen here!
            </p>
            <p class="text-muted" *ngIf="resourceResponseObj.results?.length === 0 && searchPerformed">
              Sorry we couldn't find anything
            </p>
            <ul *ngFor="let resource of resourceResponseObj.results">
              <li class="list-items">
                <a
                  class="resource-container"
                  [href]="(resource.resource_type === 'pdf' || resource.resource_type === 'xlsx') ? resource.resource_url :
                resource.external_link"
                  [target]="'_blank'"
                >
                  <img
                    alt="file type"
                    [src]="resource.resource_type === 'pdf' ? 'assets/pdf-logo.png' : 'assets/links-logo.png'"
                  />
                  <p>{{resource.name}}</p>
                </a>
              </li>
            </ul>
            <div class="force-pagination-margin" style="padding-top: 20px; margin: auto">
              <div class="pagination" *ngIf="resourceResponseObj.results?.length && searchPerformed">
                <pagination [totalItems]="resourceResponseObj.count"
                            (pageChanged)="onPageChangedForResources($event)"></pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- card for POST's -->
        <div class="card card-search-results" id="card_posts" *ngIf="postResponseObj.results && checkList[0].isSelected">
          <span style="background: #AD21E2;
          border-radius: 20px;
          color: #AD21E2;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 23px">-</span>
          <p><strong>Posts/News</strong></p>
          <div class="card-body d-flex flex-column justify-content-between">
            <p class="text-muted" *ngIf="postResponseObj.results?.length === 0 && !searchPerformed">
              Your search results will be seen here!
            </p>
            <p class="text-muted" *ngIf="postResponseObj.results?.length === 0 && searchPerformed">
              Sorry we couldn't find anything
            </p>
            <ul *ngFor="let post of postResponseObj.results">
              <li class="list-items" [routerLink]="['/posts', post.pk]">
                <p class="post-headline" style="color: #007bff; font-size: 16px">{{post.headline}}</p>
              </li>
            </ul>
            <div class="force-pagination-margin" style="padding-top: 20px; margin: auto">
              <div class="pagination" *ngIf="postResponseObj.results?.length && searchPerformed">
                <pagination [totalItems]="postResponseObj.count"
                            (pageChanged)="onPageChangedForPosts($event)"></pagination>
              </div>
            </div>

          </div>
        </div>

        <!-- card for Other's -->
      </div>
    </div>
    <!-- new section ends here -->

  </div>
</div>

