import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {OrgData, OrgSearchUsers, OrgSearchOther} from '../orgData';

@Injectable({
  providedIn: 'root'
})
export class AngOrgChartService {
  url = environment.apiUrl;
  private userEmail: string;

  constructor(
    private http: HttpClient) {
  }

  // method to get all the org chart json
  getOrgChart(): Observable<OrgData[]> {
    // method to get user's email
    this.userEmail = localStorage.getItem('email');
    return this.http.get<OrgData[]>(this.url + 'api/v1/users/org_filter?user=' + this.userEmail);
  }

  getOrgChartbySearch(email): Observable<OrgData[]> {
    // method to get user's email
    return this.http.get<OrgData[]>(this.url + 'api/v1/users/org_filter?user=' + encodeURIComponent(email));
  }

  getUserList(): Observable<OrgSearchUsers[]> {
    return this.http.get<OrgSearchUsers[]>(this.url + 'api/v1/users/search_options_user');

  }

  getOtherList(): Observable<OrgSearchOther[]> {
    return this.http.get<OrgSearchOther[]>(this.url + 'api/v1/users/search_options_other');

  }

  getUserModalData(email): Observable<OrgData[]> {
    return this.http.get<OrgData[]>(this.url + 'api/v1/users/modal_data?user=' + email);

  }

  getHead(type, name): Observable<OrgData[]> {
    return this.http.get<OrgData[]>(this.url + 'api/v1/users/head?name=' + encodeURIComponent(name) + '&type=' + type);

  }

  // modal email service
  private childClickedEvent = new BehaviorSubject<any>('');

  emitChildEvent(msg: string, open: boolean){
     this.childClickedEvent.next(msg);
     this.childClickedEvent.next(open);
  }

  childEventListner(){
     return this.childClickedEvent.asObservable();
   }

   private loadingEvent = new BehaviorSubject<any>('');

   emitChildLoadingEvent(loading: boolean){
    this.loadingEvent.next(loading);
   }

   childLoadingEventListner(){
    return this.loadingEvent.asObservable();
  }
}
