import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Post} from '../core/models/post';
import {CategoriesService} from '../core/categories.service';
import {PostsService} from '../core/posts.service';
import {CalendarEvent} from '../core/models/calendar-event';
import {EventService} from '../core/event.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {PaginatedPosts2ListResponse, Posts2} from '../core/models/paginatedPosts2ListResponse';
import {Posts2Service} from '../core/posts2.service';
import {Resources2Service} from '../core/resources2.service';
import {IResource2} from '../core/models/resource2';

@Component({
  selector: 'jz-jfit',
  templateUrl: './jfit.component.html',
  styleUrls: ['./jfit.component.scss']
})
export class JfitComponent implements OnInit {
  slideWeekConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true
        }
      }
    ]
  };
  slideConfigYear = {
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true
      }
    ]
  };
  posts: Post[];
  paginatedPosts2ListResponse: PaginatedPosts2ListResponse;
  errorMessage = '';
  cat = 'jfit';
  timeoutId: number;
  dateObject = new Date();
  month = this.dateObject.getMonth() + 1;
  year = this.dateObject.getFullYear();
  day = this.dateObject.getDay();
  date = this.dateObject.getDate();
  lastDay = new Date(this.year, this.month, 0).getDate();
  calendarEvents: CalendarEvent[];
  categoryPk: number;
  correctLastDay: number;
  correctLastMonth: string;
  monthNames = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  };
  days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  // cat name and array to store the resources2
  catForResources = 'jfit';
  resources2: IResource2[];
  page = 1;
  listOfPosts2: Posts2[];
  totalPages: number;

  constructor(
    private categoriesService: CategoriesService,
    private postsService: PostsService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private posts2Service: Posts2Service,
    private resourceService: Resources2Service
  ) { }

  ngOnInit(): void {
    this.fetchPosts2();
    this.fetchResources2();
  }

  private fetchResources2() {
    this.resourceService.getResources2ByCat(this.catForResources).subscribe({
      next: response => {
        if (response.length) {
          this.resources2 = response;
        }
      }
      }
    );
  }

  // private function below to call posts2 paginated
  private fetchPosts2() {
    this.spinner.show();
    this.posts2Service.getPosts2PaginatedByCategory(this.cat, this.page).subscribe({
      next: response => {
        this.paginatedPosts2ListResponse = response;
        // this.posts2List = response[0].posts;
        // console.log(this.paginatedPosts2ListResponse);
        this.listOfPosts2 = this.paginatedPosts2ListResponse.posts;
        this.totalPages = this.paginatedPosts2ListResponse.total_pages;
        this.spinner.hide();
      },
      error: err => this.errorMessage = err
    });
  }
}
