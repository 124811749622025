import {Component, HostListener, OnInit} from '@angular/core';
import {CategoriesService} from '../core/categories.service';
import {PostsService} from '../core/posts.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {PaginatedPosts2ListResponse, Posts2} from '../core/models/paginatedPosts2ListResponse';
import {Posts2Service} from '../core/posts2.service';
import {Resources2Service} from '../core/resources2.service';
import {IResource2} from '../core/models/resource2';


@Component({
  selector: 'jz-jazwares-cares',
  templateUrl: './jazwares-cares.component.html',
  styleUrls: ['./jazwares-cares.component.scss']
})
export class JazwaresCaresComponent implements OnInit {

  paginatedPosts2ListResponse: PaginatedPosts2ListResponse;
  errorMessage = '';
  cat = 'jcares';
  innerWidth = window.innerWidth;
  private url = environment.apiUrl;
  email = 'jazwarescares@jazwares.com';
  isDisabled = false;
  userEmail = '';
  clicked = '';
  postId;
  resources2: IResource2[];
  page = 1;
  listOfPosts2: Posts2[];
  totalPages: number;

  constructor(
    private categoriesService: CategoriesService,
    private postsService: PostsService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private posts2Service: Posts2Service,
    private resourceService: Resources2Service
  ) { }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.userEmail = localStorage.getItem('email');
    this.clicked = JSON.parse(sessionStorage.getItem('clicked'));
    this.fetchPosts2();
    this.fetchResources2();
  }

  // public functions
  sendVolunteerEmail() {
    this.http.post<any>(this.url + 'posts2/volunteer_request', { email: this.userEmail}).subscribe({
      next: data => {
        this.postId = data.id;
        this.isDisabled = !this.isDisabled;
        alert('Your email was sent!');
        // console.log('this is the data', data);
        sessionStorage.setItem('clicked', JSON.stringify('true'));
        this.clicked = JSON.parse(sessionStorage.getItem('clicked'));
      },
      error: error => {
        this.errorMessage = error.message;
        console.log('there was an error', error);
      }
    });
  }

  private fetchResources2() {
    this.resourceService.getResources2ByCat(this.cat).subscribe({
        next: response => {
          this.resources2 = response;
        }
      }
    );
  }

  // private function below to call posts2 paginated
  private fetchPosts2() {
    this.spinner.show();
    this.posts2Service.getPosts2PaginatedByCategory(this.cat, this.page).subscribe({
      next: response => {
        this.paginatedPosts2ListResponse = response;
        // this.posts2List = response[0].posts;
        // console.log(this.paginatedPosts2ListResponse);
        this.listOfPosts2 = this.paginatedPosts2ListResponse.posts;
        // console.log(this.listOfPosts2);
        this.totalPages = this.paginatedPosts2ListResponse.total_pages;
        this.spinner.hide();
      },
      error: err => this.errorMessage = err
    });
  }
}

