import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PostCategory} from '../core/models/postCategory';
import {AuthService, SocialUser} from 'angularx-social-login';
import {CategoriesService} from '../core/categories.service';
import {AuthorizationService} from '../core/authorization.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../core/utils/utils.service';
import {HomeMessages} from '../core/models/home-messages';
import {FetchingDataService} from '../new-org-chart/services/fetching-data.service';
import {Employee} from '../new-org-chart/models/employee';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LoggedUserProfileComponent} from './logged-user-profile/logged-user-profile.component';

@Component({
  selector: 'jz-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: any;

  categories: PostCategory[];
  onMobile: boolean;
  innerWidth = window.innerWidth;
  user: SocialUser;
  today = new Date();
  intervalId: any;
  homeMessages: HomeMessages[];
  logos;

  loggedUser: Employee[];

  constructor(
    private categoriesService: CategoriesService,
    private googleLoginService: AuthorizationService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private userProfileService: FetchingDataService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.intervalId = setInterval(() => {
      this.today = new Date();
    }, 60000);

    this.route.data.subscribe((data) => {
      this.categories = data.categories;
      this.categoriesService.setCategories(data.categories);
      this.categoriesService.setCategoriesCacheData(data.categories);
      this.logos = data.logos;
    });

    if (!this.googleLoginService.user) {
      this.authService.authState.subscribe((user) => {
        this.user = user;
        this.fetchLoggedUserData(this.user.email);
        // console.log('logged user email', this.user.email);
        this.googleLoginService.setLoggedInUser(user);
      });
    } else {
      this.user = this.googleLoginService.user;
      this.fetchLoggedUserData(this.user.email);
    }

    this.messages();
    // this.fetchLoggedUserData(this.user.email);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  openLoggedUserDialog(userData: any) {
    const dialogRef: MatDialogRef<LoggedUserProfileComponent> = this.dialog.open(LoggedUserProfileComponent, {
      panelClass: 'custom-modalLoggedUser',
      data: {user: userData}
    });
  }

  fetchLoggedUserData(userEmail: any) {
    this.userProfileService.fetchSearchByEmail(userEmail).subscribe({
      next: response => {
        this.loggedUser = response;
        // console.log('data to build the profile', this.loggedUser);
      }
    });
  }

  messages() {
    this.categoriesService.getMessages().subscribe((homeMessages: HomeMessages[]) => {
      this.homeMessages = homeMessages;
    });
  }

  // Triggered on each resize. Called in OnInit too.
  @HostListener('window:resize', ['$years'])
  _getScreenSize(event?) {
    this.onMobile = window.innerWidth <= 768;
    this.innerWidth = window.innerWidth;
  }

  logOut() {
    this.googleLoginService.logOut();
    this.router.navigate(['/login']);
  }

  openChange(event) {
    // Triggering next to know when nav is opened or closed
    this.utilsService.navOpened.next(event);
  }
}
