import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {BluejCategories} from './models/bluej-categories';
import {BluejCategory} from './models/bluej-category';

@Injectable({
  providedIn: 'root'
})
export class BluejCategoriesService {
  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getAllBluejCategories(): Observable<BluejCategories[]>{
    return this.http.get<BluejCategories[]>(this.url + 'bluej/categories');
  }

  getBluejCat(slug: string): Observable<BluejCategories[]>{
    return this.http.get<BluejCategories[]>(this.url + 'bluej/categories/' + slug);
  }

  getBluejCategoryPosts(slug: string): Observable<BluejCategory[]>{
    return this.http.get<BluejCategory[]>(this.url + 'bluej/' + slug);
  }
}
