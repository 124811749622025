<div class="resource-list-container">
  <span style="background: #E6258B;
          border-radius: 9px;
          color: #E6258B;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 18px">-</span>
  <h5 style="font-weight: bold">Talent Acquisition</h5>
  <div id="content-cont" *ngIf="!isEmpty">
    <div class="row">
      <div class="col-md-12 col-lg-6 col-xl-6 show-data" *ngFor="let resource of talentAcquisitionArray[0].resources">
        <a
          class="resource-container"
          [href]="(
          resource.resource_type === 'pdf' ||
          resource.resource_type === 'xlsx' ||
          resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
          [target]="'_blank'"
        >
          <img style="width: 22%"
            alt="file type"
            [src]="resource.name === 'Hot Jobs!' ? 'assets/nav/new_announcement_icon.png' : resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'"
            [ngStyle]="{
          'background-color': resource.name === 'Hot Jobs!' ? '#E6258B' : 'transparent',
          'border-radius': resource.name === 'Hot Jobs!' ? '50%' : 'null',
          'height': resource.name === 'Hot Jobs!' ? '30px' : 'null',
          'padding': resource.name === 'Hot Jobs!'? '3%' : 'null'
          }"
          />
          <p>{{resource.name}}</p>
        </a>
      </div>
    </div>
  </div>

</div>
