import { Component, OnInit, Input } from '@angular/core';
import {HrCategory} from '../../core/models/hr-category';

@Component({
  selector: 'jz-leave-of-absences',
  templateUrl: './leave-of-absences.component.html',
  styleUrls: ['./leave-of-absences.component.scss']
})
export class LeaveOfAbsencesComponent implements OnInit {

  @Input()  policiesAndForms: HrCategory[];
  isEmpty: boolean;

  constructor() { }

  ngOnInit(): void {
    this.ifEmpty();
  }

  ifEmpty() {
    if (this.policiesAndForms.length) {
      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }
  }

}
