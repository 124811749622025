import {Component, OnInit, ViewChild} from '@angular/core';
import {FetchingDataService} from './services/fetching-data.service';
import {Employee} from './models/employee';
import {MatDialog} from '@angular/material/dialog';
import {ProfileModalComponent} from './components/profile-modal/profile-modal.component';

@Component({
  selector: 'jz-new-org-chart',
  templateUrl: './new-org-chart.component.html',
  styleUrls: ['./new-org-chart.component.scss']
})
export class NewOrgChartComponent implements OnInit {
  errorMessage = '';
  ceoDataArray: Employee;
  @ViewChild('scrollMe') scrollAction: any;

  // boolean variable to use when search submitted
  searchWasSubmitted = false;
  searchResultsArray: [];

  constructor(private fetchDataServices: FetchingDataService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.fetchDataFromApi();
  }

  // method to clear the search array and get back to main array of data
  clearSearch(): void {
    this.searchWasSubmitted = false;
  }

  // open dialog for filters
  openDialog(): void {
    const dialogRef = this.dialog.open(ProfileModalComponent, {
      panelClass: 'custom-modalFilteringBox'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.searchWasSubmitted = true;
        this.searchResultsArray = result.hierarchy;
      }
    });
  }

//  private methods
  private fetchDataFromApi() {
    this.fetchDataServices.fetchData().subscribe({
      next: response => {
        this.ceoDataArray = response;
      }, error: err => this.errorMessage = err
    });
  }
}
