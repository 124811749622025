<div class="right-content">
  <div class="block-cont-style">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <span class="under-span">
        Under Construction
      </span>
      <span class="sorry-span">
        We are sorry for the inconvenience, but we are working to improve this experience.
      </span>
      <img src="assets/Error-Jazmanian.jpg" class="error-image" alt="error image">
      <a href="https://ew43.ultipro.com/Login.aspx" class="link-button" target="_blank">Until then, visit our UKG site</a>
    </div>
  </div>
</div>
