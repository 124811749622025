import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'day'
})
export class DayPipe implements PipeTransform {

  transform(value: string): string {
    const date = value.split('-');
    const day = date[2].split('T');

    return day[0];
  }

}
