import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgChartComponent } from './org-chart/org-chart.component';
import {EntityComponent} from './entity/entity.component';
import {OrgChartZoomEffectDirective} from './directives/org-chart-zoom-efffect.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [OrgChartComponent, EntityComponent, OrgChartZoomEffectDirective],
  exports: [
    OrgChartComponent,
    OrgChartZoomEffectDirective,
  ],
})
export class OrgChartModule {}
