import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Recognition} from '../models/employee';
import {EmployeeRecogService} from '../employee-recog.service';

@Injectable()

export class EmployeeRecogDetailResolver implements Resolve<Recognition> {
  constructor(
    private employeeRecogService: EmployeeRecogService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Recognition> | Promise<Recognition> | Recognition {
    return this.employeeRecogService.getEmployee(route.params.id);
  }
}
