<div id="detail">
  <div id="detail-header">
    <img id="logo" alt="jtank logo" src="assets/bluej/contest/jtank-white-logo.png"/>
    <p>{{idea.title}}</p>
  </div>

  <div id="back-button">
    <button id="back-btn" (click)="goBack()">
      <i class="fas fa-caret-left"></i>
      Back
    </button>
  </div>

  <div id="detail-container" class="row">
    <div id="image-section"
         [ngClass]="{'pdf-class': isPdf}"
         (click)="openIfPdf(idea.file_content)"
         class="col-12 col-md-6"
    >
      <img
        id="selected-img"
        alt="preview image"
        [src]="isPdf ? 'assets/bluej/contest/PDF_Icon.png' : idea.file_content"
        [ngStyle]="{'width': isPdf ? '50%' : '100%'}"
      />
    </div>

    <div id="info-section" class="col-12 col-md-6">
      <h2>{{idea.title}}</h2>
      <p>{{idea.category}}</p>

      <p>{{idea.description}}</p>

      <h4>Vote Here</h4>
      <div id="like-dislike-section">
        <button
          [disabled]="(idea.user_opinion === 'yes' || idea.user_opinion === 'no') || voted || !isAvailableToVote"
          id="like-btn"
          (click)="setOpinion('yes')"
          [ngStyle]="{
            'background-color': idea.user_opinion === 'Not yet' ?
              (likeSelected === true ? 'green' : 'rgb(239, 239, 239)') :
              (idea.user_opinion === 'yes' ? 'green' : 'rgb(239, 239, 239)')
          }"
        >
          <img
            *ngIf="(idea.user_opinion === 'Not yet' && likeSelected === false) || idea.user_opinion === 'no'"
            alt="like button"
            src="assets/bluej/contest/like.png"
          />
          <img
            *ngIf="idea.user_opinion === 'yes' || likeSelected === true"
            alt="like button"
            src="assets/bluej/contest/white-like.png"
          />
        </button>

        <div
          class="likes-count-container"
          *ngIf="idea.num_yes !== 'forbidden'"
        >
          <p>{{idea.num_yes}}</p>
        </div>

        <button
          [disabled]="(idea.user_opinion === 'yes' || idea.user_opinion === 'no') || voted || !isAvailableToVote"
          id="dislike-btn"
          (click)="setOpinion('no')"
          [ngStyle]="{
            'background-color': idea.user_opinion === 'Not yet' ?
            (dislikeSelected === true ? 'red' : 'rgb(239, 239, 239)') :
            idea.user_opinion === 'no' ? 'red' : 'rgb(239, 239, 239)'
          }"
        >
          <img
            *ngIf="(idea.user_opinion === 'Not yet' && dislikeSelected === false) || idea.user_opinion === 'yes'"
            alt="dislike button"
            src="assets/bluej/contest/dislike.png"
          />
          <img
            *ngIf="idea.user_opinion === 'no' || dislikeSelected === true"
            alt="dislike button"
            src="assets/bluej/contest/white-dislike.png"
          />
        </button>

        <div
          class="likes-count-container"
          *ngIf="idea.num_no !== 'forbidden'"
        >
          <p>{{idea.num_no}}</p>
        </div>
      </div>

      <div
        class="alert alert-warning"
        role="alert"
        style="margin-top: 20px;"
        *ngIf="idea.user_opinion === 'Not yet' && !isAvailableToVote"
      >
        <p style="margin-bottom: 0;">You have reached the maximum number of votes allowed</p>
      </div>
    </div>
  </div>
</div>
