import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IeventDetail} from '../models/event-detail';
import {EventService} from '../event.service';

@Injectable()

export class EventDetailResolver implements Resolve<IeventDetail> {
  constructor(
    private eventService: EventService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<IeventDetail> | Promise<IeventDetail> | IeventDetail {
    return this.eventService.getEventDetails(+route.params.id);
  }
}
