<div id="main-section">
  <!-- back button -->
  <div class="container-fluid button-container" (click)="goBack()">
    <i class="fas fa-caret-left" style="color: #FFFFFF;"></i>
    <button>
      Back
    </button>
  </div>
  <div class="container-fluid" style="margin-top: 20px">
    <div class="row" style="background: #F4F4F4; border-radius: 20px">
      <div class="col-md-12">
        <span style="background: #E6258B;
                    border-radius: 20px;
                    color: #E6258B;
                    position: absolute;
                    left: -9px;
                    width: 26px;
                    height: 14px;
                    top: 28px">-</span>
        <h5>{{eventDetail.name}}</h5>
        <div class="item-block d-flex flex-row flex-wrap justify-content-center gallery-wrapper">
          <img *ngFor="let url of eventDetail.contents let i = index"
               (click)="openModal(i)"
               src="{{url.display_image}}"
               alt="Event image"
               class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</div>
