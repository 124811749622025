import { Component, OnInit, Input } from '@angular/core';
import {HrCategory} from '../../core/models/hr-category';

@Component({
  selector: 'jz-talent-acquisition',
  templateUrl: './talent-acquisition.component.html',
  styleUrls: ['./talent-acquisition.component.scss']
})
export class TalentAcquisitionComponent implements OnInit {

  @Input()  talentAcquisitionArray: HrCategory[];
  isEmpty: boolean;

  constructor() { }

  ngOnInit(): void {
  this.ifEmpty();
  }

  ifEmpty() {
    if (this.talentAcquisitionArray.length) {
      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }
  }

}
