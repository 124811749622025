<div class="resource-list-container h-100">
  <span style="background: #64CC44;
          border-radius: 9px;
          color: #64CC44;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 18px">-</span>
  <h5 style="font-weight: bold">Payroll</h5>
  <div id="content-cont" *ngIf="!isEmpty">
    <div class="row">
      <div class="col-md-12 col-lg-6 col-xl-6 show-data" *ngFor="let resource of payrollArray[0].resources">
        <a
          class="resource-container"
          [href]="(
       resource.resource_type === 'pdf' ||
       resource.resource_type === 'xlsx' ||
       resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
          [target]="'_blank'"
          style="text-decoration: none; color: black;"
        >
          <img style="width: 22%"
            alt="file type"
            [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'"
          />
          <p>{{resource.name}}</p>
        </a>
      </div>
    </div>

  </div>
</div>
