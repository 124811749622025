import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IdeaService} from '../idea.service';
import {VoteCount} from '../models/vote-count';

@Injectable()
export class VoteCountResolver implements Resolve<VoteCount> {

  constructor(
    private ideaService: IdeaService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<VoteCount> | Promise<VoteCount> | VoteCount {
    return this.ideaService.getNumberOfVotes();
  }
}
