import {Component, OnInit, Inject, HostListener} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ResourceCategory} from '../../core/models/resource';

@Component({
  selector: 'jz-resource-dialog',
  templateUrl: './resource-dialog.component.html',
  styleUrls: ['./resource-dialog.component.scss']
})
export class ResourceDialogComponent implements OnInit {
  resourceCat: ResourceCategory;
  innerWidth = window.innerWidth;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ResourceCategory) {
  }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    console.log(this.data);
    this.resourceCat = this.data;
  }
}
