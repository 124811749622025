<div id="side-bar">
  <ul class="master-list">
<!--    <li class="jnav-link" id="j_search"-->
<!--        [routerLink]="'/global-search'">-->
<!--      <div class="icon-container" id="j_search_img">-->
<!--        <img class="category-image"-->
<!--             src="assets/nav/search-icon.png"-->
<!--             alt="category-logo"-->
<!--        />-->
<!--      </div>-->
<!--      <h6 class="category-name" id="j_search_text">J Search</h6>-->
<!--    </li>-->
    <li
      *ngFor="let category of navigationTabs"
      class="{{category.name}} jnav-link"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: true}" #rla="routerLinkActive"
      (mouseover)="hover=true;categoryName=category.name"
      (mouseleave)="hover=false;"
      [ngStyle]="{
                  'background-color': rla.isActive || (hover && categoryName === category.name) ? category.color : '',
                  'color': rla.isActive || (hover && categoryName === category.name) ? '#ffffff' : '',
                  'border-radius': rla.isActive || (hover && categoryName === category.name) ? '50px' : ''
                  }"
      (click)="handleOnTouch()"
    >
      <!--MenuIcon-->
      <div class="icon-container"
        [ngStyle]="{'background-color': rla.isActive || (hover && categoryName === category.name) ? category.color : ''}"
      >
        <img
          class="category-image"
          [src]="rla.isActive || (hover && categoryName === category.name) ? category.second_img ? category.second_img : '../../assets/IMG_3985.JPG' : category.icon_img"
          alt="Category logo"
        />
      </div>
      <!--MenuName if not Employee Corner-->
      <p *ngIf="category.display_name !== 'Employee Corner'" [routerLink]="['/', category.name]"
         [ngStyle]="{'color': rla.isActive || (hover && categoryName === category.name) ? '#ffffff' : '#707070'}"
         class="category-name menu-item-d-name">{{category.display_name}}
      </p>
      <!--MenuName if Employee Corner-->
      <div *ngIf="category.display_name === 'Employee Corner'"
           (mouseleave)="leavingDropDown();" class="btn-group" dropdown #dropdown="bs-dropdown"
           [autoClose]="true"
           [(isOpen)]="isSubMenuOpen">
        <button id="submenu-drop" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic"
                [ngStyle]="{'color': rla.isActive || (hover && categoryName === category.name) ? '#ffffff' : '#707070'}"
        >
          {{category.display_name}} <span class="caret"></span>
        </button>
        <ul id="submenu-ul" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic"
            [style.backgroundColor]="category.color"
            (mouseenter)="isSubMenuHovered=true;isSubMenuOpen=true;"
            (mouseleave)="dropdown.hide();isSubMenuHovered=false;">
          <li role="menuitem"><a class="dropdown-item" style="font-weight: bold" [routerLink]="['/fairness']">Fairness, Inclusion &<br/> Tolerance</a></li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem"><a class="dropdown-item" style="font-weight: bold" [routerLink]="['/keep-in-touch']">Jazmanians</a>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem"><a class="dropdown-item" style="font-weight: bold" href="https://jazwaresmerch.com/" target="_blank">J Merch Store</a></li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem"><a class="dropdown-item" style="font-weight: bold" [routerLink]="['/discounts']">J Partners</a></li>
          <li class="divider dropdown-divider"></li>
<!--          <li role="menuitem"><a class="dropdown-item" style="font-weight: bold" [routerLink]="['/jeducation']">J Resources</a></li>-->
<!--          <li class="divider dropdown-divider"></li>-->
          <li role="menuitem"><a class="dropdown-item" style="font-weight: bold" [routerLink]="['/jfit']">J Fit</a></li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem"><a class="dropdown-item" style="font-weight: bold" [routerLink]="['/jwellness']">J Wellness</a></li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem"><a class="dropdown-item" style="font-weight: bold" [routerLink]="['/human-resources/learning-hub']">Learning Hub</a>
          </li>
        </ul>
      </div>
    </li>
  </ul>

  <div class="social-section">
    <ul routerLink="/social">
      <li>
        <a><img src="assets/nav/social/fb.png" alt="facebook logo" width="39"></a>
      </li>
      <li>
        <a><img src="assets/nav/social/ig.png" alt="instagram logo" width="39"></a>
      </li>
      <li>
        <a><img src="assets/nav/social/tw.png" alt="twitter logo" width="40"></a>
      </li>
      <li>
        <a><img src="assets/nav/social/yt.png"  alt="youtube logo" width="38"></a>
      </li>
    </ul>
    <p class="footer-text text-center">fueled by: <a href="https://www.jazwares.com/" target="_blank"><img src="assets/logo-Jazwares.png" width="125" alt="jazwares logo"></a></p>
  </div>

</div>


