<div class="white-block">
  <div class="main-cont"
       [ngStyle]="{'transform' : noZoomEffect ? '' : !noZoomEffect && zoomIn ? 'scale(1.05)' : !noZoomEffect && zoomOut ? 'scale(0.6)' : '',
                   'transform-origin' : !noZoomEffect && !zoomIn ? 'left' : ''
       }"
  >
    <jz-org-chart-entity
      *ngIf="data"
      [data]="data"
      [user]="user"
      [searchParams]="searchParams"
      [hasParent]="hasParent"
      (toggleChild)="toggleShowChild($event)"
      (modalEmail)="emitModalEmail($event)"
      [hideChild]="hideChild">
      </jz-org-chart-entity>

    <div class="org-chart-container" [style.display]="hideChild ? 'none' : 'flex'">
      <ng-container *ngFor="let data of data?.children; first as isFirst; last as isLast">
        <div class="org-chart-sub-container">
          <div class="org-chart-connector-container">
            <div class="org-chart-connector org-chart-entity-border" [style.border-color]="isFirst?'transparent':''"></div>
            <div class="org-chart-entity-border"></div>
            <div class="org-chart-connector org-chart-entity-border" [style.border-color]="isLast?'transparent':''"></div>
          </div>
          <jz-angular-org-chart [searchParams]="searchParams" [user]="user" [head]="head" [data]="data" [hasParent]="true"></jz-angular-org-chart>
        </div>
      </ng-container>
    </div>
  </div>
</div>
