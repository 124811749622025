import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Weather} from './models/weather';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  url = 'https://api.openweathermap.org/data/2.5/weather';
  API_KEY = '169c3d42a83360704e229d2fdc718f49';
  constructor(private http: HttpClient) { }

  getWeatherDataByCityName(city: string): Observable<Weather> {
    let params = new HttpParams();
    params = params.set('q', city);
    params = params.set('units', 'imperial');
    params = params.set('appid', this.API_KEY);

    return this.http.get<Weather>(this.url, {params});
  }
}
