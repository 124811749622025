import { Component, OnInit } from '@angular/core';
import {HrTeamStaff} from '../../core/models/hrTeam-staff';
import {HrTeamListService} from '../../core/hr-team-list.service';
import {Employee} from '../../new-org-chart/models/employee';
import {FetchingDataService} from '../../new-org-chart/services/fetching-data.service';

@Component({
  selector: 'jz-hr-team-list',
  templateUrl: './hr-team-list.component.html',
  styleUrls: ['./hr-team-list.component.scss']
})
export class HrTeamListComponent implements OnInit {
  staff: HrTeamStaff[];
  errorMessage = '';
  staffNew: Employee[];

  constructor(
    private hrTeamListService: HrTeamListService, private fetchingDataService: FetchingDataService
  ) { }

  ngOnInit(): void {
    this.fetchNewStaff();
  }

  private fetchNewStaff() {
    this.fetchingDataService.fetchAllEmployeesByDepartment('human resources').subscribe({
      next: response => {
        this.staffNew = response;
      },
      error: err => this.errorMessage = err
    });
  }

  // private fetchStaff() {
  //   this.hrTeamListService.getHrTeamProfiles().subscribe({
  //     next: response => {
  //       this.staff = response;
  //       // console.log(this.staff);
  //     },
  //     error: err => this.errorMessage = err
  //   });
  // }

}
