import { Component, OnInit } from '@angular/core';
import {ICar} from '../core/models/jpark';
import {ActivatedRoute} from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'jz-jpark',
  templateUrl: './jpark.component.html',
  styleUrls: ['./jpark.component.scss']
})
export class JparkComponent implements OnInit {
  cars: ICar[];
  carsCopy: ICar[];
  decalNumber: any = '';
  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.cars = data.allCars;
      this.carsCopy = this.cars;
    });
  }
  onKey(value: any) {
    this.decalNumber = value;
    if (this.decalNumber === ''){
      this.carsCopy = this.cars;
    }
     // if decalNumber is not a number
     else if (isNaN(this.decalNumber)){
      this.carsCopy = this.cars.filter((res: ICar) => {
        return res.make.toLowerCase().match(this.decalNumber.toString().toLowerCase());
      });
    }
    // if decalNumber is a number
    else {
      this.carsCopy = this.cars.filter((res: ICar) => {
        return res.decal.toString().match(this.decalNumber);
      });
    }
  }
}
