<main class="modal-alert-cont">
  <div class="div-cont d-flex flex-column">
    <span class="close-modal" (click)="closeAlertModal()">X</span>
    <p class="alert-info-text">Use of this Fun Facts application is purely voluntary and intended to help employees make meaningful connections
      with their colleagues. All content provided must be professional and respectful. By accessing and using this
      organizational chart feature, you are agreeing to use this application in accordance with Jazwares Use of Company
      Equipment and Resources policy. As such, any prohibited material is strictly prohibited. Prohibited material
      includes, but is not limited to, sexual comments, pornographic material, jokes or images, racial slurs,
      gender-specific comments, or any comments, jokes or images that would discriminate against or harass someone on the
      basis of their race, color, sex, age, national origin or ancestry, religion, disability, or any other category
      protected by federal, state or local law. If you believe that any content included in the Fun Facts application is
      prohibited, please contact Human Resources (link). Any content that in Jazwares’ sole discretion violates this
      policy will be immediately taken down and any violators may be subject to discipline, which may include but is not
      limited to termination of employment.</p>
  </div>

</main>

