import { Component, OnInit } from '@angular/core';
import {QuickLinksService} from '../../core/quick-links.service';


@Component({
  selector: 'jz-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit {

  quickLinks = [];
  errorMessage = '';
  hover: boolean;
  linkTitle: string;

  constructor(
    private quickLinksService: QuickLinksService
  ) { }

  ngOnInit(): void {
    this.fetchAllQuickLinks();
  }

  // private functions
  private fetchAllQuickLinks() {
    this.quickLinksService.getQuickLinks().subscribe({
      next: response => {
        this.quickLinks = response;
      },
      error: err => this.errorMessage = err
    });
  }

}
