<div id="right-content">

  <div id="main-area">

    <div class="container-fluid">

      <span style="background: #54C8E8;
          border-radius: 20px;
          color: #54C8E8;
          position: absolute;
          left: 14px;
          width: 26px;
          height: 14px;
          top: 53px">-</span>

      <div class="row">
        <div class="col-12" id="social-header">
          <p class="social-header-text">Get on Social!</p>
          <p class="social-submenu">Follow Jazwares on social media and you won’t miss out on any of the fun! From toy teasers and reveals, to awesome artistic photography and toy videos, there is always something brewing on Jazwares’ social channels!</p>
          <p style="font-size: 16px">Follow, like, retweet, share and spread the Jazwares love with all of your friends, family and acquaintances! Click on the icons below to follow and share.</p>
          <p style="font-size: 16px">Please review our <a
            href="https://docs.google.com/document/d/1Y4zR70zLkyLONKRBnoAgveN1QZS9ZLXW/edit?usp=sharing&ouid=111054512828210464058&rtpof=true&sd=true"
            target="_blank">Social Media Policy</a>
            prior to posting or sharing content.
          </p>
          <p class="info-text" style="font-weight: bold">Click on each & Scroll down</p>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngFor="let account of accounts" style="margin-bottom: 20px">
          <div class="account-container d-flex flex-row justify-content-between align-items-center">
            <span class="acc-name">{{account.name}}</span>
            <div>
              <a *ngIf="account.facebook_link.length" [href]="(account.facebook_link)" target="_blank">
                <img src="assets/nav/social/fb.png" alt="logo">
              </a>
              <a *ngIf="account.twitter_link.length" [href]="(account.twitter_link)" target="_blank">
                <img src="assets/nav/social/tw.png" alt="logo">
              </a>
              <a *ngIf="account.instagram_link.length" [href]="(account.instagram_link)" target="_blank">
                <img src="assets/nav/social/ig.png" alt="logo">
              </a>
              <a *ngIf="account.youtube_link.length" [href]="account.youtube_link" target="_blank">
                <img src="assets/nav/social/yt.png" alt="logo">
              </a>
              <a *ngIf="account.linkedin_link.length" [href]="account.linkedin_link" target="_blank">
                <img src="assets/nav/social/linkedln_logo.png" alt="logo">
              </a>
              <a *ngIf="account.tiktok_link.length" [href]="account.tiktok_link" target="_blank">
                <img src="assets/nav/social/tiktok.jpg" alt="logo">
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


