<div id="right-content">
  <div id="top-bar">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h4><img src="../../../assets/jtank/jtank.png" width="100">
            <div class="line-separator"></div>
            Vote
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div id="back-btn-route">
      <button (click)="navigateBack()">
        <i class="fas fa-caret-left"></i>
        Back
      </button>
    </div>
  </div>
  <!--  cards-->
  <div class="container">
    <div *ngIf="(!contest || (!contest.ideas_active && contest.is_active)) && ideas[0].num_yes === 'forbidden'" class="d-flex flex-column align-items-center p-3">
      <img style="margin: auto;" alt="End voting period" src="assets/bluej/contest/voting-period.png"/>
      <p id="empty-state-graph-vote">The Voting Period is not Available</p>
    </div>
    <div class="row" *ngIf="(contest && contest.ideas_active && contest.is_active) || ideas[0].num_yes !== 'forbidden'">
      <div class="col-lg-4 col-md-6" *ngFor="let idea of ideas" style="cursor: pointer;">
        <div class="card-style" (click)="setDetailAndNavigate(idea)" [routerLink]="[idea.pk]">
          <div class="card-img">
            <img
              alt="card image"
              [src]="idea.file_content ? (idea.file_content.slice(idea.file_content.length - 3, idea.file_content.length) === 'pdf' ? 'assets/bluej/contest/PDF_Icon.png' : idea.file_content): 'assets/bluej/contest/PDF_Icon.png'"
              class="img-fluid mx-auto d-block"
            >
          </div>
          <div class="card-content">
            <h3>{{idea.title}}</h3>
            <span>{{idea.category}}</span>
            <p>{{idea.description | truncate:98}}</p>
            <div class="d-flex justify-content-center align-items-center">
              <button
                [disabled]="idea.user_opinion !== 'Not yet' || voted[idea.pk] || !isAllowedToVote"
                class="thumb-button"
                [ngStyle]="{
                  'background-color': idea.user_opinion === 'Not yet' ?
                  (votedUpIdeas[idea.pk] ? 'green' : 'white') :
                  idea.user_opinion === 'no' ? 'white' : 'green'
                }"
                (click)="setOpinion('yes', idea, $event)"
              >
                <img
                  *ngIf="(idea.user_opinion === 'Not yet' && !votedUpIdeas[idea.pk]) || idea.user_opinion === 'no'"
                  alt="like button"
                  src="assets/bluej/contest/like.png"
                />
                <img
                  *ngIf="idea.user_opinion === 'yes' || votedUpIdeas[idea.pk]"
                  alt="white like button"
                  src="assets/bluej/contest/white-like.png"
                />
              </button>

              <div
                class="likes-count-container"
                *ngIf="idea.num_yes !== 'forbidden'"
              >
                <p>{{idea.num_yes}}</p>
              </div>

              <button
                [disabled]="idea.user_opinion !== 'Not yet' || voted[idea.pk] || !isAllowedToVote"
                class="thumb-button"
                style="margin-left: 10px;"
                [ngStyle]="{
                  'background-color': idea.user_opinion === 'Not yet' ?
                  (votedDownIdeas[idea.pk] ? 'red' : 'white') :
                  idea.user_opinion === 'yes' ? 'white' : 'red'
                }"
                (click)="setOpinion('no', idea, $event)"
              >
                <img
                  *ngIf="(idea.user_opinion === 'Not yet' && !votedDownIdeas[idea.pk]) || idea.user_opinion === 'yes'"
                  alt="dislike button"
                  src="assets/bluej/contest/dislike.png"
                />
                <img
                  *ngIf="idea.user_opinion === 'no' || votedDownIdeas[idea.pk]"
                  alt="white dislike button"
                  src="assets/bluej/contest/white-dislike.png"
                />
              </button>

              <div
                class="likes-count-container"
                *ngIf="idea.num_no !== 'forbidden'"
              >
                <p>{{idea.num_no}}</p>
              </div>
            </div>

            <div
              class="alert alert-warning message-container"
              *ngIf="!isAllowedToVote && idea.user_opinion === 'Not yet'"
            >
              <p style="margin-bottom: 0;">You have reached the maximum number of votes allowed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
