import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {QuickLinks} from './models/quick-links';

@Injectable({
  providedIn: 'root'
})
export class QuickLinksService {
  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getQuickLinks(): Observable<QuickLinks[]>{
    return this.http.get<QuickLinks[]>(this.url + 'resources2/quick_links');
  }
}
