import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PaginatedPosts2ListResponse} from './models/paginatedPosts2ListResponse';
import {IResponse} from './models/response';
import {Post} from './models/post';

@Injectable({
  providedIn: 'root'
})
export class Posts2Service {
  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  // for HR carousel
  getPosts2ByCat(cat: string ): Observable<PaginatedPosts2ListResponse[]> {
    return this.http.get<PaginatedPosts2ListResponse[]>(this.url + 'posts2/get?app=' + cat + '&model=post');
  }

  // POSTS2
  getPosts2Category(cat: string): Observable<PaginatedPosts2ListResponse[]> {
    return this.http.get<PaginatedPosts2ListResponse[]>(this.url + 'posts2/get?app=posts2&model=category&category=' + cat);
  }

  // POSTS2 - paginated
  getPosts2PaginatedByCategory(cat: string, page: number): Observable<PaginatedPosts2ListResponse> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<PaginatedPosts2ListResponse>(this.url + 'posts2/get_paginate?app=posts2&model=category&category=' + cat + '&page=' + page);
  }

  // new service to search by Posts per the global search request
  getPostsBySearch(filterBy: string, page?: number): Observable<IResponse<Post>> {
    if (filterBy) {
      return this.http.get<IResponse<Post>>(this.url + 'posts2/all?search=' + filterBy + (page ? '&page=' + page : ''));
    }
  }

}
