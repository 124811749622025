import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {ActivatedRoute} from '@angular/router';
import {IeventDetail} from '../../core/models/event-detail';
import {Location} from '@angular/common';

@Component({
  selector: 'jz-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  modalRef?: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private location: Location
  ) {
  }

  eventDetail: IeventDetail;

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.eventDetail = data.eventDetail;
    });
    // console.log(this.eventDetail);
  }

  // public functions
  goBack(): void {
    this.location.back();
  }

  openModal(idx) {
    const initialState: ModalOptions = {
      initialState: {
        event: this.eventDetail,
        index: idx,
      }
    };
    this.modalRef = this.modalService.show(GalleryModalComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
  }

}


@Component({
  selector: 'jz-gallery-modal',
  template: `
    <div class="modal-body">
      <carousel [(activeSlide)]="activeSlideIndex">
        <div>
          <div>
            <slide *ngFor="let url of event.contents">
              <img src="{{url.display_image}}" alt="first slide" style="display: block; width: 100%;">
            </slide>
          </div>
        </div>
      </carousel>
    </div>
  `
})

export class GalleryModalComponent implements OnInit {
  index: number;
  closeBtnName?: string;
  event: IeventDetail;
  activeSlideIndex: number;

  constructor(
    public bsModalRef: BsModalRef,
    private location: Location
  ) {}

  ngOnInit() {
    this.initActiveSlide();
  }

  // public functions
  goBack(): void {
    this.location.back();
  }

  // work around to issue here https://github.com/valor-software/ngx-bootstrap/issues/2533
  initActiveSlide() {
    setTimeout(() => {
      this.activeSlideIndex = this.index;
    }, 0);
  }
}
