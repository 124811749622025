<section>

  <!--divider-->
  <div class="d-flex justify-content-center align-items-center">
    <jz-divider></jz-divider>
  </div>

  <!--node looping-->
  <div class="children-cont container d-flex flex-row flex-wrap justify-content-center align-items-center">

    <!--collapse button-->
    <div *ngIf="isMobile > 500" class="collapse-btn-custom d-flex flex-row justify-content-center align-items-center"
         (click)="toggleCollapse()">
      <span class="collapse-text-custom">{{isCollapsed ? 'Expand' : 'Collapse'}}</span>
      <div *ngIf="!isCollapsed" class="rectangle-16"></div>
      <div *ngIf="isCollapsed" class="rectangle-17">+</div>
    </div>

    <!--node card-->
    <div *ngFor="let element of data">
      <jz-node-card [id]="element" [toggle]="isCollapsed" (newItemEvent)="checkDataFromChildComp($event)"
                    [activeState]="activeNodeStateChanges" [numberId]="activeNodeNumberId"
                    (newActiveEvent)="checkActiveStatusChangeFromChildComp($event)">
      </jz-node-card>
    </div>
  </div>

</section>

<!--when click in an employee node-->
<div *ngIf="expanded">
  <jz-hierarchy-container [data]="mockData"></jz-hierarchy-container>
</div>
