import { Component, OnInit } from '@angular/core';
import {Post} from '../core/models/post';
import {CategoriesService} from '../core/categories.service';
import {PostsService} from '../core/posts.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'jz-kids-corner',
  templateUrl: './kids-corner.component.html',
  styleUrls: ['./kids-corner.component.scss']
})
export class KidsCornerComponent implements OnInit {

  posts: Post[];
  constructor(
    private categoriesService: CategoriesService,
    private postsService: PostsService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    if (this.categoriesService.categories['kids_corner']) {
      this.postsService.getPosts(this.categoriesService.categories['kids_corner'])
        .subscribe((data: Post[]) => {
          this.posts = data;
          this.spinner.hide();
        });
    } else {
      this.categoriesService.getCategories().subscribe((data) => {
        this.categoriesService.setCategories(data);
        this.postsService.getPosts(this.categoriesService.categories['kids_corner'])
          .subscribe((postData: Post[]) => {
            this.posts = postData;
            this.spinner.hide();
          });
      });
    }
  }

}
