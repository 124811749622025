import { Component, OnInit } from '@angular/core';
import {Post} from '../core/models/post';
import {CategoriesService} from '../core/categories.service';
import {PostsService} from '../core/posts.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'jz-bluej',
  templateUrl: './bluej.component.html',
  styleUrls: ['./bluej.component.scss']
})
export class BluejComponent implements OnInit {
  posts: Post[];
  constructor(
    private categoriesService: CategoriesService,
    private postsService: PostsService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.postsService.getPosts(this.categoriesService.categories['bluej'])
      .subscribe((data: Post[]) => {
        this.posts = data;
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
      });
  }

}
