import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PostsService} from '../core/posts.service';
import {ActivatedRoute} from '@angular/router';
import {HrCategory} from '../core/models/hr-category';
import {HrTeamStaff} from '../core/models/hrTeam-staff';
import {HrTeamListService} from '../core/hr-team-list.service';
import {Posts2} from '../core/models/paginatedPosts2ListResponse';
import {Posts2Service} from '../core/posts2.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {FetchingDataService} from '../new-org-chart/services/fetching-data.service';
import {Employee} from '../new-org-chart/models/employee';

@Component({
  selector: 'jz-human-resources',
  templateUrl: './human-resources.component.html',
  styleUrls: ['./human-resources.component.scss']
})
export class HumanResourcesComponent implements OnInit, AfterViewInit {
  cat = 'HR';
  errorMessage;
  hrCategories: HrCategory[];
  benefitsArray: HrCategory[];
  talentAcquisitionArray: HrCategory[];
  performanceArray: HrCategory[];
  payrollArray: HrCategory[];
  policiesAndForms: HrCategory[];
  staff: HrTeamStaff[];
  posts2List: Posts2[];
  dataLoaded = false;

  // for accordion
  panelOpenState = false;

  staffNew: Employee[];

  @ViewChild('hrContainer') hrContainer: ElementRef;

  constructor(
    private postsService: PostsService,
    private route: ActivatedRoute,
    private hrTeamListService: HrTeamListService,
    private posts2Service: Posts2Service,
    private spinner: NgxSpinnerService,
    private fetchingDataService: FetchingDataService,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe((data: { hrCategories: HrCategory[] }) => {
      this.hrCategories = data.hrCategories;
    });
    this.fetchData();
    this.fetchStaff();
    this.fetchPosts2();
    this.fetchHRStaff();
  }

  ngAfterViewInit() {
    // ElementRef { nativeElement: <input> }
    // console.log('height', this.hrContainer.nativeElement.style.height);
  }

  private fetchHRStaff() {
    this.fetchingDataService.fetchAllEmployeesByDepartment('human resources').subscribe({
      next: response => {
        this.staffNew = response;
      }
    });
  }

  // private functions below
  private fetchData() {
    this.spinner.show();
    this.talentAcquisitionArray = this.hrCategories.filter(hrCategory => hrCategory.cat === 'Talent Acquisition');
    this.performanceArray = this.hrCategories.filter(hrCategory => hrCategory.cat === 'Performance Management');
    this.payrollArray = this.hrCategories.filter(hrCategory => hrCategory.cat === 'Payroll');
    this.policiesAndForms = this.hrCategories.filter(hrCategory => hrCategory.cat === 'Policies and Forms');
    this.benefitsArray = this.hrCategories.filter(hrCategory => hrCategory.cat === 'Benefits');
    this.dataLoaded = true;
    this.spinner.hide();
  }

  private fetchStaff() {
    this.hrTeamListService.getHrTeamProfiles().subscribe({
      next: response => {
        this.staff = response;
      },
      error: err => this.errorMessage = err
    });
  }

  private fetchPosts2() {
    this.posts2Service.getPosts2ByCat(this.cat).subscribe({
      next: response => {
        if (response.length) {
          this.posts2List = response[0].posts;
        }
      },
      error: err => this.errorMessage = err
    });
  }

}


