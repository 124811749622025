import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FetchingDataService} from '../../services/fetching-data.service';
import {Employee} from '../../models/employee';

@Component({
  selector: 'jz-peers-node',
  templateUrl: './peers-node.component.html',
  styleUrls: ['./peers-node.component.scss']
})
export class PeersNodeComponent implements OnInit {
  @Input() elemNumber: string;
  @Input() color: string;
  @Input() fontColor: string;
  person: Employee;

  // passing data to peers-list comp after a click in the node (peers-node comp)
  @Output() newTest = new EventEmitter<string>();

  constructor(private fetchDataService: FetchingDataService) { }

  ngOnInit(): void {
    this.fetchData(this.elemNumber);
  }

  addNewTest(value: string, managerNumber: string) {
    this.newTest.emit(value);
    this.fetchData(value);
  }

  fetchData(employeeNumber: string) {
    this.fetchDataService.fetchEmployeePerNumber(employeeNumber).subscribe({
      next: response => {
        this.person = response;
      }
    });
  }

}
