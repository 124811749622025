import {Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'jz-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent implements OnInit, OnChanges {
  @Input() data: [];
  @Input() color: string;
  reportsArray: [];
  @Input() newManagesArrayIf: [];

  // new variable to store new person number coming from peers-node comp
  newPersonNumber: string;
  newTest: string;
  // passing data to right-menu comp after receiving data from node (peers-node comp)
  @Output() newTestValue = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    this.reportsArray = this.data;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.reportsArray = this.data;
  }

  // receiving the new person number from peers-node comp
  addTest(newTestVarFromPeersNode: string) {
    this.newTest = newTestVarFromPeersNode;
    this.passingNewTestValueToRightMenuComp(newTestVarFromPeersNode);
  }

  passingNewTestValueToRightMenuComp(addTest: string) {
    this.newTestValue.emit(addTest);
  }
}
