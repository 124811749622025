<div id="post-list-cont" class="container-fluid post-list-scroll" infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="50"
     (scrolled)="onScroll()"
     [scrollWindow]="false">

  <!-- region Spinner -->
  <ngx-spinner
    bdColor="rgba(51,51,51,0.3)"
    size="medium"
    color="white"
    type="ball-climbing-dot"
    [fullScreen]="false"
    name="spinner2"
  ></ngx-spinner>
  <!-- endregion -->

  <div class="row flex-wrap justify-content-around">
    <div
      class="card-container global-center-card-mobile"
      *ngFor="let post of listOfPosts2"
      [routerLink]="['/posts', post.id]">
      <mat-card class="card-mat">
        <img class="card-image" mat-card-image [src]="post.card_image" alt="card image">
        <mat-card-content style="overflow-y: hidden;">
          <h4 class="post-headline">{{post.headline}}</h4>
          <div class="post-text-content">
            <p>{{post.short_description}}</p>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
