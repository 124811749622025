import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ElementRef } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { OrgData, OrgSearchUsers, OrgSearchOther } from './orgData';
import {AngOrgChartService} from './services/ang-org-chart.service';

@Component({
  selector: 'jz-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart-combined.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class OrgChartComponent implements OnInit {
  orgData: OrgData;
  orgSearchUsers: OrgSearchUsers[];
  orgSearchOther: OrgSearchOther[];
  userModalData: OrgData;
  errorMessage;
  loading = true;
  user = localStorage.getItem('user');
  email = localStorage.getItem('email')
  searchParams = [];
  // tslint:disable-next-line:variable-name
  results_list = [];
  // tslint:disable-next-line:variable-name
  other_results_list = [];
  // new variables for zoom in/out effects
  zoomIn = false;
  zoomOut = false;
  noZoomEffect = true;
  openUserDetailedView = false

  //modal data
  modalImage = ''
  modalPerson = ''
  modalPosition = ''
  modalCompany = ''
  modalDepartment = ''
  modalDivision = ''
  modalEmail = ''
  modalOffice = ''

  head = ''



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private angOrgChartService: AngOrgChartService,
    // tslint:disable-next-line:variable-name
    private _eref: ElementRef,
  ) { }

  ngOnInit() {
    this.fetchOrgChart();
    this.fetchUserList();
    this.fetchOtherList();
    //this.fetchModalData(this.email)
    this.angOrgChartService.childEventListner().subscribe(info =>{
      if(info == true)
      {
        this.openUserDetailedView = info
      } else if (info != false && info != '' ) {
        this.fetchModalData(info)
      }
    })

    // this.angOrgChartService.childLoadingEventListner().subscribe(is_loading =>{
    //   this.loading = is_loading
    // })
  }


  // private functions below
  private fetchOrgChart() {
    this.angOrgChartService.getOrgChart().subscribe({
      next: response => {
        this.orgData = response[0];
        this.loading = false
      },
      error: err => this.errorMessage = err
    });
  }

  private fetchOrgChartbySearch(email) {
    this.loading = true;
    this.angOrgChartService.getOrgChartbySearch(email).subscribe({
      next: response => {
        this.orgData = response[0];
        this.loading = false

      },
      error: err => this.errorMessage = err
    });
  }

  private fetchUserList() {
    this.angOrgChartService.getUserList().subscribe({
      next: response => {
        this.orgSearchUsers = response;
        // console.log(this.orgSearchUsers);
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  }

  private fetchOtherList() {
    this.angOrgChartService.getOtherList().subscribe({
      next: response => {
        this.orgSearchOther = response;
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  }

  private fetchModalData(email) {
    this.angOrgChartService.getUserModalData(email).subscribe({
      next: response => {
        //this.openUserDetailedView = true
        this.userModalData = response[0];
        this.modalImage = this.userModalData['image']
        this.modalPerson = this.userModalData['person']
        this.modalPosition = this.userModalData['position']
        this.modalCompany = this.userModalData['company']
        this.modalDepartment = this.userModalData['department']
        this.modalDivision = this.userModalData['division']
        this.modalEmail = this.userModalData['email']
        this.modalOffice = this.userModalData['office']

      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  }

  private fetchHead(type,name){
    this.angOrgChartService.getHead(type,name).subscribe({
      next: response=>{
        this.head = response[0]['email']

        let names = document.querySelectorAll('.org-chart-entity-name')

        // names.forEach(name=>{
        //   let element = (name as HTMLElement)
        //   let email = element.dataset.email

        //   if(email && this.head){


        //     if(email.toLowerCase() == this.head.toLowerCase()){

        //       element.style.scrollMarginTop = '50px'
        //       element.scrollIntoView({inline:'center',behavior:'smooth',block:'start'});

        //     }
        //   }

        // })
      },
      error: err => this.errorMessage = err
    })
  }

  // public functions below
  onClickZoomIn(): void {
    if (this.noZoomEffect && !this.zoomOut) {
      this.noZoomEffect = !this.noZoomEffect;
      // this.zoomOut = false;
      this.zoomIn = true;
      let modals = document.getElementsByClassName('detailed-user-modal')

      //Array.from(modals).forEach(modal=>{modal.classList.add('display-none')})
    };
    if (!this.noZoomEffect && this.zoomOut) {
      this.zoomOut = false;
      this.noZoomEffect = true;
    }
  }

  onClickZoomOut(): void {
    if (this.noZoomEffect && !this.zoomIn) {
      this.noZoomEffect = !this.noZoomEffect;
      this.zoomOut = true;

      //remove modal on zoom out
      let modals = document.getElementsByClassName('detailed-user-modal')
      Array.from(modals).forEach(modal=>{

        //modal.classList.add('display-none')

        console.log(modal.getBoundingClientRect().right)

    })
      // this.zoomIn = false;
    };
    if (!this.noZoomEffect && this.zoomIn) {

      this.zoomIn = false;
      this.noZoomEffect = true;
    }
  }

  searching(e){
    const results = [];
    // tslint:disable-next-line:variable-name
    const other_results = [];
    this.orgSearchOther.forEach(other => {
      // tslint:disable-next-line:variable-name
      const input_len = e.target.value.length;
      // length is at least 2
      if (input_len >= 2)
      {
        if (e.target.value.toLowerCase() === other['name'].substring(0, input_len).toLowerCase()){
          let name = capitalizeSmallWords(other['name'])
          other_results.push({
            id: other['id'],
            type:other['type'],
            name: name,
          });
        }
        this.other_results_list = other_results
      }
    });

    this.orgSearchUsers.forEach(user => {
      // tslint:disable-next-line:variable-name
      const input_len = e.target.value.length;
      // tslint:disable-next-line:variable-name
      const user_nm = user['first_name'] + ' ' + user['last_name'];
      // length is at least 2
      if (input_len >= 2)
      {
        // find all first names that match
        if (user['first_name'].length >= input_len){
          if (e.target.value.toLowerCase() === user['first_name'].substring(0, input_len).toLowerCase()) {
            results.push({
              id: user['id'],
              user: user_nm,
              email: user['email']
            });
          }
        }

        // find all first names that match
        if (user['last_name'].length >= input_len) {
          if (e.target.value.toLowerCase() === user['last_name'].substring(0, input_len).toLowerCase()) {
            results.push({
              id: user['id'],
              user: user_nm,
              email: user['email']
            });
          }
        }

        // find all full name that match
        if (user['first_name'].length + 1 +user['last_name'].length >= input_len) {
          if (e.target.value.toLowerCase() === user['first_name'].toLowerCase() + ' ' + user['last_name'].substring(0, input_len - user['first_name'].length - 1).toLowerCase()){
            results.push({
              id: user['id'],
              user: user_nm,
              email: user['email']
            });
          }
        }
        this.results_list = results
      }
    });
  }

  clearSearch(e){
    this.results_list = [];
    this.other_results_list = [];
    e.target.parentElement.querySelector('input').value = '';
  }

  selectSearch(e){
    this.fetchOrgChartbySearch(e.target.dataset.email);
    this.user = e.target.dataset.id;
    this.results_list = [];
    this.other_results_list = [];
  }

  selectOtherSearch(e){
    // tslint:disable-next-line:variable-name
    this.fetchHead(e.target.dataset.type,e.target.innerText)
    const values_array = this.searchParams.map(a => { return a['value']});
    if (values_array.includes(e.target.innerText) == false){
      this.searchParams = this.searchParams.concat([{
        type: e.target.dataset.type,
        value: e.target.innerText
      }]);
}
    this.other_results_list = [];
    this.results_list = [];
  }

  clearParam(e){
    this.searchParams = this.searchParams.filter((param) => {
      return param.value !== e.target.dataset.value;
    });
  }

  onClick(event) {
    if (this._eref.nativeElement.contains(event.target)){
      this.other_results_list = [];
      this.results_list = [];
    } // or some similar check

   }

  //  modalData(data,user){


  //  }

  closeUserDetailView(){
    this.openUserDetailedView = !this.openUserDetailedView
  }

  receiveModalEmail(email: string):void{
    console.log(email)
  }

}

function capitalizeSmallWords(string){
  let string_array = string.split(" ")

  let new_string_array = string_array.map(word=>{
    if(word.length <= 2){
      return word.toUpperCase()
    } else {
      return word
    }

  })

  let new_string = new_string_array.join(" ")
  return new_string
}
