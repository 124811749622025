import { Component, OnInit, Input } from '@angular/core';
import {Posts2} from '../../core/models/paginatedPosts2ListResponse';

@Component({
  selector: 'jz-hr-latest-updates',
  templateUrl: './hr-latest-updates.component.html',
  styleUrls: ['./hr-latest-updates.component.scss']
})
export class HrLatestUpdatesComponent implements OnInit {
  @Input() posts2List: Posts2[];
  cat = 'General Posts';

  constructor() { }

  ngOnInit(): void {
  }

}
