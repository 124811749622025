import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Idea, IdeaCategory, IIdea} from './models/idea';
import {Observable} from 'rxjs';
import {Contest} from './models/contest';
import {VoteCount} from './models/vote-count';

@Injectable({
  providedIn: 'root'
})
export class IdeaService {
  url = environment.apiUrl;
  detailIdea: IIdea;

  constructor(
    private http: HttpClient
  ) { }

  setDetailIdea(idea: IIdea) {
    this.detailIdea = idea;
  }

  createIdea(idea: Idea) {
    const data = new FormData();
    data.append('title', idea.title);
    data.append('description', idea.description);
    // @ts-ignore
    data.append('category', idea.category);
    data.append('file_content', idea.file_content);

    return this.http.post(this.url + 'j_tank/ideas', data);
  }

  getContest(): Observable<Contest[]> {
    return this.http.get<Contest[]>(this.url + 'j_tank/contests/last_active');
  }

  getCategories(): Observable<IdeaCategory[]> {
    return this.http.get<IdeaCategory[]>(this.url + 'j_tank/categories');
  }

  getIdeas(): Observable<IIdea[]>{
    return this.http.get<IIdea[]>(this.url + 'j_tank/ideas');
  }

  getIdea(id: number): Observable<IIdea> {
    return this.http.get<IIdea>(this.url + 'j_tank/ideas/' + id);
  }

  vote(ideaId: number, opinion: string, pk: number) {
    const body = {
      idea: ideaId,
      opinion_value: opinion,
      user: pk
    };

    return this.http.post(this.url + 'j_tank/opinions', body);
  }

  getNumberOfVotes(): Observable<VoteCount> {
    return this.http.get<VoteCount>(this.url + 'users/vote_count');
  }
}
