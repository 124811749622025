import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import { AuthorizationService } from './authorization.service';
import { tap } from 'rxjs/operators';
import {Router} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {environment} from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  apiIp = environment.apiIp;

  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private spinner: NgxSpinnerService
  ) {}

  checkExceptions(requestUrl: string): boolean {
    switch (requestUrl) {
      case 'http://18.222.238.253/api/auth/google':
        return true;
      case 'https://api.jcosmos.net/api/auth/google':
        return true;
      case 'http://3.140.242.101/api/auth/google':
        return true;
      case 'http://0.0.0.0:8000/api/auth/google':
        return true;
      case 'https://api.openweathermap.org/data/2.5/weather':
        return true;
      default:
        return false;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Checking if req endpoints are protected (if not just clone request) otherwise add Auth header
    if (this.checkExceptions(req.url)) {

      const noTokenRequest = req.clone();
      return next.handle(noTokenRequest);

    } else {

      const request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authorizationService.getToken()}`
        }
      });
      return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {

          }
        }, (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401 || error.status === 403) {
              localStorage.removeItem('token');
              this.authorizationService.logOut();
              this.router.navigate(['/login']);
              console.log(error);
            }
          }
        })
      );

    }
  }
}
