import { Component, OnInit, Input } from '@angular/core';
// import {HrTeamStaff} from '../../core/models/hrTeam-staff';
import {Employee} from '../../new-org-chart/models/employee';

@Component({
  selector: 'jz-hr-team',
  templateUrl: './hr-team.component.html',
  styleUrls: ['./hr-team.component.scss']
})
export class HrTeamComponent implements OnInit {

  // @Input() staff: HrTeamStaff[];
  @Input() staff: Employee[];
  indexPosition = 0;

  constructor() { }

  ngOnInit(): void {
  }

  // public functions
  nextUser() {
    const newIndex = this.indexPosition + 1;
    this.indexPosition = newIndex;
  }

  previousUser() {
    if (this.indexPosition !== 0) {
      const newIndex = this.indexPosition - 1;
      this.indexPosition = newIndex;
    }
  }

}
