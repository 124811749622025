import {Directive, ElementRef, Renderer2, OnInit, HostListener} from '@angular/core';

@Directive({
  selector: '[jzBluejBannerHover]'
})
export class BluejBannerHoverDirective implements OnInit{
  logoImgElem: ElementRef;

  constructor(
    private elm: ElementRef,
    private renderer2: Renderer2
  ) { }

  ngOnInit(): void {
    this.logoImgElem = this.elm.nativeElement.querySelector('.bird-img');
  }

  @HostListener('mouseenter') mouseover(event: Event) {
    this.renderer2.removeClass(this.logoImgElem, 'static');
    this.renderer2.addClass(this.logoImgElem, 'slide');
  }

  @HostListener('mouseleave') mouseleave(event: Event) {
    this.renderer2.removeClass(this.logoImgElem, 'slide');
    this.renderer2.addClass(this.logoImgElem, 'static');
  }

}
