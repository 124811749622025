<div id="right-content">
  <div class="container-fluid">

    <!-- back button -->
    <div class="container-fluid button-container"
         (click)="goBack()"
    >
      <i class="fas fa-caret-left" style="color: #FFFFFF;"></i>
      <button>
        Back
      </button>
    </div>

    <div class="row post-content-container">
      <span style="background: #54C8E8;
          border-radius: 20px;
          color: #54C8E8;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 28px">-</span>

<!--      <h4 class="post-category-title">{{post.headline}}</h4>-->
<!--      <div class="col-md-12 col-sm-12">-->
<!--        <div *ngFor="let content of post.contents; let i = index">-->
<!--          <div class="text-center" *ngIf="i/1 == 0">-->
<!--            <img src="{{content.display_image}}" style="width: 70%" alt="post display image">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="col-md-12 col-sm-12" style="margin-top: 20px">
        <h1 class="text-center">{{post.headline}}</h1>
        <div id="html-content" [innerHTML]="post.text_content | sanitize : 'html'"></div>
<!--        <div class="text-center" style="margin-bottom: 20px">-->
<!--          <img src="{{post.card_image}}" class="img-fluid" alt="content display image">-->
<!--        </div>-->
      </div>
    </div>
<!--    <div class="row">-->
<!--      <div class="col-md-12">-->
<!--        <div class="item-block d-flex flex-row flex-wrap justify-content-center gallery-wrapper">-->
<!--            <div *ngFor="let content of post.contents; let i = index">-->
<!--              <div *ngIf="i > 1">-->
<!--                <img (click)="openModal(template)"  src="{{content.display_image}}" class="img-fluid" alt="content display name">-->
<!--                <ng-template #template>-->
<!--&lt;!&ndash;                  <div class="modal-header">&ndash;&gt;-->
<!--&lt;!&ndash;                    <h4 class="modal-title pull-left">Modal</h4>&ndash;&gt;-->
<!--&lt;!&ndash;                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&ndash;&gt;-->
<!--&lt;!&ndash;                      <span aria-hidden="true">&times;</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </button>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                  <div class="modal-body">-->
<!--                    <carousel>-->
<!--                      <div *ngFor="let content of post.contents; let i = index">-->
<!--                        <div *ngIf="i > 1">-->
<!--                          <slide>-->
<!--                            <img src="{{content.display_image}}" alt="first slide" style="display: block; width: 100%;">-->
<!--                          </slide>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </carousel>-->
<!--                  </div>-->
<!--                </ng-template>-->
<!--              </div>-->
<!--            </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>


