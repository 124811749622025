import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {User} from './models/user';
import {Employee} from '../new-org-chart/models/employee';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getAllUsers(): Observable<User>{
    return this.http.get<User>(this.url + 'users');
  }

  getUsersByDates(month: number): Observable<Employee> {
    // return this.http.get<>(this.url + `users?birthdate_month=${month}`);
    return this.http.get<Employee>(this.url + 'users/upcoming_birthdays');
  }

  getUsersByDatesInArray(month: number): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.url + 'users/upcoming_birthdays');
  }
}
