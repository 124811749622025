import { Component, OnInit } from '@angular/core';
import {IIdea, Vote} from '../../core/models/idea';
import {ActivatedRoute, Router} from '@angular/router';
import {IdeaService} from '../../core/idea.service';
import {CheckVoteComponent} from '../../shared/check-vote/check-vote.component';
import {MatDialog} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {Contest} from '../../core/models/contest';
import {VoteCount} from '../../core/models/vote-count';

@Component({
  selector: 'jz-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.scss']
})
export class VoteComponent implements OnInit {
  ideas: IIdea[];
  dialogRef;
  contest: Contest;
  voted = {};
  votedUpIdeas = {};
  votedDownIdeas = {};
  isAllowedToVote = true;

  constructor(
    private route: ActivatedRoute,
    private ideaService: IdeaService,
    private router: Router,
    public dialog: MatDialog,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.ideas = data.ideas;
      this.contest = data.contest[0];

      if (data.voteCount.votes_per_contest >= 3) {
        this.isAllowedToVote = false;
      }
    });
  }

  async setDetailAndNavigate(idea: IIdea) {
    if (!this.votedUpIdeas[idea.pk] && !this.votedDownIdeas[idea.pk]) {
      this.ideaService.setDetailIdea(idea);
    } else {
      this.ideaService.setDetailIdea(undefined);
    }
  }

  navigateBack() {
    this.location.back();
  }

  checkVotes() {
    this.ideaService.getNumberOfVotes().subscribe((votes: VoteCount) => {
      if (votes.votes_per_contest >= 3) {
        this.isAllowedToVote = false;
      }
    });
  }

  updateLocalIdea(idea: IIdea, value: string) {
    const updated = this.ideas.find((arrIdea: IIdea) => arrIdea.pk === idea.pk);
    updated.user_opinion = value;
  }

  setOpinion(opinion: string, idea: IIdea, event) {
    this.dialogRef = this.dialog.open(CheckVoteComponent, {
      width: '350px',
      disableClose: true
    });

    const user = parseInt(localStorage.getItem('user'), 10);

    if (this.dialogRef) {
      this.dialogRef.afterClosed().subscribe((data) => {
        if (data === 'vote') {
          this.ideaService
            .vote(idea.pk, opinion, user)
            .subscribe((response: Vote) => {
              if (response.opinion_value === 'yes') {
                this.votedUpIdeas[response.idea] = response.idea;
                if (!this.voted[response.idea]) {
                  this.voted[response.idea] = response.idea;
                }
                this.updateLocalIdea(idea, response.opinion_value);
              } else {
                this.votedDownIdeas[response.idea] = response.idea;
                if (!this.voted[response.idea]) {
                  this.voted[response.idea] = response.idea;
                }
                this.updateLocalIdea(idea, response.opinion_value);
              }
              // Checking if user is able to vote after voting
              this.checkVotes();
            });
        } else {
          console.log('User cancelled');
        }
      });
    }

    event.stopPropagation();
  }
}
