<div class="right-content">

  <!--labels-->
  <section>
      <span style="background: #E63A95;
            border-radius: 20px;
            color: #E63A95;
            position: absolute;
            left: -7px;
            width: 26px;
            height: 14px;
            top: 20px">-</span>

    <h5 class="label-card-name">
      Organizational Chart
    </h5>

    <img src="assets/new-org-chart/search-icon.png" class="search-icon-cont" alt="search icon" (click)="openDialog()">
  </section>

  <div class="container-fluid" #scrollMe [scrollTop]="scrollMe.scrollHeight" style="overflow-y: auto; overflow-x: hidden; height: 90%">

    <!--org chart starts here-->
    <section *ngIf="!searchWasSubmitted" class="container section-cont">

      <!--root-->
      <div class="root-node">
        <div class="card-style-cont d-flex flex-row justify-content-center">
          <jz-node-card *ngIf="ceoDataArray" [id]="ceoDataArray[0].number"></jz-node-card>
        </div>
      </div>

      <jz-hierarchy-container *ngIf="ceoDataArray" [data]="ceoDataArray[0].manages"></jz-hierarchy-container>
    </section>

    <!--search results - hierarchy view-->
    <section *ngIf="searchWasSubmitted" class="container section-cont">

      <!--return button to show main tree view-->
      <div class="return-btn-home">
        <button class="view-org-chart-button d-flex flex-row justify-content-center align-items-center"
                (click)="clearSearch()">
          Clear Search
        </button>
      </div>

      <div class="root-node">
        <div class="card-style-cont d-flex flex-row justify-content-center">
          <jz-search-results [searchArray]="searchResultsArray"></jz-search-results>
        </div>
      </div>
    </section>

  </div>
</div>
