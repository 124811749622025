import {AfterViewInit, Component, DoCheck, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Recognition} from '../../core/models/employee';
import {EmployeeRecogService} from '../../core/employee-recog.service';
import {ActivatedRoute} from '@angular/router';
import * as tinycolor from 'tinycolor2';

@Component({
  selector: 'jz-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit, DoCheck, AfterViewInit {
  @ViewChild('container') container: ElementRef;

  recognition: Recognition;
  containerOffset: number;
  applyClass = false;
  innerWidth = window.innerWidth;
  currentInnerWidth: number;

  constructor(
    private employeeRecogService: EmployeeRecogService,
    private route: ActivatedRoute
  ) { }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.recognition = data.recognition;
    });
    this.currentInnerWidth = window.innerWidth;
  }

  ngAfterViewInit() {
    this.containerOffset = this.container.nativeElement.offsetWidth;
  }

  ngDoCheck(): void {
    if (this.container) {
      const offsetWidth = this.container.nativeElement.offsetWidth;
      this.applyClass = offsetWidth < this.containerOffset;
    }
  }

  darkenColor(color: string): string {
    return tinycolor(color).darken().toString();
  }

}
