<div id="right-content">

  <!-- back button -->
  <div
    class="container-fluid button-container"
    [routerLink]="['/', 'bluej']"
  >
    <i class="fas fa-caret-left" style="color: #FFFFFF;"></i>
    <button>
      Back
    </button>
  </div>

  <div class="white-section">
    <!-- new design  -->
      <img class="new" src="assets/bluej/BlueJ_Refactor/BlueJ-Image-for-background.png" alt="background image" style="width: 95%;"/>.
  </div>

</div>
