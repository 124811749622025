import { Component, OnInit, Input } from '@angular/core';
import {ProfileQuestionsServiceService} from '../../services/profile-questions-service.service';
import {IResponses} from '../../models/responses';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'jz-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  // data coming from parent comp
  @Input() componentPK: number;
  @Input() email: string;

  // local variables
  responses: IResponses[];
  response: string;
  errorMessage: string;
  text: string;
  textEdited: string;
  responseArray: IResponses[];
  editBoolean = false;

  constructor(private fetchQuestionsData: ProfileQuestionsServiceService, private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchTest(this.email);
  }

  editing() {
    this.editBoolean = true;
  }
  edit(primaryKey: number, userResponse: string) {
    console.log('something');
    this.http.patch<any>('https://devapi.jcosmos.net/api/v1/user_profiles/responses/' + primaryKey + '/', {
      response: userResponse
    }).subscribe({
      next: data => {
        console.log(data);
      },
      error: err => {
        console.log('there was an error', err.message);
      }
    });
  }

  save(questionCompID: number, userEmail: string, userResponse: string) {
    this.http.post<any>('https://devapi.jcosmos.net/api/v1/user_profiles/responses/', {
        user: userEmail, component: questionCompID, response: userResponse
    }).subscribe({
      next: data => {
        console.log(data);
      },
      error: err => {
        console.log('there was an error', questionCompID, err.message);
      }
    });
  }

  fetchTest(arg: string) {
    this.fetchQuestionsData.fetchResponsesArray(arg).subscribe({
      next: response => {
        console.log('responses array', response);
        this.responses = response;
        this.filterBy();
      }
    });
  }

  filterBy() {
    const uniqueResponse = this.responses.filter(component => component.component === this.componentPK);
    this.responseArray = uniqueResponse;
    this.response = uniqueResponse[0].response;
  }

  // fetchResponses() {
  //   this.fetchQuestionsData.fetchUserResponses().subscribe({
  //     next: response => {
  //       this.responses = response;
  //       console.log('array of responses', this.responses);
  //     }
  //   });
  // }

}
