import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Diversity} from '../models/diversity';
import {DiversityService} from '../diversity.service';

@Injectable()
export class DiversityResolver implements Resolve<Diversity[]> {

  constructor(
    private diversityService: DiversityService
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Diversity[]> | Promise<Diversity[]> | Diversity[] {
    return this.diversityService.getDiversityResources();
  }
}
