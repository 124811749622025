import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Employee} from '../../models/employee';
import {FetchingDataService} from '../../services/fetching-data.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RightMenuModalComponent} from '../right-menu-modal/right-menu-modal.component';

@Component({
  selector: 'jz-node-card',
  templateUrl: './node-card.component.html',
  styleUrls: ['./node-card.component.scss']
})
export class NodeCardComponent implements OnInit {
  @Input() id: string;
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() toggle: boolean;
  @Input() activeState: boolean;
  @Input() numberId: string;
  @Output() newActiveEvent = new EventEmitter<string>();
  employeeData: Employee;
  vectorToggle = false;
  errorMessage = '';
  @Input() searchActive: boolean;
  search = false;

  constructor(private fetchDataServices: FetchingDataService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.fetchEmployeeData();
    if (this.searchActive) {
      this.search = !this.search;
    }
  }

  // method to open right side MatDialog
  openRightSideDialog(id: string) {
   const dialogRef: MatDialogRef<RightMenuModalComponent> = this.dialog.open(RightMenuModalComponent, {
     panelClass: 'custom-modalRightMenu',
     data: {
       number: id
     }
   });
  }

  // method to call when click event is triggered in node
  addNewData(list: any) {
    this.newItemEvent.emit(list);
    this.vectorToggle = !this.vectorToggle;
  }

  // passing ID number
  sendNewActiveDataToParent(numberId: string) {
    this.newActiveEvent.emit(numberId);
  }

  private fetchEmployeeData() {
    this.fetchDataServices.fetchEmployeePerNumber(this.id).subscribe({
      next: response => {
        this.employeeData = response;
      }, error: err => this.errorMessage = err
    });
  }
}
