import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ISubmission} from './models/halloween-contest';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {IIdea} from './models/idea';

@Injectable({
  providedIn: 'root'
})
export class SubmissionService {
  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) {
  }

  createSubmission(submission: ISubmission) {
    const data = new FormData();
    data.append('title', submission.title);
    // @ts-ignore
    data.append('contents', submission.contents);

    return this.http.post(this.url + 'photo_contest/submissions', data);
  }

  getSubmissions(): Observable<ISubmission[]> {
    return this.http.get<ISubmission[]>(this.url + 'photo_contest/submissions');
  }

  vote(listOfRelationList: any) {
    const data = new FormData();
    data.append('cat_sub_relation_list', JSON.stringify(listOfRelationList));
    return this.http.post(this.url + 'photo_contest/vote_data', data);
  }

  // checks if the user already voted.
  checkVoted() {
    return this.http.get<boolean>(this.url + 'photo_contest/voted');
  }
}
