import {Component, Input, OnInit} from '@angular/core';
import {SubCategory} from '../../core/models/resource';
import {PostsService} from '../../core/posts.service';

@Component({
  selector: 'jz-resource-cat',
  templateUrl: './resource-cat.component.html',
  styleUrls: ['./resource-cat.component.scss']
})
export class ResourceCatComponent implements OnInit {
  @Input() subCat: SubCategory;
  @Input() catColor: string;
  compHeight: number;


  constructor(
    private postService: PostsService
  ) {
  }

  ngOnInit(): void {
    this.compHeight = this.postService.maxHeight;

    console.log(this.subCat);

    // Calculating max height
    const currHeight = this.postService.maxHeight;
    const catHeight = 57 * this.subCat.res_by_sub_cat.length;

    this.postService.setMaxHeight(Math.max(currHeight, catHeight));
    this.compHeight = Math.max(currHeight, catHeight);
  }
}
