import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CategoriesService} from '../categories.service';
import {PostCategory} from '../models/postCategory';

@Injectable()
export class CategoriesResolver implements Resolve<PostCategory[]> {
  constructor(
    private categoriesService: CategoriesService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<PostCategory[]> | Promise<PostCategory[]> | PostCategory[] {
    return this.categoriesService.getCategories();
  }
}
