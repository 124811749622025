import {Directive, ElementRef, HostListener, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[jzBlueCatHover]'
})
export class BlueCatHoverDirective implements OnInit {

  birdImgElem: ElementRef;
  cardCopyElem: ElementRef;

  constructor(private elm: ElementRef,
              private renderer2: Renderer2) {
  }

  ngOnInit(): void {
    this.birdImgElem = this.elm.nativeElement.querySelector('.bird-logo-img');
    this.cardCopyElem = this.elm.nativeElement.querySelector('.cat-card-copy');
  }

  @HostListener('mouseenter') mouseover(event: Event) {
    this.renderer2.removeClass(this.birdImgElem, 'grow');
    this.renderer2.removeClass(this.cardCopyElem, 'd-none-c');

    this.renderer2.addClass(this.birdImgElem, 'shrink');
    this.renderer2.addClass(this.cardCopyElem, 'd-block-c');
  }

  @HostListener('mouseleave') mouseleave(event: Event) {
    this.renderer2.removeClass(this.birdImgElem, 'shrink');
    this.renderer2.removeClass(this.cardCopyElem, 'd-block-c');

    this.renderer2.addClass(this.birdImgElem, 'grow');
    this.renderer2.addClass(this.cardCopyElem, 'd-none-c');

  }


}
