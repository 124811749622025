import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ResourceCategory} from '../models/resource';
import {PostsService} from '../posts.service';

@Injectable()
export class ResourcesResolver implements Resolve<ResourceCategory[]> {

  constructor(
    private postsService: PostsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ResourceCategory[]> | Promise<ResourceCategory[]> | ResourceCategory[] {
    return this.postsService.getCategoriesWithResources();
  }
}
