<div class="resource-list-container h-100">
  <span style="background: #F5DA00;
          border-radius: 9px;
          color: #F5DA00;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 18px">-</span>
  <h5 style="font-weight: bold">Benefits</h5>
  <div id="content-cont" *ngIf="!isEmpty">
    <div class="d-flex flex-wrap">
      <div class="show-data" *ngFor="let resource of benefitsArray[0].resources">
        <a
          class="resource-container"
          [href]="(
            resource.resource_type === 'pdf' ||
            resource.resource_type === 'xlsx' ||
            resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
          [target]="'_blank'"
          style="text-decoration: none; color: black;"
        >
          <img style="width: 15%"
            alt="file type"
            [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'"
          />
          <p>{{resource.name}}</p>
        </a>
      </div>
    </div>

    <div class="d-flex flex-column mt-4" *ngIf="benefitsArray[0].sub_cat.length">
      <mat-accordion *ngFor="let subCat of benefitsArray[0].sub_cat; index as i ">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title style="position: relative">
              <span style="background: #F5DA00;
            border-radius: 9px;
            color: #F5DA00;
            position: absolute;
            left: 0px;
            width: 26px;
            height: 14px;
            top: 13px">-</span>
              <p class="subcat">{{subCat.cat}}</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let resource of benefitsArray[0].sub_cat[i].resources">
            <a
              class="resource-container"
              [href]="(
              resource.resource_type === 'pdf' ||
              resource.resource_type === 'xlsx' ||
              resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
              [target]="'_blank'"
            >
              <img style="width: 15%"
                alt="file type"
                [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'"
              />
              <p>{{resource.name}}</p>
            </a>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

<!--    <div class="d-flex flex-wrap" *ngIf="benefitsArray[0].sub_cat.length">-->
<!--      &lt;!&ndash; looping through sub_cat &ndash;&gt;-->
<!--      <div class="show-data" id="subcat-content" style="position: relative" *ngFor="let subCat of benefitsArray[0].sub_cat; index as i ">-->
<!--        <span style="background: #F5DA00;-->
<!--            border-radius: 9px;-->
<!--            color: #F5DA00;-->
<!--            position: absolute;-->
<!--            left: -9px;-->
<!--            width: 26px;-->
<!--            height: 14px;-->
<!--            top: 13px">-</span>-->
<!--        <p class="subcat">{{subCat.cat}}</p>-->

<!--        <div *ngFor="let resource of benefitsArray[0].sub_cat[i].resources">-->
<!--          <a-->
<!--            class="resource-container"-->
<!--            [href]="(-->
<!--              resource.resource_type === 'pdf' ||-->
<!--              resource.resource_type === 'xlsx' ||-->
<!--              resource.resource_type === 'video') ? resource.resource_url : resource.external_link"-->
<!--            [target]="'_blank'"-->
<!--          >-->
<!--            <img-->
<!--              alt="file type"-->
<!--              [src]="resource.resource_type === 'pdf' ? 'assets/pdf-logo.png' : 'assets/links-logo.png'"-->
<!--            />-->
<!--            <p>{{resource.name}}</p>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>




</div>
