import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IdeaService} from '../idea.service';
import {Contest} from '../models/contest';

@Injectable()
export class ContestResolver implements Resolve<Contest[]> {

  constructor(
    private ideaService: IdeaService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Contest[]> | Promise<Contest[]> | Contest[] {
    return this.ideaService.getContest();
  }
}
