import {Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import {Employee} from '../../models/employee';
import {FetchingDataService} from '../../services/fetching-data.service';

@Component({
  selector: 'jz-peers-list',
  templateUrl: './peers-list.component.html',
  styleUrls: ['./peers-list.component.scss']
})
export class PeersListComponent implements OnInit, OnChanges {
  @Input() data: string;
  @Input() number: string;
  @Input() color: string;
  @Input() fontColor: string;
  manager: Employee;
  peersArray: [];
  reportToArray: Employee;

  newTest: string;
  // passing data to right-menu comp after receiving data from node (peers-node comp)
  @Output() newTestValue = new EventEmitter<string>();

  constructor(private fetchDataService: FetchingDataService) { }

  ngOnInit(): void {
    this.fetchManager(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fetchManager(this.data);
  }

  // receiving the new person number from peers-node comp
  addTest(newTestVarFromPeersNode: string) {
    this.newTest = newTestVarFromPeersNode;
    this.passingNewTestValueToRightMenuComp(newTestVarFromPeersNode);
  }

  passingNewTestValueToRightMenuComp(addTest: string) {
    this.newTestValue.emit(addTest);
  }

  // method to find peers by looking to your manager
  fetchManager(managerNumber: string) {
    this.fetchDataService.fetchEmployeePerNumber(managerNumber).subscribe({
      next: response => {
       this.peersArray = response.manages;
       this.reportToArray = response;
      }
    });
  }
}
