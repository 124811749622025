import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IPartner} from './models/partner';


@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getAllPartners(): Observable<IPartner[]> {
    return this.http.get<IPartner[]>(this.url + 'partners/');
  }
}
