import {Component, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {Location} from '@angular/common';
import {Posts2} from '../core/models/paginatedPosts2ListResponse';

@Component({
  selector: 'jz-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss']
})
export class PostDetailComponent implements OnInit {
  post: Posts2;
  modalRef: BsModalRef;
  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.post = data.post;
      // console.log(this.post);
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  goBack() {
    this.location.back();
  }
}
