import { Component, OnInit, Input } from '@angular/core';
import {HrCategory} from '../../core/models/hr-category';

@Component({
  selector: 'jz-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent implements OnInit {

  @Input() payrollArray: HrCategory[];
  isEmpty: boolean;

  constructor() { }

  ngOnInit(): void {
    this.ifEmpty();
  }

  ifEmpty() {
    if (this.payrollArray.length) {
      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }
  }

}
