import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IIdea, Vote} from '../../core/models/idea';
import {IdeaService} from '../../core/idea.service';
import {MatDialog} from '@angular/material/dialog';
import {CheckVoteComponent} from '../../shared/check-vote/check-vote.component';
import {Location} from '@angular/common';
import {VoteCount} from '../../core/models/vote-count';

@Component({
  selector: 'jz-idea-detail',
  templateUrl: './idea-detail.component.html',
  styleUrls: ['./idea-detail.component.scss']
})
export class IdeaDetailComponent implements OnInit {
  // TODO: Remove voting buttons if not available to vote
  idea: IIdea;
  dialogRef;
  isPdf = false;
  voted = false;

  dislikeSelected = false;
  likeSelected = false;
  isAvailableToVote = true;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private ideaService: IdeaService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((data: {idea: IIdea, voteCount: VoteCount}) => {
      if (data.voteCount.votes_per_contest >= 3) {
        this.isAvailableToVote = false;
      }
    });

    if (this.ideaService.detailIdea) {
      this.idea = this.ideaService.detailIdea;
      this.isPdf = this.onIsPdf(this.ideaService.detailIdea.file_content);
      console.log(this.idea);
    } else {
      this.route.data.subscribe((data: {idea: IIdea, voteCount: VoteCount}) => {
        this.idea = data.idea;
        this.isPdf = this.onIsPdf(data.idea.file_content);

        if (data.voteCount.votes_per_contest >= 3) {
          this.isAvailableToVote = false;
        }
        console.log(this.idea);
      });
    }
  }

  goBack() {
    this.location.back();
    this.ideaService.setDetailIdea(undefined);
  }

  checkVotes() {
    this.ideaService.getNumberOfVotes().subscribe((votes: VoteCount) => {
      if (votes.votes_per_contest >= 3) {
        this.isAvailableToVote = false;
      }
    });
  }

  setOpinion(opinion: string) {
    this.dialogRef = this.dialog.open(CheckVoteComponent, {
      width: '350px',
      disableClose: true
    });

    const user = parseInt(localStorage.getItem('user'), 10);

    if (this.dialogRef) {
      this.dialogRef.afterClosed().subscribe((data) => {
        if (data === 'vote') {
          this.ideaService
            .vote(this.idea.pk, opinion, user)
            .subscribe((response: Vote) => {
            this.voted = true;
          });
          this.dislikeSelected = opinion === 'no';
          this.likeSelected = opinion === 'yes';
        } else {
          this.dislikeSelected = false;
          this.likeSelected = false;
        }
        this.checkVotes();
      });
    }
  }

  // This function will be called on OnInit
  onIsPdf(fileContent: string): boolean {
    return fileContent.slice(fileContent.length - 3, fileContent.length) === 'pdf';
  }

  openIfPdf(fileContent) {
    if (this.isPdf) {
      window.open(fileContent, '_blank');
    }
  }

}
