import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HrTeamStaff} from './models/hrTeam-staff';

@Injectable({
  providedIn: 'root'
})
export class HrTeamListService {
  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getHrTeamProfiles(): Observable<HrTeamStaff[]> {
    return this.http.get<HrTeamStaff[]>(this.url + 'hr/team');
  }
}
