<div class="container" id="medalla" style="height: 100%">


<!--    <div style="padding: 15px">-->
<!--      <div class="d-flex justify-content-center">-->
<!--        <div id="logo-pic">-->
<!--          <img src="assets/home-cards/medalla.png" alt="medalla logo">-->
<!--        </div>-->
<!--        <div>-->
<!--          <p id="employee-recog">-->
<!--            Employee<br>Recognition-->
<!--          </p>-->
<!--          <button type="button" class="btn btn-primary" id="recog-button"-->
<!--                  [routerLink]="['/', 'recog-employees']" >-->
<!--            Click Here-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


    <div class="row" style="padding: 15px">
      <div class="d-flex flex-row" style="width: 100%">
        <div class="d-sm-none d-md-block" id="logo-pic" style="margin: auto; width: 50%">
          <img class="img-fluid" src="assets/home-cards/medalla.png" alt="medalla logo">
        </div>
        <div style="margin: auto; width: 50%">
          <p id="employee-recog">Employee<br>Recognition</p>
          <button type="button" class="btn btn-primary" id="recog-button"
                  [routerLink]="['/', 'recog-employees']" >
            Click Here
          </button>
        </div>
      </div>
    </div>


</div>


<!--<div class="col-sm-6 col-md-6 col-lg-6 text-center" id="logo-pic">-->
<!--  <img class="img-fluid" src="assets/home-cards/medalla.png" alt="medalla logo">-->
<!--</div>-->
<!--<div class="col-sm-6 col-md-6 col-lg-6">-->
<!--  <p class="text-left" id="employee-recog">Employee<br>Recognition</p>-->
<!--  <button type="button" class="btn btn-primary" id="recog-button"-->
<!--          style="background: #54C8E8; border-radius: 50px; border-color: #54C8E8"-->
<!--          [routerLink]="['/', 'recog-employees']" >-->
<!--    Click Here-->
<!--  </button>-->
<!--</div>-->
