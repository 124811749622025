<div id="right-content">

  <div class="container">

    <div class="row">
      <div class="col-md-5 col-lg-4 text-center">
        <div class="grey-section card-height">
          <span style="background: #FFC400;
               border-radius: 20px;
               color: #FFC400;
               position: absolute;
               left: -9px;
               width: 26px;
               height: 14px;
               top: 28px">-</span>
          <h4>J Partner Discounts</h4>
          <img src="assets/discounts/JPartners-new.jpg" class="img-fluid mt-3" alt="QR code">
          <p class="jpartner-text text-left" style="font-size: 14px">
            Find local restaurants and businesses suggested by Jazmanians, many with special discounts
            and offers for Jazwares employees. Jazwares loves to support small businesses, so give your
            favorite local cafe, restaurant, gym, yoga studio or daycare some love by having them featured
            on this page.<br><br>
            Just fill out the form here to leave a suggestion for a business near you.<br>
          </p>
          <div class="jdiscounts-button" >
            <a href="https://forms.gle/8J8gswPUDwyQYZXa6" target="_blank">Click Here</a>
          </div>


          <div class="btn-group text-center" dropdown>
            <button id="loc-btn"
                    dropdownToggle
                    type="button"
                    class="dropdown-toggle"
                    aria-controls="dropdown-basic">
              {{selectedLocation ? selectedLocation : 'Location'}}
              <span class="caret"></span>
            </button>

            <ul id="loc-ul" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem" *ngFor="let loc of allLocations">
                <a class="dropdown-item" (click)="filterByLocation(loc)">{{loc}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-7 col-lg-8">
        <div class="search-section mb-2">
          <input id="search-bar" class="mb-2 sb" type="text" placeholder="Search by name" name="decalNumber" #box
                 (keyup)="onKey(box.value)">
        </div>

        <div class="row" *ngIf="noData">
          <div class="col-md-10 col-lg-12 text-center" id="no-data-state">
            <h4>No results found</h4>
            <p>Try adjusting your search or filter to find what you are looking for</p>
            <img src="assets/discounts/no-results-found-placeholder.png" alt="placeholder Logo">
          </div>
        </div>

        <!-- state 1-->
        <div class="row" id="scrollbar-div" style="overflow-y: auto; overflow-x: hidden; height: 60%">
          <div class="col-6 mb-4" *ngFor="let partner of filteredDiscounts; let i = index"
               triggers="focus"
               (mouseenter)="onHover(i)"
               (mouseleave)="onHover(-1)"
          >
            <button type="button" class="logo-cards">
              <img [src]="partner.logo" alt="{{partner.name}} Logo"
                   [ngStyle]="{'width': imageResize && (hoverIndex === i) ? '30%' : '50%'}"
              >
              <div  *ngIf="i == hoverIndex">
                <p class="text-descrip" style="color: #707070; font-size: 12px; margin-top: 10px">{{(partner.discount.length > 40) ? (partner.discount | slice: 0: 40) + '...' : (partner.discount) }}</p>
                <div class="jdiscounts-link-button">
                  <a [href]="(partner.resource.external_link)" target="_blank">Link Here</a>
                </div>
              </div>
            </button>
          </div>
        </div>
<!--        <div class="d-flex flex-wrap mt-4">-->
<!--          <button type="button" class="logo-cards"-->
<!--                  *ngFor="let partner of filteredDiscounts; let i = index"-->
<!--                  triggers="focus"-->
<!--                  (mouseenter)="onHover(i)"-->
<!--                  (mouseleave)="onHover(-1)"-->
<!--          >-->
<!--            <img [src]="partner.logo" alt="{{partner.name}} Logo">-->
<!--            <div  *ngIf="i == hoverIndex">-->
<!--              <p class="text-descrip" style="color: #707070; font-size: 12px; margin-top: 10px">{{(partner.discount.length > 40) ? (partner.discount | slice: 0: 40) + '...' : (partner.discount) }}</p>-->
<!--              <div class="jdiscounts-link-button">-->
<!--                <a [href]="(partner.resource.external_link)" target="_blank">Link Here</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</div>
