import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HrCategory} from './models/hr-category';

@Injectable({
  providedIn: 'root'
})
export class HumanResourcesService {

  private url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  // method to get all resources from HR different categories
  getHrResources(): Observable<HrCategory[]> {
    return this.http.get<HrCategory[]>(
      this.url + 'resources2/get?app=HR&model=category'
    );
  }

}
