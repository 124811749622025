<div id="carousel-height">
  <carousel>
    <slide *ngFor="let slide of slides">
      <img class="slide-img" [routerLink]="['/posts', slide.post]" alt="first slide"
           src="{{slide.background_image}}"/>
      <div id="slider-title">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 col-sm-12">
              <p class="filsonProMed">{{(slide.message.length > 30) ? (slide.message | slice:0:30) + '...' : (slide.message)}}</p>
            </div>
            <div class="col-md-4 col-sm-12 text-right">
              <span [routerLink]="['/posts', slide.post]" class="filsonProMed link-style"
                    style="cursor: pointer">Read more >
              </span>
            </div>
          </div>
        </div>
      </div>
    </slide>
  </carousel>
</div>
