<div id="right-content">
  <div class="container">

    <!-- region Spinner -->
    <ngx-spinner
      bdColor="rgba(51,51,51,0.3)"
      size="large"
      color="white"
      type="ball-climbing-dot"
      [fullScreen]="true"
    ></ngx-spinner>
    <!-- endregion -->

      <div class="row">

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4" *ngIf="paginatedPosts2ListResponse">
            <div class="card-height left-card">
              <div class="img-container text-center">
                <img src="assets/jcares/JazCares-01.jpg" class="img-fluid" alt="picture">
              </div>
              <div class="card-body">
                <h5 class="card-title">Let's make a difference together</h5>
                <p class="card-text">
                  Jazwares Cares is our way to connect with our local and global
                  community to support the health, well-being and educational
                  development of children through play!
                  <br><br>
                  Are you Passionate about giving back?
                  Do you want to volunteer? Have any great ideas?
                </p>
                <a href="mailto:{{email}}" target="_blank" class="btn" style="margin: auto; margin-bottom: 10px">Contact Jazwares Cares</a>
                <a href="https://drive.google.com/file/d/1dqjeHxWQYGIOb9KP6tC1eyDf4yy5B-Zj/view?usp=sharing"
                   target="_blank" class="btn" style="margin: auto; margin-bottom: 10px">Volunteer Time Off Policy</a>
                <a href="https://docs.google.com/spreadsheets/d/13p_kCjWw3o2wpET8kwL-MSlFVlYtsAhUH3R7S-KJtYA/edit#gid=634347005"
                   target="_blank" class="btn" style="margin: auto; margin-bottom: 10px">Jazwares Cares Events Calendar</a>
              </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-8 col-xl-8 mt-4 mt-md-0">
          <!--posts-->
          <jz-common-post-list [listOfPosts2]="listOfPosts2" [resources2]="resources2" [totalPages]="totalPages" [page]="page" [cat]="cat"></jz-common-post-list>
        </div>

      </div>

  </div>
</div>
