import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BluejCategoriesService} from '../core/bluej-categories.service';
import {BluejCategory} from '../core/models/bluej-category';
import {BluejCategories} from '../core/models/bluej-categories';

@Component({
  selector: 'jz-bluej-posts-detail',
  templateUrl: './bluej-posts-detail.component.html',
  styleUrls: ['./bluej-posts-detail.component.scss']
})
export class BluejPostsDetail implements OnInit {
  slug = '';
  posts: BluejCategory[] = [];
  data: BluejCategory[];
  catArray: {};
  isExternal = true;
  hover: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bluejCategories: BluejCategoriesService,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((data: {posts: BluejCategory[]}) => {
      this.posts = data.posts;
    });

    this.route.data.subscribe((data: {cat: BluejCategories[]}) => {
      this.catArray = data.cat;
    });

    // this.fetchCat();
    this.containsSpecialChars(this.catArray['external-link']);
  }

  private containsSpecialChars(str) {
    const specialChars = /[`@`]/;
    return specialChars.test(str);
    if (this.containsSpecialChars(str)) {
      this.isExternal = !this.isExternal;
    } else {
      this.isExternal = this.isExternal;
    }
  }

}
