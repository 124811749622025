import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Iyear} from '../models/year';
import {EventService} from '../event.service';

@Injectable()

export class EventResolver implements Resolve<Iyear> {
  constructor(
    private eventService: EventService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Iyear> | Promise<Iyear> | Iyear {
    return this.eventService.getEvent();
  }
}
