<div id="right-content">
  <div class="scrollable-div">
    <div class="block-cont" style="background-color: #FFFFFF; border-radius: 20px; position: relative">
      <span style="background: #E63A95;
          border-radius: 20px;
          color: #E63A95;
          position: absolute;
          left: -14px;
          width: 26px;
          height: 14px;
          top: 20px">-</span>
      <h5 style="position:absolute;color: #707070; font-family: FilsonProMed, 'Helvetica Neue', sans-serif; padding-top: 16px; margin-left: 20px;">Organizational Chart</h5>

      <!-- Loader -->
      <div class="loading-container">
        <span *ngIf="loading" id="Loader" class="loader"></span>
      </div>

      <div *ngIf="loading" class="shadow"></div>
      <!-- Search -->
      <div class="search-container">
        <mat-icon _ngcontent-hhr-c215="" role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">search</mat-icon>
        <input (input)="searching($event)" placeholder="Search" class="search-bar">
        <div class="search-results-container">
          <ul>
            <li (click)="selectOtherSearch($event)"
                *ngFor="let other_result of other_results_list"
                attr.data-type="{{other_result.type}}"
                attr.data-id="{{other_result.id}}"
                [ngClass]="{'search-office':other_result.type == 'office','search-division':other_result.type == 'division','search-department':other_result.type == 'department'}">{{other_result.name}}</li>
            <li (click)="selectSearch($event)"  *ngFor="let result of results_list" attr.data-email="{{result.email}}" attr.data-id="{{result.id}}">{{result.user}}</li>
          </ul>
        </div>
        <button (click)="clearSearch($event)" class="results-clear">&times;</button>
      </div>

      <!-- depts, offices, divisions -->
      <div class="search-params-container">
        <div class="search-params">
          <div *ngFor="let param of searchParams" class="search-param">
            {{param.value}}
            <span attr.data-value="{{param.value}}" (click)="clearParam($event)" class="search-param-exit">&times;</span>
          </div>
        </div>
      </div>
        <div style="overflow-x: auto;">
        <jz-angular-org-chart style="padding: 100px 0 450px 0;width:max-content;background-color:white;border-radius: 20px;min-width: 100vw;min-height: 100vh;" [user]="user" [head]="head" [data]="orgData" [searchParams]="searchParams " [noZoomEffect]="noZoomEffect" [zoomIn]="zoomIn" [zoomOut]="zoomOut"></jz-angular-org-chart>
        </div>
        <!-- zoom i/out controllers box -->
      <div class="d-flex flex-column" style="position:fixed;background-color: #f4f4f4; border-radius: 12px; bottom: 50px; margin-left:30px; width: 50px; height: 80px; border: 1px solid #f4f4f4; align-items: center; box-shadow: 0 0.5rem 1rem #777 !important; justify-content: center; z-index: 100">
        <svg style="cursor: pointer"  (click)="onClickZoomIn()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
          <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/>
          <path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"/>
        </svg><br>
        <svg style="cursor: pointer" (click)="onClickZoomOut()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-zoom-out" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
          <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/>
          <path fill-rule="evenodd" d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </div>

    </div>
  </div>

</div>



<!--employee detail card-->
<div class="sticky d-flex flex-column detailed-user-modal" *ngIf="openUserDetailedView">
  <div class="d-flex flex-row justify-content-start align-items-center blue-back">
    <img class="md-avatar org-prof-pic" [src]="modalImage ? modalImage : 'assets/org-chart/pic-placeholder.png'" alt="profile-pic" />
    <div class="info-block">
      <div class="org-chart-entity-name" style="color: #f4f4f4; font-size: 12px">{{modalPerson}}</div>
      <div class="org-chart-entity-type" style="color: #f4f4f4; font-size: 10px">{{modalPosition}}</div>
    </div>
    <span class="text-center close-div-btn" (click)="closeUserDetailView()">X</span>
  </div>
  <div class="d-flex flex-column extra-info-block">
    <h5>Details</h5>
    <p>Company: {{modalCompany | uppercase}}</p>
    <p>Department: {{modalDepartment | uppercase}}</p>
    <p >Division: {{modalDivision | uppercase}}</p>
    <p>Email: {{modalEmail}}</p>
    <p>{{modalOffice | uppercase}}</p>
  </div>
</div>
