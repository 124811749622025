<div class="resource-list-container">
  <span style="background: #9B46BB;
          border-radius: 20px;
          color: #9B46BB;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 18px">-</span>
  <h5 style="font-weight: bold">Meet Your HR Team</h5>
  <div class="d-flex flex-column justify-content-center h-100">
    <div class="resource-container">
      <carousel style="width: 80%">
        <slide *ngFor="let slide of staff">
          <div class="hr-team text-center">
            <img
              [src]="slide.profile_picture_url ? slide.profile_picture_url : 'assets/new_placeholder.png'"
              alt="user-logo"
            >
            <div class="text-center" style="margin: auto; width: 100%">
              <p class="header-name">{{slide.name}}</p>
              <p class="position-title">{{(slide.job_title.length > 20) ? (slide.job_title | slice: 0: 20) + '...' : (slide.job_title)}}</p>
              <button type="button" class="btn btn-outline-primary" [routerLink]="['/human-resources/hr-team-list']">
                View All
              </button>
            </div>


          </div>
        </slide>
      </carousel>
      <!--    <div class="control-sec">-->
      <!--      <button type="button" class="btn btn-outline-primary" [routerLink]="['/human-resources/hr-team-list']">-->
      <!--        View All-->
      <!--      </button>-->
      <!--    </div>-->
    </div>
  </div>
</div>
