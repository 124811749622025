import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Employee} from '../models/employee';
import {Hierarchy} from '../models/hierarchy';

@Injectable({
  providedIn: 'root'
})
export class FetchingDataService {
  url = environment.apiUrl;
  local = 'http://localhost:8000/api/v1/';

  constructor(private http: HttpClient) { }

//  public method to fetch data from new employee table
  fetchData(): Observable<Employee> {
    return this.http.get<Employee>(this.url + 'employees/ceo/');
  }

  fetchEmployeePerNumber(numberId: string): Observable<Employee> {
    return this.http.get<Employee>(this.url + 'employees/details/' + numberId + '/');
  }

  // fetching departments
  fetchAllDepartments(): Observable<any> {
    return this.http.get<any>(this.url + 'employees/departments');
  }

//  fetching divisions
  fetchAllDivisions(): Observable<any> {
    return this.http.get<any>(this.url + 'employees/divisions');
  }

  // fetching locations
  fetchAllLocations(): Observable<any> {
    return this.http.get<any>(this.url + 'employees/locations');
  }

  //  searching employees
  fetchSearchByEmployees(name: any, department: any): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.url + 'employees/search?name=' + name + '&department=' + department);
  }

  //  searching the employee hierarchy array
  fetchSearchHierarchyByEmployee(employeeNumber: any): Observable<Hierarchy> {
    return this.http.get<Hierarchy>(this.url + 'employees/hierarchy/' + employeeNumber);
  }

  // fetch employees by department
  fetchAllEmployeesByDepartment(department: string): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.url + 'employees/search?department=' + department);
  }

  // search employee by email to be used for the profile section
  fetchSearchByEmail(email: any): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.url + 'employees/search?email=' + email);
  }
}
