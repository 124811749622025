import { Component, OnInit } from '@angular/core';
import {UsersService} from '../core/users.service';
import {Employee} from '../new-org-chart/models/employee';

@Component({
  selector: 'jz-happybirthday',
  templateUrl: './happybirthday.component.html',
  styleUrls: ['./happybirthday.component.scss']
})
export class HappybirthdayComponent implements OnInit {

  users: Employee[];
  errorMessage: string;
  today = new Date();
  day: number;
  month: number;
  year: number;
  months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  };
  separator = '-';

  constructor(
    private usersService: UsersService
  ) { }

  ngOnInit(): void {

    // dates calculation
    this.day = this.today.getDate();
    this.month = this.today.getMonth() + 1;
    this.year = this.today.getFullYear();

    this.fetchUsers(this.month);
  }

  // convertDateFromIntToString(month) {
  //   const result = this.months[month];
  //   // console.log(result);
  //   return result;
  // }

  stringToSplit(birthdate: string, separator: any) {
    const arrayOfStrings = birthdate.split(separator);
    const month = arrayOfStrings[1];
    const intMonth = parseInt(month, 10);
    return this.months[intMonth];
  }

  stringToSplitToDay(birthdate: string, separator: any) {
    const arrayOfStrings = birthdate.split(separator);
    return arrayOfStrings[2];
  }

  // private functions below
  private fetchUsers(month) {
    this.usersService.getUsersByDatesInArray(month).subscribe({
      next: response => {
        this.users = response;
        // console.log(this.users);
      },
      error: err => this.errorMessage = err
    });
  }

}
