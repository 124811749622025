<div
  class="dialog-container"
  [ngStyle]="{
    'background-color': resourceCat.color,
    'min-height': (resourceCat.name === 'Human Resources' && innerWidth <= 576) ? '465px' : ((resourceCat.name === 'Human Resources' && innerWidth > 576) ? '375px' : 'unset')
  }"
>
  <div class="close-container" mat-dialog-close>
    <h1 style="color: white">{{resourceCat.name}}</h1>
    X
  </div>

  <mat-tab-group
    class="hr-tab-group"
    mat-align-tabs="start"
    *ngIf="resourceCat.sub_cats.length > 0"
  >
    <mat-tab [label]="cat.name" *ngFor="let cat of resourceCat.sub_cats">
      <ng-template matTabContent>
        <div class="row">
          <div
            *ngFor="let res of cat.res_by_sub_cat"
            [ngClass]="{'col-md-6': cat.res_by_sub_cat.length > 1}"
            class="col-12"
          >
            <div class="resource">
              <img
                [src]="res.resource_type === 'pdf' ? 'assets/home-cards/pdf-icon.png' : 'assets/home-cards/link-icon.png'"
                alt="resource type icon"
                style="margin-right: 10px"
              >
              <a
                [href]="(res.resource_type === 'pdf' || res.resource_type === 'xlsx') ?
                res.resource_url : res.external_link ? res.external_link : res.resource_link"
                target="_blank">
                {{res.name}}
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div class="row categories-resources"
       *ngIf="resourceCat.sub_cats.length === 0;">

    <!--   EXCEPTION FOR INTERNAL APP ROUTE AS A RESOURCE ( /parking list )     -->
    <div *ngIf="resourceCat.name === 'Parking Information'" class="resource">
      <img
        [src]="'assets/home-cards/link-icon.png'"
        alt="resource type icon"
      />
      <a style="margin-left: 10px;" [routerLink]="['/parking-list']" mat-dialog-close>Parking List</a>
    </div>
    <!--  hard coded resource end   -->

    <div
      [ngClass]="{'col-md-6': resourceCat.res_by_cat.length > 1}"
      *ngFor="let resource of resourceCat.res_by_cat"
      class="col-12"
    >
      <div class="resource">
        <img
          [src]="resource.resource_type === 'pdf' ? 'assets/home-cards/pdf-icon.png' : 'assets/home-cards/link-icon.png'"
          alt="resource type icon"
        />
        <a
          style="margin-left: 10px;"
          [href]="(resource.resource_type === 'pdf' || resource.resource_type === 'xlsx') ?
           resource.resource_url : resource.external_link ? resource.external_link : resource.resource_link"
          [target]="'_blank'"
        >
          {{resource.name}}
        </a>
      </div>
    </div>
  </div>
</div>
