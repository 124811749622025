import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JeducationMessage} from './models/jeducationMessage';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JeducationService {
  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  sendTraining(jeducationMessage: JeducationMessage) {
    return this.http.post(this.url + 'notifications/training', jeducationMessage, {observe: 'response'});
  }
}
