import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment';
import {Post} from './models/post';
import {Slide} from './models/slide';
import {ResourceCategory} from './models/resource';
import {IResponse} from './models/response';
import {Posts2} from './models/paginatedPosts2ListResponse';


@Injectable({
  providedIn: 'root'
})
export class PostsService {
  url = environment.apiUrl;
  resourceCat: ResourceCategory;
  maxHeight = 398;

  constructor(
    private http: HttpClient
  ) { }

  getPosts(categoryId: number): Observable<Post[]> {
    return this.http.get<Post[]>(this.url + 'posts/all?category=' + categoryId);
  }

  getPost(id: number): Observable<Posts2[]> {
    return this.http.get<Posts2[]>(this.url + 'posts2/' + id);
  }

  // using POSTS2
  getPostSlider(): Observable<Slide[]>{
    return this.http.get<Slide[]>(this.url + 'posts2/carousel');
  }

  getCategoriesWithResources(): Observable<ResourceCategory[]> {
    return this.http.get<ResourceCategory[]>(this.url + 'resources/all_by_categories');
  }

  setResource(cat: ResourceCategory) {
    this.resourceCat = cat;
  }

  setMaxHeight(height: number) {
    this.maxHeight = height;
  }

// new service to search by Posts per the global search request
  getPostsBySearch(filterBy: string, page?: number): Observable<IResponse<Post>> {
    if (filterBy) {
      return this.http.get<IResponse<Post>>(this.url + 'posts/all_paginated?search=' + filterBy + (page ? '&page=' + page : ''));
    }
  }

}
