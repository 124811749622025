import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IQuestions} from '../models/questions';
import {IResponses} from '../models/responses';

@Injectable({
  providedIn: 'root'
})
export class ProfileQuestionsServiceService {
  url = environment.apiUrl;
  errorMessage: string;

  constructor(private http: HttpClient) { }

  // fetch questions
  fetchQuestionsData(): Observable<IQuestions[]> {
    return this.http.get<IQuestions[]>(this.url + 'user_profiles/components/');
  }

  // fetch user responses
  fetchUserResponses(): Observable<IResponses[]> {
    return this.http.get<IResponses[]>(this.url + 'user_profiles/responses/');
  }

  fetchUserResponsesById(sortID: number): Observable<IResponses[]> {
    return this.http.get<IResponses[]>(this.url + 'user_profiles/responses/' + sortID + '/');
  }

  fetchResponsesArray(userEmail: string): Observable<any> {
    return this.http.get<any>(this.url + 'user_profiles/responses/by_email/' + userEmail + '/');
  }

  // fetch responses by email - tu use it for the org chart
  fetchResponseByUsersEmail(userEmail: string): Observable<any> {
    return this.http.get<any>(this.url + 'user_profiles/responses/by_email/' + userEmail + '/');
  }

}
