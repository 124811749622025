import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {SubmissionService} from '../submission.service';

@Injectable()
export class VotedCheckResolver implements Resolve<boolean> {

  constructor(
    private subService: SubmissionService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.subService.checkVoted();
  }
}
