import {Directive, ElementRef, HostListener, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[jzOrgChartZoomEffect]'
})
export class OrgChartZoomEffectDirective implements OnInit {
  zoomInElem: ElementRef;
  zoomOutElem: ElementRef;
  scaleValue = 10;

  constructor(private elm: ElementRef, private renderer2: Renderer2) {
  }

  ngOnInit(): void {
    this.zoomInElem = this.elm.nativeElement.querySelector();
    this.zoomOutElem = this.elm.nativeElement.querySelector();
  }

  // @HostListener('click') onClick() {
  //   console.log(this.scaleValue++);
  // }
  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    if (this.elm.nativeElement.contains(event.target)) {
      console.log(this.scaleValue++);
    }
  }
}
