import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {BluejCategoriesService} from '../bluej-categories.service';
// import {BluejCategory} from '../models/bluej-category';
import {BluejCategories} from '../models/bluej-categories';


@Injectable({
  providedIn: 'root'
})
export class BluejCatBannerResolver implements Resolve<BluejCategories[]> {
  constructor(
    private bluejCategory: BluejCategoriesService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<BluejCategories[]> | Promise<BluejCategories[]> | BluejCategories[] {
    return this.bluejCategory.getBluejCat(route.paramMap.get('slug'));
  }
}
