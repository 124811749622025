import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ICar} from '../models/jpark';
import {JparkService} from '../jpark.service';

@Injectable()
export class JparkResolver implements Resolve<ICar[]> {

  constructor(
     private jparkService: JparkService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ICar[]> | Promise<ICar[]> | ICar[] {
     return this.jparkService.getAllCars();
  }
}
