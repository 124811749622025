<div id="right-content">
  <!--  Blue j-->
  <div *ngFor="let post of posts">
    <div id="blue-j" *ngIf="post.category == 'bluej'">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>Submit Your Ideas</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div id="ideas-j">
              <h5>We know you have brand ideas and now you have a place to share them</h5>
              <span>@12:00pm</span>
              <p>Click here to share your ideas! ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  post list -->
  <div class="container">
    <div class="row">
      <div
        class="col-md-4"
        style="cursor:pointer; margin-bottom: 25px;"
        *ngFor="let post of posts"
        [routerLink]="[post.pk]"
      >
        <mat-card style="height: 380px; width: 330px; border-radius: 19px; background-color: #f4f4f4">
          <img style="height: 55%; object-fit: cover;" mat-card-image [src]="post.card_image">
          <mat-card-content style="overflow-y: hidden;">
            <h4 style="color: #3551da; overflow: hidden; text-overflow: ellipsis;">{{post.headline}}</h4>
            <div style="padding: 5px;">
              <p>{{post.short_description}}</p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
