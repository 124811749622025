import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {PostsService} from '../posts.service';
import {Posts2} from '../models/paginatedPosts2ListResponse';

@Injectable()
export class PostResolver implements Resolve<Posts2[]> {

  constructor(
    private postsService: PostsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Posts2[]> | Promise<Posts2[]> | Posts2[] {
    return this.postsService.getPost(+route.params.id);
  }
}
