import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {IResource} from './models/resource';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {IResponse} from './models/response';
import {IResource2, Resource2Category} from './models/resource2';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  private url = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  // method to get specifics resources via input text from users
  getResources(filterBy: string, page?: number): Observable<IResponse<Resource2Category>> {
    if (filterBy) {
      return this.http.get<IResponse<Resource2Category>>(
        this.url + 'resources2/all?search=' + filterBy + (page ? '&page=' + page : '')).pipe(
        catchError((this.handleError))
      );
    }
  }

  // method to get the next page or previous
  getNextOrPreviousResources(newUrl: string): Observable<IResponse<IResource2>> {
    return this.http.get<IResponse<IResource2>>(newUrl).pipe(
      catchError((this.handleError))
    );
  }

  // method to get all resources
  getAllResources(): Observable<IResponse<IResource>> {
    return this.http.get<IResponse<IResource>>(this.url + 'resources/all').pipe(
      catchError(this.handleError)
    );
  }

  // method to handle the error
  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
