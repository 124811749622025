<div style="padding: 20px;">
  <h2 mat-dialog-title>This Action is Final!</h2>
  <div mat-dialog-content>
    After you vote, it won't be possible to change your answer!

    <div id="buttons-container">
      <button mat-button (click)="onAction('cancel')">Cancel</button>
      <button mat-button (click)="onAction('vote')">Vote</button>
    </div>
  </div>
</div>
