<div class="container h-100" id="weather-card">
    <div class="right-claret-arrow">
      <a
         [popover]="popup"
         [outsideClick]="true"
         #pop2="bs-popover"
      >
        <i class="fas fa-sort-down"></i>
      </a>
    </div>

    <div class="row">
      <div class="d-flex flex-row" style="width: 100%">
        <div id="weather-img" style="margin: auto">
          <img class="img-fluid" src="assets/weather/{{weather ? weather.weather[0].icon : '01d'}}.png" alt="weather-logo">
        </div>
        <div id="weather-info" style="margin: auto">
          <p id="temp-degree">{{weather ? (weather.main.temp | number:'1.0-0') : ''}}°</p>
          <p id="full-date">{{today | date: 'fullDate' }}</p>
          <p id="city-location">{{selectedCityName}}</p>
          <p id="timestamp">{{timezone}}</p>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12" id="user-section">
        <p id="welcome-text">Welcome!</p>
        <p id="username">{{user ? user.name : ''}}</p>
      </div>
    </div>

</div>

<!-- start popover template for citi -->
<ng-template #popup>
  <div style="padding: 10px; background: transparent">
    <div id="cities-list">
      <div class="city-item" *ngFor="let city of cities">
        <div
          (click)="setCityWeather(city.name, city.timezone); pop2.hide()"
        >
          <p>•  {{city.displayName?city.displayName:city.name}}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- ends popover template  -->
