import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Recognition} from '../core/models/employee';

@Component({
  selector: 'jz-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  recognitions: Recognition[];

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.recognitions = data.recognitions;
    });
  }
}
