import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment';
import {Iyear} from './models/year';
import {IeventDetail} from './models/event-detail';
import {CalendarEvent} from './models/calendar-event';
import {GlobalEvents} from './models/global-events';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  url = environment.apiUrl;


  constructor(
    private http: HttpClient
  ) { }

  getEvent(): Observable<Iyear>{
    return this.http.get<Iyear>(this.url + 'events/by_year');
  }

  getEventDetails(id: number): Observable<IeventDetail> {
    return this.http.get<IeventDetail>(this.url + 'events/' + id);
  }

  getCalendarEvents(category: number, minDate: string, maxDate: string): Observable<CalendarEvent[]> {
    return this.http.get<CalendarEvent[]>(this.url + `events/for_calendar?category=${category}&min_date=${minDate}&max_date=${maxDate}`);
  }

  //  adding new methods to fetch the global_events

  getAllGlobalEvents(): Observable<GlobalEvents> {
    return this.http.get<GlobalEvents>(this.url + 'events/all');
  }

  getGlobalEventsByDates(startDate: string, endDate: string): Observable<GlobalEvents[]> {
    return this.http.get<GlobalEvents[]>(this.url + `events/all?&start_date=${startDate}&end_date=${endDate}`);
  }

  getUpcomingEvents(): Observable<GlobalEvents[]> {
    return this.http.get<GlobalEvents[]>(this.url + 'events/upcoming_events');
  }

}
