<div id="right-content">
  <div class="container">


    <!-- Spinner start -->
    <ngx-spinner
      bdColor="rgba(51,51,51,0.3)"
      size="medium"
      color="white"
      type="ball-climbing-dot"
      [fullScreen]="true"
    ></ngx-spinner>
    <!-- Spinner end -->

    <div class="row">

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
        <div class="card-height left-card">
          <div class="img-container text-center">
            <!--            <img src="assets/compliance/Corportate-Compliance.jpg" class="img-fluid" alt="picture">-->
          </div>
          <div class="card-body">
            <p class="card-title">Jazmanians</p>
            <p class="card-text">
              Learn more about Jazmanians from around the world.
            </p>
            <div class="cont-content" *ngIf="resources2">
              <div *ngFor="let resource of resources2[0].resources">
                <a
                  class="resource-container"
                  [href]="(
                           resource.resource_type === 'pdf' ||
                           resource.resource_type === 'xlsx' ||
                           resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                  [target]="'_blank'"
                  style="text-decoration: none; color: black;"
                >
                  <img
                    alt="file type"
                    [src]="resource.resource_type === 'pdf' ? 'assets/resources-colored-icon.png' : 'assets/links-logo.png'"
                  />
                  <p>{{resource.name}}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-8 mt-0 mt-4 mt-md-0">
        <!--posts-->
        <jz-common-post-list [listOfPosts2]="listOfPosts2" [resources2]="resources2" [totalPages]="totalPages" [page]="page" [cat]="cat"></jz-common-post-list>
      </div>
    </div>

  </div>
</div>
