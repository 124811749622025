<div id="right-content">
  <div class="container-fluid">

    <!-- back button -->
    <div
      class="container-fluid button-container"
      [routerLink]="['/', 'human-resources']"
    >
      <i class="fas fa-caret-left" style="color: #FFFFFF;"></i>
      <button>
        Back
      </button>
    </div>

    <div class="white-section">
      <div class="row">
        <div class="col-12">
          <h4 class="filsonProMed">Meet your HR Team!</h4>
        </div>
      </div>
      <div class="purple-line"></div>

      <div class="row" id="employee-section">
        <!--first employee-->
        <div class="col-lg-6 col-md-12" *ngFor="let element of staffNew">
          <div class="row">
            <!-- profile image -->
            <div class="col-4">
              <img class="md-avatar mt-2 mb-3" id="profile-pic"
                   [src]="element.profile_picture_url ? element.profile_picture_url : 'assets/new_placeholder.png'" alt="user-logo">
            </div>
            <!-- Profile content -->
            <div class="col-8">
              <div class="mt-2 d-flex align-items-center">
                <p class="align-middle">
                  {{element.name}}<br>
                  <span>{{element.job_title}}</span><br>
                  <span style="color: #707070">{{element.location}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

