import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Post} from '../models/post';
import {PostsService} from '../posts.service';
import {CategoriesService} from '../categories.service';
import {PostCategory} from '../models/postCategory';

@Injectable()
export class PostsResolver implements Resolve<Post[]> {
  cats = {};

  constructor(
    private postsService: PostsService,
    private categoriesService: CategoriesService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Post[]> | Promise<Post[]> | Post[] {
    if (this.categoriesService.categories[route.params.category]) {

      const categories = this.categoriesService.categories;
      return this.postsService.getPosts(categories[route.params.category]);

    } else {
      this.categoriesService.getCategories().subscribe((cats: PostCategory[]) => {
        this.categoriesService.setCategories(cats);
        this.cats = this.categoriesService.categories;
        return this.postsService.getPosts(this.cats[route.params.category]);
      });
    }
  }
}
