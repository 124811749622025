<div class="bj-card" id="right-content">

<!--  <div class="row">-->
<!--    <button (click)="goBack()" class="back-button">Back</button>-->
<!--  </div>-->

  <!-- back button -->
  <div class="container-fluid button-container"
       [routerLink]="['/']"
  >
    <i class="fas fa-caret-left" style="color: #FFFFFF;"></i>
    <button>
      Back
    </button>
  </div>

  <!-- Bluej-Posts-Detail banner here -->
  <div class="container" [ngStyle]="{'background-color': catArray['color']}">

    <!-- condition for Jtank -->
    <a class="cat-link text-center" href="{{catArray['external_link']}}" target="_blank"
       (mouseover)= "hover=true"
       (mouseleave)="hover=false"
       [ngStyle]="{
        'background': (hover) ? '#F4F4F4' : '#F5AD00',
        'color': (hover) ? '#F5AD00' : '#F4F4F4',
        'border': (hover) ? '2px solid #F5AD00' : ''
       }"
       *ngIf="isExternal && catArray['name'] === 'jtank'">
      {{catArray['external_link_cta']}}
    </a>
    <a class="cat-link text-center" href="{{catArray['external_link']}}" target="_blank"
       (mouseover)= "hover=true"
       (mouseleave)="hover=false"
       [ngStyle]="{
        'background': (hover) ? '#F4F4F4' : '#F5AD00',
        'color': (hover) ? '#F5AD00' : '#F4F4F4',
        'border': (hover) ? '2px solid #F5AD00' : ''
       }"
       *ngIf="!isExternal && catArray['name'] === 'jtank'">
      {{catArray['external_link_cta']}}
    </a>

    <!-- condition for Jtrends -->
    <a class="cat-link text-center" href="{{catArray['external_link']}}" target="_blank"
       (mouseover)= "hover=true"
       (mouseleave)="hover=false"
       [ngStyle]="{
        'background': (hover) ? '#F4F4F4' : '#4cad1c',
        'color': (hover) ? '#4cad1c' : '#F4F4F4',
        'border': (hover) ? '2px solid #4cad1c' : ''
       }"
       *ngIf="isExternal && catArray['name'] === 'jtrends'">
      {{catArray['external_link_cta']}}
    </a>
    <a class="cat-link text-center" href="{{catArray['external_link']}}" target="_blank"
       (mouseover)= "hover=true"
       (mouseleave)="hover=false"
       [ngStyle]="{
        'background': (hover) ? '#F4F4F4' : '#4cad1c',
        'color': (hover) ? '#4cad1c' : '#F4F4F4',
        'border': (hover) ? '2px solid #4cad1c' : ''
       }"
       *ngIf="!isExternal && catArray['name'] === 'jtrends'">
      {{catArray['external_link_cta']}}
    </a>

    <!-- condition for Jinspo -->
    <a class="cat-link text-center" href="{{catArray['external_link']}}" target="_blank"
       (mouseover)= "hover=true"
       (mouseleave)="hover=false"
       [ngStyle]="{
        'background': (hover) ? '#F4F4F4' : '#9B46BB',
        'color': (hover) ? '#9B46BB' : '#F4F4F4',
        'border': (hover) ? '2px solid #9B46BB' : ''
       }"
       *ngIf="isExternal && catArray['name'] === 'jinspo'">
      {{catArray['external_link_cta']}}
    </a>
    <a class="cat-link text-center" href="{{catArray['external_link']}}" target="_blank"
       (mouseover)= "hover=true"
       (mouseleave)="hover=false"
       [ngStyle]="{
        'background': (hover) ? '#F4F4F4' : '#9B46BB',
        'color': (hover) ? '#9B46BB' : '#F4F4F4',
        'border': (hover) ? '2px solid #9B46BB' : ''
       }"
       *ngIf="!isExternal && catArray['name'] === 'jinspo'">
      {{catArray['external_link_cta']}}
    </a>

    <!-- condition for Jretail -->
    <a class="cat-link text-center" href="{{catArray['external_link']}}" target="_blank"
       (mouseover)= "hover=true"
       (mouseleave)="hover=false"
       [ngStyle]="{
        'background': (hover) ? '#F4F4F4' : '#E6258B',
        'color': (hover) ? '#E6258B' : '#F4F4F4',
        'border': (hover) ? '2px solid #E6258B' : ''
       }"
       *ngIf="isExternal && catArray['name'] === 'jretail'">
      {{catArray['external_link_cta']}}
    </a>
    <a class="cat-link text-center" href="{{catArray['external_link']}}" target="_blank"
       (mouseover)= "hover=true"
       (mouseleave)="hover=false"
       [ngStyle]="{
        'background': (hover) ? '#F4F4F4' : '#E6258B',
        'color': (hover) ? '#E6258B' : '#F4F4F4',
        'border': (hover) ? '2px solid #E6258B' : ''
       }"
       *ngIf="!isExternal && catArray['name'] === 'jretail'">
      {{catArray['external_link_cta']}}
    </a>

    <div class="row" id="banner-content">
      <div class="col-lg-3 col-md-3 col-sm-4" style="position: relative">
        <img class="img-fluid" id="bluej-banner-logo" [src]="catArray['bird_logo']" alt="logo">
      </div>
      <div class="col-lg-9 col-md-9 col-sm-8">
        <div class="row" style="margin-top: 25px">
          <div class="col-8" *ngIf="catArray['name'] === 'jtrends'">
            <img class="img-fluid" [src]="catArray['text_logo']"
                 alt="logo" style="margin-top: 16px; width: 25%">
          </div>
          <div class="col-8" *ngIf="catArray['name'] !== 'jtrends'">
            <img class="img-fluid" [src]="catArray['text_logo']"
                 alt="logo" style="margin-top: 16px; width: 25%; margin-left: -16px">
          </div>
        </div>
        <div class="row" style="margin-top: 15px">
          <div class="col-12">
            <p>{{catArray['long_description']}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- contest image 01 -->
  <div class="container" id="j-content" *ngFor="let post of posts">
      <a [href]="post.external_link"
         target="_blank">
        <img class="img-fluid" [src]="post.banner_img"
             alt="jtank contest">
      </a>
  </div>


</div>
