import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ResourcesService} from '../core/resources.service';
import {PostsService} from '../core/posts.service';
import {Post} from '../core/models/post';
import {IResponse} from '../core/models/response';
import {PageChangedEvent} from 'ngx-bootstrap';
import {Resource2Category} from '../core/models/resource2';
import {Posts2Service} from '../core/posts2.service';

@Component({
  selector: 'jz-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
  userInput = '';
  errorMessage = '';
  allSelected = true;
  checkList = [
    {id: 1, value: 'Posts/News', isSelected: true},
    {id: 2, value: 'HR Resources', isSelected: true},
  ];
  searchPerformed = false;

  resourceResponseObj: IResponse<Resource2Category> = {next: '', previous: '', count: 0, results: []};
  postResponseObj: IResponse<Post> = {next: '', previous: '', count: 0, results: []};


  // working on the search params
  search: string; // search can be my userInput
  params = {
    search: ''
  };
  count: number;

  // getters and setters below
  get listFilter(): string {
    return this.userInput;
  }

  set listFilter(value: string) {
    this.userInput = value;
    // console.log('userInput is: ', +this.userInput);
    // console.log('In setter: ', value);
  }

  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private router: Router,
    private postService: PostsService,
    private posts2Service: Posts2Service,
    // private spinner: NgxSpinnerService,
  ) {
  }

  // handle functionality for a keyboard event - this case pressing "Enter"
  @HostListener('window: keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.performSearch();
    }
  }

  ngOnInit(): void {
    // todo: inspect route for query params
    this.checkParamsAndFilter();
  }

  // public functions below ...
  performSearch(): void {
    // todo: add query params to route
    const searchBy = this.userInput;
    if (searchBy) {
      this.searchPerformed = true;
      // for resources
      if (this.checkList[1].isSelected === true) {
        this.fetchResources(searchBy);
      } else if (this.checkList[1].isSelected === false) {
        this.resourceResponseObj.results = [];
      }
      // for posts
      if (this.checkList[0].isSelected === true) {
        this.fetchPosts(searchBy);
      }
      // at the end update route url
      this.updateRouteParams(this.userInput);
    }
  }

  onPageChangedForResources(event: PageChangedEvent): void {
    this.fetchResources(this.userInput, event.page);
  }

  onPageChangedForPosts(event: PageChangedEvent): void {
    this.fetchPosts(this.userInput, event.page);
  }

  // todo: same as above for posts.

  isExternal(link: string) {
    return link.startsWith('http');
  }

  resetSearch(): void {
    this.userInput = '';
    this.resourceResponseObj.results = [];
    this.postResponseObj.results = [];
    this.searchPerformed = false;
    this.resourceResponseObj = this.postResponseObj = {next: '', previous: '', results: [], count: 0};
    this.updateRouteParams('');
  }

  // private functions below ...
  private checkParamsAndFilter() {
    this.route.queryParams.subscribe((params: Params) => {
      if (params.search) {
        this.params.search = params.search;
      }
    });

    this.userInput = this.params.search;
    // checks params and do all searches in case of refresh
    if (this.params.search) {
      this.performSearch();
    }
  }

  private updateRouteParams(param: string): void {
    this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams: {search: param},
        queryParamsHandling: 'merge'
      });
  }

  private fetchResources(searchBy, page?) {
    this.resourcesService.getResources(searchBy, page).subscribe({
      next: response => {
        this.resourceResponseObj = response;
        console.log('resources', this.resourceResponseObj.results);
      },
      error: err => this.errorMessage = err
    });
  }

  private fetchPosts(searchBy, page?) {
    this.posts2Service.getPostsBySearch(searchBy, page).subscribe({
      next: response => {
        this.postResponseObj = response;
      },
      error: err => this.errorMessage = err
    });
  }

}
