<div id="right-content">
  <div class="container" style="padding-bottom: 40px">

    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="container-block" style="height:909px">
          <span style="background: #FFC400;
          border-radius: 20px;
          color: #FFC400;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 21px">-</span>
          <h4 class="text-font">J Resources</h4>
          <img src="assets/jtraining/jtraining-icons-resource.png" class="img-fluid" alt="picture">
          <div class="card-body">
            <p class="card-text">Looking for a form, an SOP or other resources? You’ll find it here on JResources.
              Can’t find what you’re looking for?
            </p>
            <p class="card-text">Check out Jazwiki</p>
            <div class="text-left">
              <img style="width: 30%" src="assets/human-resources/learning-hub/Jazwiki_Logo_FINAL-01.png" alt="placeholder Logo">
            </div>
            <div class="resource-button text-center">
              <a href="https://jazwares.bizlibrary.com" target="_blank">Click Here</a>
            </div>
            <p class="card-text">or fill out the HR Request Form</p>
            <div class="askHrContainer text-center">
              <a href="https://jazwares.employee.hrsd.ultipro.com/" target="_blank"><img class="askHrGraphic" src="assets/human-resources/ask-hr-02.png" alt="user-logo"></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 mt-4 mt-lg-0 mobile-separation">
        <div class="container-block" style="margin-bottom: 20px; height: 860px">
          <span style="background: #FFC400;
          border-radius: 20px;
          color: #FFC400;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 21px">-</span>
          <h4 class="text-font">Click each box to find the resources you need</h4>
          <div style="overflow-y: auto; overflow-x: hidden; height: 88%">
            <mat-accordion id="{{'custom-acc-' + category.id}}" class="jResources-custom-mat-accordion" *ngFor="let category of resources2Training; index as i">
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{category.cat}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let resource of category.resources">
                  <a class="resource-container" [href]="(
                      resource.resource_type === 'pdf' ||
                      resource.resource_type === 'xlsx' ||
                      resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                     [target]="'_blank'"
                  >
                    <img style="width: 5%" alt="file type" [src]="resource.resource_type === 'pdf' ? 'assets/pdf-logo.png' : 'assets/links-logo.png'"/>
                    <p>{{resource.name}}</p>
                  </a>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
