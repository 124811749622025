import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Diversity} from './models/diversity';

@Injectable({
  providedIn: 'root'
})
export class DiversityService {

  private url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  // method to get the resources
  getDiversityResources(): Observable<Diversity[]> {
    return this.http.get<Diversity[]>(
      this.url + 'resources2/get?app=resources2&model=category&category=Diversity and Inclusion'
    );
  }
}
