import { Component, OnInit } from '@angular/core';
import {Slide} from '../../core/models/slide';
import {CarouselConfig} from 'ngx-bootstrap/carousel';
import {PostsService} from '../../core/posts.service';

@Component({
  selector: 'jz-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers: [
    {provide: CarouselConfig,
      useValue: {interval: 5000, noPause: true,
                pauseOnFocus: false, showIndicators: true,
                animation: true}}
  ]
})
export class CarouselComponent implements OnInit {
  slides: Slide[];

  constructor(
    private postsService: PostsService,
  ) { }

  ngOnInit(): void {
    //  getting slider post
    this.postsService.getPostSlider().subscribe((slides: Slide[]) => {
      this.slides = slides;
    });
  }

}
