<div class="container h-100" id="events-card">

  <span style="background: #E63A95;
          border-radius: 20px;
          color: #E63A95;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 28px">-</span>
  <p class="header-title">Upcoming Events</p>

  <div class="container-fluid content-container">
    <div class="row">

        <div class="col-sm-12 col-md-12 col-lg-12" *ngFor="let event of upcomingEvents">
          <div class="row" style="margin-bottom: 10px">

            <div class="col-sm-4 col-md-4 col-lg-3">
              <div class="d-flex flex-column">
                <p *ngIf="convertStringToDateObj(event.datetime) !== convertStringToDateObj(event.datetime_end)" class="event-day">{{convertStringToDateObj(event.datetime)}}-{{convertStringToDateObj(event.datetime_end)}}</p>
                <p *ngIf="convertStringToDateObj(event.datetime) === convertStringToDateObj(event.datetime_end)" class="event-day">{{convertStringToDateObj(event.datetime)}}</p>
                <p class="event-date">{{convertStringToMonth(event.datetime)}}, {{convertStringToFullYear(event.datetime)}}</p>
              </div>
            </div>

            <div class="col-sm-8 col-md-8 col-lg-9" id="content-area">
              <div class="d-flex flex-column">
                <p>{{event.name}}</p>
                <!--condition for event_type = gallery-->
                <a  class="link-button"
                    *ngIf="event.event_type === 'gallery'"
                    [target]="(event.event_type === 'gallery') ? '' : '_blank'"
                    [routerLink]="event.event_type === 'gallery' ? ['/jevent/', event.id] : 'null'"
                    [ngStyle]="{
                      'background-color': '#E6258B',
                      'border-color': '#E6258B'
                    }"
                >
                  {{event.event_type === 'gallery' ? 'gallery' : event.external_link_cta ? event.external_link_cta : ''}}
                </a>
                <!--condition for event_type !== gallery-->
                <a  class="link-button"
                    *ngIf="event.event_type !== 'gallery'"
                    [href]="event.event_type === 'pdf' ? event.pdf_url : event.external_link ? event.external_link : ''"
                    [target]="(event.event_type === 'link') ? '_blank' : event.event_type === 'pdf' ? '_blank' : ''"
                    [ngStyle]="{
                      'background-color': '#54C8E8',
                      'border-color': '#54C8E8'
                    }"
                >
                  {{event.event_type === 'gallery' ? 'gallery' : event.external_link_cta ? event.external_link_cta : ''}}
                </a>
              </div>
            </div>

            <mat-divider></mat-divider>

          </div>
        </div>

    </div>

  </div>
</div>
