import { Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {PaginatedPosts2ListResponse, Posts2} from '../core/models/paginatedPosts2ListResponse';
import {IResource2} from '../core/models/resource2';
import {Posts2Service} from '../core/posts2.service';
import {Resources2Service} from '../core/resources2.service';

@Component({
  selector: 'jz-jwellness',
  templateUrl: './jwellness.component.html',
  styleUrls: ['./jwellness.component.scss']
})
export class JwellnessComponent implements OnInit {

  cat = 'jwellness';
  paginatedPosts2ListResponse: PaginatedPosts2ListResponse;
  listOfPosts2: Posts2[];
  catForResources = 'jwellness';
  resources2: IResource2[];
  page = 1;
  totalPages: number;
  errorMessage = '';

  constructor( private posts2Service: Posts2Service,
               private resourceService: Resources2Service,
               private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.fetchPosts2();
    this.fetchResources2();
  }

  private fetchResources2() {
    this.resourceService.getResources2ByCat(this.catForResources).subscribe({
        next: response => {
          if (response.length) {
            this.resources2 = response;
            // console.log(this.resources2);
          }
        }
      }
    );
  }

  private fetchPosts2() {
    this.spinner.show();
    this.posts2Service.getPosts2PaginatedByCategory(this.cat, this.page).subscribe({
      next: response => {
        this.paginatedPosts2ListResponse = response;
        // this.posts2List = response[0].posts;
        // console.log('nothing to show?', this.paginatedPosts2ListResponse);
        this.listOfPosts2 = this.paginatedPosts2ListResponse.posts;
        this.totalPages = this.paginatedPosts2ListResponse.total_pages;
        this.spinner.hide();
      },
      error: err => this.errorMessage = err
    });
  }

}
