<div id="right-content">
  <div class="container-fluid">
    <div class="white-section">
      <div class="row">
        <div class="col-12">
          <h4 class="label-title">Happy Birthday!</h4>
        </div>
      </div>

      <div class="purple-line"></div>
      <img class="img-fluid confeti-design" src="assets/confeti.png" alt="confeti-img">

      <div class="row" id="employee-section">
        <div class="col-lg-6 col-md-12" *ngFor="let user of users">
          <div class="row">
            <!-- profile image -->
            <div class="col-4">
              <img class="img-fluid mt-2 mb-3" style="border-radius: 50%; width: 130px; height: 130px"
                   [src]="user.profile_picture_url ? user.profile_picture_url :
                   'assets/jazwares-placeholder-bday.jpg'" alt="user-logo">
            </div>
            <!-- Profile content -->
            <div class="col-6">
              <div class="mt-2 d-flex flex-column" style="height: 150px;">
                <p class="align-middle">
                  {{user.name}} <br>
                  <span>{{users ? stringToSplit(user.birthdate, separator) : 'oops'}},
                    {{users ? stringToSplitToDay(user.birthdate, separator) : 'oops'}}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

