import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jz-learning-hub',
  templateUrl: './learning-hub.component.html',
  styleUrls: ['./learning-hub.component.scss']
})
export class LearningHubComponent implements OnInit {

  text = 'The Learning Hub was designed to offer resources,\n' +
    '      in interesting and varied ways, that will help encourage\n' +
    '      all Jazmanians to develop independent learning skills\n' +
    '      and encourage a culture of curiosity.';

  constructor() { }

  ngOnInit(): void {
  }

}
