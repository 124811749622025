import { Component, OnInit } from '@angular/core';
import { AuthService, SocialUser } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { AuthorizationService } from '../core/authorization.service';
import { AuthResponse } from '../core/models/auth-response';
import { Router } from '@angular/router';

@Component({
  selector: 'jz-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private googleLoginService: AuthorizationService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  signInWithGoogle() {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((result: SocialUser) => {
        console.log(result);
        this.googleLoginService.setLoggedInUser(result);
        this.googleLoginService
          .getCustomUserProfile()
          .subscribe((data: any) => {
            console.log(data);
            localStorage.setItem('user', data.pk.toString());
            localStorage.setItem('email', data.email);
            this.router.navigate(['/home']);
          });
      });
  }
}
