import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ICar} from './models/jpark';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class JparkService {
  url = environment.apiUrl;
  constructor(
    private http: HttpClient
  ) { }
  getAllCars(): Observable<ICar[]>{
    return this.http.get<ICar[]>(this.url + 'cars/all');
  }
}
