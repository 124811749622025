import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jz-j-tank-rules',
  templateUrl: './j-tank-rules.component.html',
  styleUrls: ['./j-tank-rules.component.scss']
})
export class JTankRulesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
