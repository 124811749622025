<div id="right-content">
  <div id="top-bar">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h4><img src="../../../assets/jtank/jtank.png" width="100" alt="JTank logo">
            <div class="line-separator"></div>
            Rules / Instructions
          </h4>
        </div>
      </div>
    </div>
  </div>
  <!--    rules and instructions -->
  <div id="rules-instructions">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="grey-section">

            <div class="w-100 mb-4">
              <img src="../../../assets/jtank/challenge-intro-w-logo.png" alt="Challenge intro description graphic" class="w-100">
            </div>

            <div class="row mb-4">
              <div class="col-lg-6 col-md-12">
                <a style="border: none" routerLink="/bluej/jtank/submission"><img src="../../../assets/jtank/submit-your-idea.png" class="img-fluid" alt="jtank logo"></a>
              </div>
              <div class="col-lg-6 col-mg-12">
                <a style="border: none" routerLink="/bluej/jtank/ideas"><img src="../../../assets/jtank/enter-to-vote.png" class="img-fluid" alt="vote logo"></a>
              </div>
            </div>
            <h3>Rules / Instructions</h3>
            <p>
              Calling all Tinkers, Makers, and Dreamers! We’re so excited that you’re here. JTank’s mission is to bring
              bright ideas to life and push Jazwares to the top of the industry.
            </p>
            <p>
              JTank will be hosting these challenges quarterly with a variety of themes for each.
              We have lots of excitement in store for 2021!
            </p>
            <h4>Submissions:</h4>
            <p>Step 1: Click “Submit Idea”</p>
            <p>Step 2: This is where it gets fun! Give your idea a title, description, category, and upload (1) 11” x
              8.5” image (JPEG or PNG) that shows your idea. Don’t get overwhelmed though, this can also be a stick
              figure drawing on a napkin! We will treat both of them equally.</p>
            <p>Step 3: Press submit! All ideas will go live on the same date. So if you submit before the deadline, your
              idea will live in our incubator tank until the challenge is live! The JTank team will send out an email
              once we’re live.
            </p>
            <h4>Voting:</h4>
            <p>Voting is as easy as a thumbs up or thumbs down! We want to know what you think is going to be the next
              multimillion dollar brand. We encourage you all to go through each idea and vote for the ones you think
              are hot & trending. All votes are kept private so unfortunately, your BFF in Accounting won’t see if you
              voted for their idea.</p>
            <h4>JTank Panel: </h4>
            <p>At the end of the competition, we will be taking the top 5 ideas with the most up votes to our JTank
              Panel. Who’s on our JTank Panel you ask?! That’s a secret we’ll never tell. JK! It’s going to be Senior
              Leadership of all our major departments. They will decide if your idea will be the next BIG thing by
              awarding 1st, 2nd, and 3rd place. If your idea is chosen, CONGRATULATIONS, you will be contacted by the
              JTank team with next steps!</p>
            <h4>Prizes:</h4>
            <ul>
              <li>1st: $500 + (1) Vacation Day </li>
              <li>2nd: $250</li>
              <li>3rd: $100</li>
            </ul>
            <!--            <p class="date-anounce">Date: 00/00/00</p>-->
<!--            <div class="row">-->
<!--              <div class="col-md-6">-->
<!--                <div class="d-flex align-items-center">-->
<!--                  <img src="../../../assets/Bluej-Posts-Detail/img-placeholder.png" class="img-fluid">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-md-6">-->
<!--                <img src="../../../assets/Bluej-Posts-Detail/img-placeholder.png" class="img-fluid">-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row text-center button-sec">-->
<!--              <div class="col-md-6">-->
<!--                <a routerLink="/bluej/Bluej-Posts-Detail/submission">Submit your Idea</a>-->
<!--              </div>-->
<!--              <div class="col-md-6">-->
<!--                <a routerLink="/bluej/Bluej-Posts-Detail/ideas">Enter to Vote</a>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="grey-section">
            <h3>FAQ</h3>
            <p><strong>Q: How many images can we upload?</strong></p>
            <ul>
              <li>A: Just one (1) 11” x 8.5” image but you can fit as much or as little on that page as you’d like.
              </li>
            </ul>

            <p><strong>Q: Will my submission be final or can I come back and edit?</strong></p>
            <ul>
              <li>A: Your submission is final so check it twice! Although, if you are chosen for the JTank Panel,
                you will have the option to tweak your submission.
              </li>
            </ul>

            <p><strong>Q: Can I vote for my own idea?</strong></p>
            <ul>
              <li>A: Of course! You wanna win don’t ya?!
              </li>
            </ul>

            <p><strong>Q: How many times can I vote on an idea? </strong></p>
            <ul>
              <li>A: You can submit 1 vote per idea.
              </li>
            </ul>

            <p><strong>Q: Can I upload multiple ideas? </strong></p>
            <ul>
              <li>A: Ummmm… HECK YEA! Show us what you got!
              </li>
            </ul>


            <img class="mx-auto d-block jtank-blue" src="../../../assets/jtank/jtank-blue.png" width="193" alt="jtank blue logo">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
