<div class="bj-card" id="right-content" *ngIf="categories">

  <!-- Spinner start -->
<!--  <ngx-spinner-->
<!--    bdColor="rgba(51,51,51,0.3)"-->
<!--    size="large"-->
<!--    color="white"-->
<!--    type="ball-climbing-dot"-->
<!--    [fullScreen]="true"-->
<!--  ></ngx-spinner>-->
  <!-- Spinner end -->

  <img class="img-fluid" src="assets/bluej/BlueJ_Refactor/BlueJ-Image-for-background.png" alt="bluej pic"/>

  <!-- New BlueJ Banner start-->
<!--  <div class="container" id="banner-container">-->
<!--    <div class="row align-items-center" id="bluej-banner-div"  [routerLink]="['/bluej/team']" jzBluejBannerHover >-->
<!--      <div class="col-lg-5 col-md-5 col-sm-6 bluej-logo text-center">-->
<!--        <img class="img-fluid"-->
<!--             src="assets/bluej/BlueJ_Refactor/Logo-Blue-J-powered-by-jazwares.png"-->
<!--             alt="BlueJ-logo">-->
<!--      </div>-->
<!--      <div class="col-lg-4 col-md-4 col-sm-6 text-button">-->
<!--        <p class="text-center">Jazwares innovation arm of Blue Sky development</p>-->
<!--        <button class="button1" mat-button>-->
<!--          Learn about BlueJ-->
<!--        </button>-->
<!--      </div>-->
<!--      <img class="bird-img"-->
<!--           src="assets/bluej/BlueJ_Refactor/BlueJ%20bird%20logo.png"-->
<!--           alt="bluej-bird-logo">-->
<!--    </div>-->
<!--  </div>-->
  <!-- BlueJ Banner end-->

  <!-- Cards for categories start -->
<!--  <div id="post-list-cont" class="container cat-container">-->
<!--    <div class="row">-->
<!--      <div-->
<!--        class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 mb-5"-->
<!--        *ngFor="let cat of categories"-->
<!--      >-->
<!--        <div class="b-cat-card" [ngStyle]="{'background-color': cat.color}" jzBlueCatHover [routerLink]="['/bluej/', cat.name]">-->
<!--          <img class="b-text-logo-img" [src]="cat.text_logo" alt="Category {{cat.name}}">-->
<!--          <img class="bird-logo-img" [src]="cat.bird_logo" alt="Category {{cat.name}} logo">-->
<!--          <p class="cat-card-copy" style="opacity: 0">{{cat.short_description}}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
