// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';

// Material
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';


// Ours
import {CheckVoteComponent} from './check-vote/check-vote.component';
import { ResourceDialogComponent } from './resource-dialog/resource-dialog.component';
import { HeaderComponent } from './header/header.component';
import { ResourceCatComponent } from './resource-cat/resource-cat.component';
import { PostListComponent } from './post-list/post-list.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxSpinnerModule} from 'ngx-spinner';
import { UnderConstructionPageComponent } from './under-construction-page/under-construction-page.component';



@NgModule({
  declarations: [
    CheckVoteComponent,
    ResourceDialogComponent,
    HeaderComponent,
    ResourceCatComponent,
    PostListComponent,
    UnderConstructionPageComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    RouterModule,
    MatCardModule,
    InfiniteScrollModule,
    NgxSpinnerModule
  ],
  exports: [
    CheckVoteComponent,
    HeaderComponent,
    ResourceCatComponent,
    PostListComponent
  ]
})
export class SharedModule { }
