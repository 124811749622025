import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Recognition} from './models/employee';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeRecogService {
  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getEmployee(id: number): Observable<Recognition> {
    return this.http.get<Recognition>(this.url + 'recognitions/' + id);
  }

  getEmployees(): Observable<Recognition[]> {
    return this.http.get<Recognition[]>(this.url + 'recognitions/all');
  }
}
