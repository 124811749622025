import { Component, OnInit } from '@angular/core';
import {LearningHub} from '../../../../core/models/learning-hub';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'jz-parent-cont',
  templateUrl: './parent-cont.component.html',
  styleUrls: ['./parent-cont.component.scss']
})
export class ParentContComponent implements OnInit {
  windowWidth: number;
  categories: LearningHub[];
  marginTop = '151px';
  categoriesView = true;
  filterBy: number;
  detailedArray: LearningHub[];
  subCat = false;
  panelOpenState = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    this.route.data.subscribe((data: {learningHubCategories: LearningHub[]}) => {
      this.categories = data.learningHubCategories;
    });
    // console.log(this.categories);
  }

  closeDetailView() {
    this.categoriesView = !this.categoriesView;
  }


  showCategoriesView(categoryID: number) {
    this.filterBy = categoryID;
    this.showResources();
  }

  showResources() {
    this.detailedArray = this.categories.filter(category => category.id === this.filterBy);
    this.categoriesView = !this.categoriesView;
    if (this.detailedArray[0].sub_cat.length) {
      this.subCat = !this.subCat;
    }
  }
}
