<section style="margin-right: 20px;height: 100%; width: 100%">
  <div class="container" id="quick-links" style="height: 100%;">

  <span style="background: #64CC44;
                    border-radius: 20px;
                    color: #64CC44;
                    position: absolute;
                    left: -9px;
                    width: 26px;
                    height: 14px;
                    top: 29px">-</span>
    <p class="label-title">Forms & Quick Links</p>

    <div class="content-container">

      <div class="d-flex flex-row" id="quick-links-content" *ngFor="let link of quickLinks">
        <img [src]='link.icon' alt="logo" style="width: 20px; height: 20px">
        <a  *ngIf="link.title !== 'JPark App' " class="link-container" [href]="(link.resource_type === 'pdf' || link.resource_type === 'xlsx') ? link.resource_url :
                link.external_link ? link.external_link : link.resource_link" [target]="(link.resource_type === 'jcosmos') ? '' : '_blank'">
          <p class="link-title" (mouseover)="hover=true;linkTitle=link.title" (mouseleave)="hover=false" [ngStyle]="{'color': (hover && linkTitle === link.title) ? '#64CC44' : '#707070'}">{{link.title}}</p>
          <mat-divider></mat-divider>
        </a>
        <a  *ngIf="link.title === 'JPark App' " class="link-container" [href]="(link.resource_type === 'pdf' || link.resource_type === 'xlsx') ? link.resource_url :
                link.external_link ? link.external_link : link.resource_link" [target]="(link.resource_type === 'jcosmos') ? '' : '_blank'" [routerLink]="['/', 'parking-list']">
          <p class="link-title" (mouseover)="hover=true;linkTitle=link.title" (mouseleave)="hover=false" [ngStyle]="{'color': (hover && linkTitle === link.title) ? '#64CC44' : '#707070'}">{{link.title}}</p>
          <mat-divider></mat-divider>
        </a>
      </div>

      <!--    <div class="row">-->
      <!--      <div class="col-sm-12 col-md-12 col-lg-12" *ngFor="let link of quickLinks">-->
      <!--        <div class="row">-->
      <!--          <div class="col-sm-2 col-md-2 col-lg-2">-->
      <!--            <img class="img-fluid" style="width: 60%" [src]='link.icon' alt="logo">-->
      <!--          </div>-->
      <!--          <div class="col-sm-10 col-md-10 col-lg-10">-->
      <!--            <a [href]="(link.resource_type === 'pdf' || link.resource_type === 'xlsx') ? link.resource_url :-->
      <!--                link.external_link ? link.external_link : link.resource_link" [target]="(link.resource_type === 'jcosmos') ? '' : '_blank'">-->
      <!--              <p class="link-title">{{link.title}}</p>-->
      <!--            </a>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <mat-divider></mat-divider>-->
      <!--      </div>-->
      <!--    </div>-->

    </div>


  </div>
</section>
