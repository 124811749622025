<div id="right-content">
  <!-- back button -->
  <div class="container-fluid back-button"
       [routerLink]="['/', 'home']"
  >
    <i class="fas fa-caret-left" style="color: #FFFFFF;"></i>
    <button>
      Back
    </button>
  </div>
  <div class="container-fluid main-container" style="max-width: 98%; padding-bottom: 80px;
background: #FFFFFF; border-radius: 20px; position: relative; padding-top: 15px">
  <span style="background: #54C8E8;
          border-radius: 20px;
          color: #54C8E8;
          position: absolute;
          left: -9px;
          width: 26px;
          height: 14px;
          top: 28px">-</span>
    <p style="color: #707070; font-size: 30px; text-align: left; margin-left: 10px"><b>Employee Recognition</b></p>
    <div class="container button-container">
      <a class="button-link text-center" href="https://docs.google.com/presentation/d/1wYuNDe0H2BRsIqS1dR1yEK-1RnkpTtetpCQNpZaMyy8/edit#slide=id.gb7e68dd9c2_2_0"
         target="_blank">
        Submit
        <i class="fas fa-caret-right" style="color: #707070;"></i>
      </a>
    </div>
    <div class=" container description-text">
      <p>
        Congratulations to all Jazmanians who have been recognized in the
        <span>Jazwares Employee Recognition Program!</span><br> The employees listed below have
        gone above and beyond in exemplifying one or more of our core values: Passion, Collaboration and Humility.
      </p>
    </div>
    <div class=" container description-text">
      <p>
        Know someone you’d like to recognize? Click the “submit” button at the top of
        this page to recommend an employee on your team.
      </p>
    </div>

    <div class="row justify-content-around mobile-row">
      <div
        *ngFor="let recognition of recognitions"
        class="col-12 col-sm-6 col-md-4 employee-container"
        [ngStyle]="{'background-color': recognition.quality.color}"
        [routerLink]="['/', 'recog-employees', recognition.id]"
      >
        <h2>{{recognition.employee.employee_name}}</h2>
        <p>{{recognition.employee.employee_title}}</p>

        <p>{{recognition.quality.quality_name}}</p>

        <div class="images-container">
          <img alt="quality name" [src]="recognition.quality.icon_image" />
          <img id="column" alt="column" src="assets/recognition/column.png" />
        </div>
      </div>
    </div>
  </div>
</div>

