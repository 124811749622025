import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Iyear} from '../core/models/year';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import {ActivatedRoute, Params} from '@angular/router';
import {CategoriesService} from '../core/categories.service';
import {EventService} from '../core/event.service';
import {CalendarEvent} from '../core/models/calendar-event';


@Component({
  selector: 'jz-jevents',
  templateUrl: './jevents.component.html',
  styleUrls: ['./jevents.component.scss']
})
export class JeventsComponent implements OnInit {
  slideConfig = {slidesToShow: 5, slidesToScroll: 1};
  slideConfigYear = {
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true
        }
      }
    ]
  };

  // Events related properties
  dateObject = new Date();
  month = this.dateObject.getMonth() + 1;
  year = this.dateObject.getFullYear();
  lastDay = new Date(this.year, this.month, 0).getDate();
  calendarEvents: CalendarEvent[];

  monthNames = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  };

  constructor(
    private route: ActivatedRoute,
    private categoriesService: CategoriesService,
    private eventService: EventService
  ) { }

  years: Iyear[];

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.years = data.event;
    });

    this.route.url.subscribe((value) => {
      const path = value[0].path;
      this.getCalendarEventsData(path);
    });
  }

  getCalendarEventsData(path: string) {
    const pk = this.categoriesService.categories[path];
    let actualMonth = this.month.toString();

    if (this.month < 10) {
      actualMonth = `0${this.month}`;
    }

    const minDate = `${this.year}-${actualMonth}-01`;
    const maxDate = `${this.year}-${actualMonth}-${this.lastDay}`;

    this.eventService.getCalendarEvents(pk, minDate, maxDate)
      .subscribe((response: CalendarEvent[]) => {
        this.calendarEvents = response.reverse();
      });
  }

}
