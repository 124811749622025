<div class="divider d-flex flex-row justify-content-center align-items-center">
  <div class="">
    <div class="topL"></div>
    <div class="botL"></div>
  </div>
  <div class="">
    <div class="topR"></div>
    <div class="botR"></div>
  </div>
</div>
