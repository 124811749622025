import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Angular Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import {MatSelectModule} from '@angular/material/select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Angular Material Modules
import {MatCardModule} from '@angular/material/card';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';

// Other Modules
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider} from 'angularx-social-login';
import {AccordionModule, CarouselModule, ModalModule, PopoverModule, BsDropdownConfig, BsDropdownModule} from 'ngx-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import {SharedModule} from './shared/shared.module';
import {JtankModule} from './idea-contest/jtank.module';
import {ContestModule} from './halloween-contest/contest.module';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {NgxSpinnerModule} from 'ngx-spinner';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { OrgChartModule } from './org-chart/org-chart.module';

// Components
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NavComponent } from './nav/nav.component';
import { PostListComponent } from './post-list/post-list.component';
import { JnewsComponent } from './jnews/jnews.component';
import { JeventsComponent } from './jevents/jevents.component';
import { JeducationComponent } from './jeducation/jeducation.component';
import { JplusplusComponent } from './jplusplus/jplusplus.component';
import { JfitComponent } from './jfit/jfit.component';
import { BluejComponent } from './bluej/bluej.component';
import { JazwaresCaresComponent } from './jazwares-cares/jazwares-cares.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { PostDetailComponent } from './post-detail/post-detail.component';
import {EventDetailComponent, GalleryModalComponent} from './jevents/event-detail/event-detail.component';
import { AskJcosmosComponent } from './ask-jcosmos/ask-jcosmos.component';
import { CovinewsComponent } from './covinews/covinews.component';
import { KeepInTouchComponent } from './keep-in-touch/keep-in-touch.component';
import { HelpfulTipsComponent } from './helpful-tips/helpful-tips.component';
import { KidsCornerComponent } from './kids-corner/kids-corner.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { ImageSelectionComponent } from './idea-contest/submit-idea/image-selection/image-selection.component';
import { AfterSubmissionComponent } from './idea-contest/submit-idea/after-submission/after-submission.component';
import { EmployeesComponent } from './employees/employees.component';
import { EmployeeComponent } from './employees/employee/employee.component';
import { TitleBarComponent } from './employees/title-bar/title-bar.component';
import {DidYouKnowComponent} from './did-you-know/did-you-know.component';
import {GlobalSearchComponent} from './global-search/global-search.component';
import { OrgChartComponent } from './org-chart/org-chart.component';

import { DayPipe } from './core/pipes/day.pipe';
import { SanitizePipe } from './core/pipes/sanitize.pipe';

// Providers
import {EventDetailResolver} from './core/resolvers/event-detail';
import {PostsResolver} from './core/resolvers/posts.resolver';
import {PostResolver} from './core/resolvers/post.resolver';
import {EventResolver} from './core/resolvers/event.resolver';
import {CategoriesResolver} from './core/resolvers/categories.resolver';
import {LoggedInGuard} from './core/guards/logged-in-guard';
import {TokenInterceptor} from './core/token.interceptor';
import {HomemessagesResolver} from './core/resolvers/homemessages';
import {IdeasResolver} from './core/resolvers/ideas.resolver';
import {DetailIdeaResolver} from './core/resolvers/detail-idea.resolver';
import {ContestResolver} from './core/resolvers/contest.resolver';
import {EmployeeRecogDetailResolver} from './core/resolvers/employee-recog-detail.resolver';
import {EmployeesRecogResolver} from './core/resolvers/employees-recog.resolver';
import { DiscountsComponent } from './discounts/discounts.component';
import { JparkComponent } from './jpark/jpark.component';
import {JparkResolver} from './core/resolvers/jpark.resolver';
import {VoteCountResolver} from './core/resolvers/vote-count.resolver';
import { HumanResourcesComponent } from './human-resources/human-resources.component';
import {ResourcesResolver} from './core/resolvers/resources.resolver';
import {SubmissionCategoriesResolver} from './core/resolvers/submission-categories.resolver';
import {PartnerResolver} from './core/resolvers/partner.resolver';
import {VotedCheckResolver} from './core/resolvers/voted-check.resolver';
import { HrTrackerComponent } from './human-resources/hr-tracker/hr-tracker.component';
import { HrTeamComponent } from './human-resources/hr-team/hr-team.component';
import { HrLatestUpdatesComponent } from './human-resources/hr-latest-updates/hr-latest-updates.component';
import { HappybirthdayComponent } from './happybirthday/happybirthday.component';
import { WeatherCardComponent } from './home/weather-card/weather-card.component';
import { UpcomingEventsComponent } from './home/upcoming-events/upcoming-events.component';
import { RecognitionCardComponent } from './home/recognition-card/recognition-card.component';
import { HrTeamListComponent } from './human-resources/hr-team-list/hr-team-list.component';
import { BdayCardComponent } from './home/bday-card/bday-card.component';
import { CarouselComponent } from './home/carousel/carousel.component';
import { QuickLinksComponent } from './home/quick-links/quick-links.component';
import { BluejRefactorComponent } from './bluej/bluej-refactor/bluej-refactor.component';
import { BluejPostsDetail } from './Bluej-Posts-Detail/bluej-posts-detail.component';
import { MeetTeamComponent } from './meet-team/meet-team.component';
import { BlueCatHoverDirective } from './core/directives/blue-cat-hover.directive';
import { BluejBannerHoverDirective } from './core/directives/bluej-banner-hover.directive';
import { BenefitsComponent } from './human-resources/benefits/benefits.component';
import { TalentAcquisitionComponent } from './human-resources/talent-acquisition/talent-acquisition.component';
import { PerformanceManagementComponent } from './human-resources/performance-management/performance-management.component';
import { PayrollComponent } from './human-resources/payroll/payroll.component';
import { LeaveOfAbsencesComponent } from './human-resources/leave-of-absences/leave-of-absences.component';
import { LearningHubComponent } from './human-resources/learning-hub/learning-hub.component';
import { LearningHubDashboardComponent } from './human-resources/learning-hub/learning-hub-dashboard/learning-hub-dashboard.component';
import { GlobalEventsComponent } from './jevents/global-events/global-events.component';
import { PartnersHoverDirective } from './core/directives/partners-hover.directive';
import { SocialComponent } from './social/social.component';
import {HumanResourcesCatResolver} from './core/resolvers/human-resources-cat.resolver';
import {TheLearningHub} from './core/resolvers/learning-hub.resolver';
import { DiversityComponent } from './diversity/diversity.component';
import {DiversityResolver} from './core/resolvers/diversity.resolver';
import { ErrorPageComponent } from './error-page/error-page.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {JwellnessComponent} from './jwellness/jwellness.component';
import { JplanetComponent } from './jplanet/jplanet.component';
import { NewOrgChartComponent } from './new-org-chart/new-org-chart.component';
import { NodeCardComponent } from './new-org-chart/components/node-card/node-card.component';
import { DividerComponent } from './new-org-chart/components/divider/divider.component';
import { HierarchyContainerComponent } from './new-org-chart/components/hierarchy-container/hierarchy-container.component';
import {LogosResolver} from './core/resolvers/logos-resolver';
import { ProfileModalComponent } from './new-org-chart/components/profile-modal/profile-modal.component';
import { SearchResultsComponent } from './new-org-chart/components/search-results/search-results.component';
import { RightMenuModalComponent } from './new-org-chart/components/right-menu-modal/right-menu-modal.component';
import { PeersListComponent } from './new-org-chart/components/peers-list/peers-list.component';
import { ReportsListComponent } from './new-org-chart/components/reports-list/reports-list.component';
import { PeersNodeComponent } from './new-org-chart/components/peers-node/peers-node.component';
import { CategoryCardComponent } from './human-resources/learning-hub/learning-hub-dashboard/category-card/category-card.component';
import { ParentContComponent } from './human-resources/learning-hub/learning-hub-dashboard/parent-cont/parent-cont.component';
import { LoggedUserProfileComponent } from './top-nav/logged-user-profile/logged-user-profile.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { QuestionsComponent } from './user-profile/components/questions/questions.component';
import { ModalAlertComponent } from './user-profile/components/modal-alert/modal-alert.component';

// import { OrgChartComponent } from './org-chart/org-chart.component';
// import { EntityComponent } from './org-chart/entity/entity.component';


const config = new AuthServiceConfig([{
  id: GoogleLoginProvider.PROVIDER_ID,
  provider: new GoogleLoginProvider('901198326879-sm58pui5h5q7tumtsk8a6gb6se3ck46d.apps.googleusercontent.com')
}]);

function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavComponent,
    JnewsComponent,
    JeventsComponent,
    JeducationComponent,
    JplusplusComponent,
    JfitComponent,
    BluejComponent,
    JazwaresCaresComponent,
    NavComponent,
    PostListComponent,
    TopNavComponent,
    PostDetailComponent,
    EventDetailComponent,
    AskJcosmosComponent,
    CovinewsComponent,
    KeepInTouchComponent,
    HelpfulTipsComponent,
    AskJcosmosComponent,
    SanitizePipe,
    KidsCornerComponent,
    DayPipe,
    ComplianceComponent,
    ImageSelectionComponent,
    AfterSubmissionComponent,
    ComplianceComponent,
    EmployeesComponent,
    EmployeeComponent,
    TitleBarComponent,
    DiscountsComponent,
    TitleBarComponent,
    JparkComponent,
    HumanResourcesComponent,
    GalleryModalComponent,
    DidYouKnowComponent,
    GlobalSearchComponent,
    HrTrackerComponent,
    HrTeamComponent,
    HrLatestUpdatesComponent,
    HappybirthdayComponent,
    GlobalSearchComponent,
    BluejRefactorComponent,
    BluejPostsDetail,
    MeetTeamComponent,
    BlueCatHoverDirective,
    BluejBannerHoverDirective,
    HappybirthdayComponent,
    WeatherCardComponent,
    UpcomingEventsComponent,
    RecognitionCardComponent,
    HrTeamListComponent,
    BdayCardComponent,
    CarouselComponent,
    QuickLinksComponent,
    BenefitsComponent,
    TalentAcquisitionComponent,
    PerformanceManagementComponent,
    PayrollComponent,
    LeaveOfAbsencesComponent,
    LearningHubComponent,
    LearningHubDashboardComponent,
    GlobalEventsComponent,
    PartnersHoverDirective,
    GlobalEventsComponent,
    SocialComponent,
    DiversityComponent,
    ErrorPageComponent,
    OrgChartComponent,
    JwellnessComponent,
    JplanetComponent,
    NewOrgChartComponent,
    NodeCardComponent,
    DividerComponent,
    HierarchyContainerComponent,
    ProfileModalComponent,
    SearchResultsComponent,
    RightMenuModalComponent,
    PeersListComponent,
    ReportsListComponent,
    PeersNodeComponent,
    CategoryCardComponent,
    ParentContComponent,
    LoggedUserProfileComponent,
    UserProfileComponent,
    QuestionsComponent,
    ModalAlertComponent,
    // EntityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    HttpClientModule,
    RouterModule,
    MatCardModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    CarouselModule.forRoot(),
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    SlickCarouselModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    MatDialogModule,
    SharedModule,
    JtankModule,
    MatTabsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ContestModule,
    CollapseModule.forRoot(),
    PaginationModule.forRoot(),
    MatDividerModule,
    MatSelectModule,
    InfiniteScrollModule,
    MatExpansionModule,
    OrgChartModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    LoggedInGuard,
    CategoriesResolver,
    SubmissionCategoriesResolver,
    PostsResolver,
    PostResolver,
    EventResolver,
    HomemessagesResolver,
    EventDetailResolver,
    EmployeeRecogDetailResolver,
    EmployeesRecogResolver,
    IdeasResolver,
    DetailIdeaResolver,
    ContestResolver,
    JparkResolver,
    VoteCountResolver,
    ResourcesResolver,
    PartnerResolver,
    VotedCheckResolver,
    HumanResourcesCatResolver,
    TheLearningHub,
    DiversityResolver,
    LogosResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    // isAnimated: true, causes a visual error.
    {provide: BsDropdownConfig, useValue: {isAnimated: false, autoClose: true}},

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
