import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Slide} from '../core/models/slide';
import {PostsService} from '../core/posts.service';
import {CarouselConfig} from 'ngx-bootstrap/carousel';
import {Recognition} from '../core/models/employee';
import {IResource, ResourceCategory} from '../core/models/resource';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'jz-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    {provide: CarouselConfig, useValue: {interval: 5000, noPause: true, pauseOnFocus: false, showIndicators: true, animation: true}}
  ]
})
export class HomeComponent implements OnInit {
  slides: Slide[];
  resourceCategories: ResourceCategory[];

  // Used to match images for every category
  resources: IResource[];
  recognitions: Recognition[];
  color = 'transparent';

  // new vars added by Alejandro
  name: string;
  today = new Date();
  events = [
    {title: 'Global Sales Conference', day: '15', date: 'jan 2022'},
    {title: 'NY Toy Fair', day: '19', date: 'feb 2022'},
    {title: 'Bagels at main kitchen', day: '24', date: 'feb 2022'},
    {title: 'Easter eggs paint shop ', day: '27', date: 'feb 2022'},
  ];
  day: number;
  month: number;
  year: number;

  constructor(
    private postsService: PostsService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.recognitions = data.recognitions;
    });
    //  getting slider post
    this.postsService.getPostSlider().subscribe((slides: Slide[]) => {
      this.slides = slides;
    });
    this.postsService.getCategoriesWithResources().subscribe((resourceCategories: ResourceCategory[]) => {
      this.resourceCategories = resourceCategories;
    });

    // dates calculation
    this.day = this.today.getDate(); // to be deleted from home
    this.month = this.today.getMonth() + 1; // to be deleted from home
    this.year = this.today.getFullYear(); // to be deleted from home
  }
}
