import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HrCategory} from '../models/hr-category';
import {HumanResourcesService} from '../human-resources.service';

@Injectable()
export class HumanResourcesCatResolver implements Resolve<HrCategory[]> {

  constructor(
    private humanResources: HumanResourcesService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<HrCategory[]> | Promise<HrCategory[]> | HrCategory[] {
    return this.humanResources.getHrResources();
  }
}
