<div id="right-content">

  <div class="container">

    <div class="row">

      <!-- back button -->
      <div
        class="container-fluid button-container"
        [routerLink]="['/', 'human-resources']"
        style="margin-bottom: 10px"
      >
        <i class="fas fa-caret-left" style="color: #FFFFFF;"></i>
        <button>
          Back
        </button>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-4 col-lx-4" id="left-content" style="height: 825px">
        <div class="d-flex flex-column justify-content-between">
          <div class="text-center" id="top-image">
            <img class="img-fluid learning-hub-banner-abs" style="position:absolute;left:0;margin-top: 0px;border-top-left-radius: 20px; border-top-right-radius: 20px;border-bottom:1px solid black;box-shadow: 0 0 3px 1px rgba(0,0,0,.75);" src="assets/human-resources/learning-hub/TheLearningHub.jpg" alt="placeholder Logo">
            <img class="img-fluid learning-hub-banner-normal" style="opacity: 0;margin-bottom: 20px;margin-top: 10px;" src="assets/human-resources/learning-hub/TheLearningHub.jpg" alt="placeholder Logo">
          </div>

          <div id="concept-text">
            <span style="background: #FFC400;
               border-radius: 20px;
               color: #FFC400;
               position: absolute;
               left: -24px;
               width: 26px;
               height: 14px;
               top: 28px">-</span>
            <h4>What's the Learning Hub?</h4>
            <p>The Learning Hub was designed to offer resources, in interesting and varied ways,
              that will help encourage all Jazmanians to develop independent learning skills and
              encourage a culture of curiosity.</p>
          </div>

          <div>
            <img class="rounded mx-auto d-block" id="woman-pic" src="assets/human-resources/learning-hub/Screen%20Shot%202022-03-21%20at%201.48.20%20PM.png" alt="placeholder Logo">
          </div>

          <div id="info-text">
            <p><span style="color: black; opacity: 90%">For questions or suggestions please contact:</span><br>
              Amy Golbitz PHR, SHRM-CP Manager,<br>
              Learning & Organizational Development<br><br>
              Learning@Jazwares.com</p>
          </div>

          <div class="text-center" id="jazwiki-logo" style="margin-bottom: 5px">
            <img class="img-fluid" src="assets/human-resources/learning-hub/Jazwiki_Logo_FINAL-01.png" alt="placeholder Logo">
          </div>
            <a class="jazwiki-btn" href="https://jazwares.bizlibrary.com/" target="_blank">Click Here</a>
        </div>
      </div>

      <!-- state 1 -->
      <div class="col-sm-12 col-md-12 col-lg-8 col-lx-8 mt-0 mt-4 mt-md-4 mt-lg-0" id="cat-container" *ngIf="categoriesView">
        <div class="d-flex flex-wrap justify-content-around">
          <div class="d-flex flex-column" id="card-for-resour" *ngFor="let cat of learninghubCat">
            <h4 style="margin: auto">{{cat.cat}}</h4>
            <p>{{cat.description}}</p>
            <a (click)="onClick(cat.id)">Click Here</a>
          </div>
        </div>
      </div>

      <!-- state 2 -->
      <div class="col-sm-12 col-md-8 col-lg-8 col-lx-8 mt-0 mt-4 mt-md-0" *ngIf="!categoriesView">
        <span style="background: #FFC400;
          border-radius: 9px;
          color: #FFC400;
          position: absolute;
          left: 7px;
          width: 26px;
          height: 14px;
          top: 24px">-</span>
        <div class="close-button" (click)="closeDetailView()">X</div>
        <div class="d-flex flex-column"  id="detail-view-resour-card" *ngFor="let cat of detailArray">
          <h4 class="cat-header">{{cat.cat}}</h4>
          <p class="cat-description">{{cat.description}}</p>
          <div class="scrollable">
            <div *ngFor="let resource of detailArray[0].resources">
              <a
                class="resource-container"
                [href]="(
              resource.resource_type === 'pdf' ||
              resource.resource_type === 'xlsx' ||
              resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                [target]="'_blank'"
                style="text-decoration: none; color: black;"
              >
                <img style="width: 5%"
                  alt="file type"
                  [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'"
                />
                <p>{{resource.name}}</p>
              </a>
            </div>
            <div class="d-flex flex-column" *ngIf="subCat">
              <!--accordion for subcats-->
              <mat-accordion *ngFor="let subCat of detailArray[0].sub_cat; index as i">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{subCat.cat}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div *ngFor="let resource of detailArray[0].sub_cat[i].resources">
                    <a class="resource-container" [href]="(
                      resource.resource_type === 'pdf' ||
                      resource.resource_type === 'xlsx' ||
                      resource.resource_type === 'video') ? resource.resource_url : resource.external_link"
                    [target]="'_blank'"
                    >
                      <img style="width: 5%" alt="file type" [src]="resource.resource_type === 'pdf' ? 'assets/new-pdf-icon.png' : 'assets/new-icon-link.png'"/>
                      <p>{{resource.name}}</p>
                    </a>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <!-- looping through sub_cat -->
<!--              <div class="show-data" id="subcat-content" style="position: relative" *ngFor="let subCat of detailArray[0].sub_cat; index as i ">-->
<!--                <span style="background: #F5DA00;-->
<!--            border-radius: 9px;-->
<!--            color: #F5DA00;-->
<!--            position: absolute;-->
<!--            left: -9px;-->
<!--            width: 26px;-->
<!--            height: 14px;-->
<!--            top: 13px">-</span>-->
<!--                <p class="subcat">{{subCat.cat}}</p>-->

<!--                <div *ngFor="let resource of detailArray[0].sub_cat[i].resources">-->
<!--                  <a-->
<!--                    class="resource-container"-->
<!--                    [href]="(-->
<!--                      resource.resource_type === 'pdf' ||-->
<!--                      resource.resource_type === 'xlsx' ||-->
<!--                      resource.resource_type === 'video') ? resource.resource_url : resource.external_link"-->
<!--                    [target]="'_blank'"-->
<!--                  >-->
<!--                    <img-->
<!--                      alt="file type"-->
<!--                      [src]="resource.resource_type === 'pdf' ? 'assets/pdf-logo.png' : 'assets/links-logo.png'"-->
<!--                    />-->
<!--                    <p>{{resource.name}}</p>-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>
