import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ISubmission} from '../models/halloween-contest';
import {IdeaService} from '../idea.service';
import {SubmissionService} from '../submission.service';

@Injectable()
export class ContentResolver implements Resolve<ISubmission[]> {

  constructor(
    private submissionService: SubmissionService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ISubmission[]> | Promise<ISubmission[]> | ISubmission[] {
    return this.submissionService.getSubmissions();
  }
}
