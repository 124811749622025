import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Message} from './models/message';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AskService {
  url = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  sendQuestion(message: Message) {
    return this.http.post(this.url + 'notifications/contact_us', message, {observe: 'response'});
  }
}
