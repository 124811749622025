import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {EventService} from '../../core/event.service';
import {CalendarEvent} from '../../core/models/calendar-event';
import {NgxSpinnerService} from 'ngx-spinner';
import {GlobalEvents} from '../../core/models/global-events';

@Component({
  selector: 'jz-global-events',
  templateUrl: './global-events.component.html',
  styleUrls: ['./global-events.component.scss']
})
export class GlobalEventsComponent implements OnInit {

  today = new Date();
  months = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
  };
  day: number;
  month: number;
  year: number;
  calendarEvents: CalendarEvent[];

  // new
  globalEvents: GlobalEvents[];

  // mock data start fetching the events (year 2019)
  startDate = '2023-01-01';
  endDate = '2023-12-31';
  firstDayOfJanMonth = '1';
  firstMonthOfYear = '1';
  lastDayOfDecMonth = '31';
  lastMonthOfYear = '12';
  mockYear = '2019';
  // year selected from the select/option elem
  selected = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private eventService: EventService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {

    // dates calculation
    this.day = this.today.getDate();
    this.month = this.today.getMonth() + 1;
    this.year = this.today.getFullYear();

    this.fetchLatestGlobalEvents(this.startDate, this.endDate);
  }

  convertDateFromIntToString(date) {
    const dateComp = date.split('T');
    const dateParts = dateComp[0].split('-');
    const day = dateParts[2];
  }

  // method to get the date
  convertStringtoDateObj(date) {
    const newDate = new Date(date);
    const testDate = newDate.getDate();
    return testDate;
  }

  // method to get the full year
  convertStringToFullYear(date) {
    const newDate = new Date(date);
    const testDate = newDate.getFullYear();
    return testDate;
  }

  // method to get the month
  convertStringToMonth(date) {
    const newDate = new Date(date);
    const testDate = newDate.getMonth() + 1;
    const finalDate = this.months[testDate];
    return finalDate;
  }

  onChange() {
   const builtStartDate = this.selected + '-' + this.firstMonthOfYear + '-' + this.firstDayOfJanMonth;
   const builtLastDate = this.selected + '-' + this.lastMonthOfYear + '-' + this.lastDayOfDecMonth;
   this.fetchLatestGlobalEvents(builtStartDate, builtLastDate);
  }

  private fetchLatestGlobalEvents(startDate, endDate) {
    this.spinner.show();
    this.eventService.getGlobalEventsByDates(startDate, endDate).subscribe({
      next: response => {
        this.globalEvents = response;
        this.spinner.hide();
        // console.log('globalevents', this.globalEvents);
        // console.log('fjfjfjfj', this.globalEvents[0].datetime);
      }
    });
  }
}
